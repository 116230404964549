import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { NavHashLink as NavLink } from 'react-router-hash-link';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from "react-bootstrap/Dropdown";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import TextField from '@mui/material/TextField';

import './layout.css';
import '../../App.css';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
// ========================================

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';

import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import LoginForm from '../../Common/components/login-form';


import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../features/loginSlice';
import { setUserName } from '../../features/usersName';
import { store } from '../../store';
import adminService from '../../services/admin.service';


import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import eventBus from '../../features/eventBus';
import CheckboxTree from 'react-checkbox-tree';

function CircularProgressWithLabel(props) {
  let progressState = store.getState();
  console.log("PROGRESS STATE", progressState)

  const userInfo = localStorage.getItem('userInfo');
  const role = JSON.parse(userInfo);
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {/* <Typography variant="caption" component="div" color="text.secondary" className='cp_completed'>
          <strong>{`${(role.profile_percentage== null ? progressState.header.progress : role.profile_percentage).toFixed(1)}%`}</strong> Completed
        </Typography> */}
      </Box>
    </Box>
  );
}
CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};
// ========================================
function Header() {





  let progressState = store.getState();
  console.log("PROGRESS STATE", progressState)

  const logClass = 'dropdown-menu log_out_specific logout_box open'
  const [dropClass, changeClasses] = useState(false);

  // const progresss = useSelector((state) => state.header.progress);

  const dispatch = useDispatch();
  const location = useLocation();
  const route = location.pathname;
  let a_b = '';

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [areaExpand, setAreaExpanded] = useState([]);
  const [, forceUpdate] = useState();
  const [status, setStatus] = useState('');
  const [statusCode, setStatusCode] = useState('');
  const [areaChecked ,setAreaChecked] = useState([])
  const [statusColor, setStatusColor] = useState();
  const [percentage, setPercentage] = useState();
  const [userName, setUserName] = useState();
  const [callData, setParentAreaData] = useState([]);
  const [progressEvent, setProgressEvent] = useState()
  const [flatAreas, setFlatArea] = useState([])
  const[userRole,setRole] = useState();
  const [name, setName] = useState();
  const [FormState, setFormState] = useState({
    id: null,
    pareaCode: "SWZ",
    pareaID: "",
    plevel: "1",
    pareaName: "Eswatini",
    areaName: "Eswatini",
    areaId: "",
    oldAreacode: ""
  });


  var dataMan = '';
  const userInfo = localStorage.getItem('userInfo');
  var role = null;
  if(userInfo) {
    role = JSON.parse(userInfo);
  } 


  useEffect(() => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    if (userInfo != null && userInfo != 'null' && userInfo != undefined && userInfo != '') {
      const data = {
        "user_id": userInfo?.id
      }


      // useEffect(()=>{

      //   else if(role?.profileStatus == 2){
      //     setStatus('Pending')
      //   }
      //   else if(role?.profileStatus == 1){
      //     setStatus('Approved')
      //   }
      //   else if(role?.profileStatus == 3){
      //     setStatus('Disapproved')
      //   }
      // }, [status])


      let statusLocal;
  
    adminService.httpPost('/data-import/actor/getById', data).then((res) => {
      if (res) {
        setName(res?.data?.[0]?.name)

       updateUserData();

        setStatusCode(res?.data?.[0]?.status)
        if (res?.data?.[0]?.status == null) {
          setStatus('Pending')
          setStatusColor('pending')
        }

        else if (res?.data?.[0]?.status == 1) {
          
          setStatus('Approved')
          setStatusColor('approved')
        }
        else if (res?.data?.[0]?.status == 2) {
          setStatus('Pending')
          setStatusColor('pending')
        }
        else if (res?.data?.[0]?.status == 3) {
          setStatus('Rejected')
          setStatusColor('disapproved')
        }

      }
      else {
        dispatch(logout())
        navigate("/")
      }



    })

  }




    eventBus.on("completion", (data) => {

      setProgressEvent(data.message)
      forceUpdate();
      console.log('mika', progressEvent)
    });



    const datamn = adminService.httpGet('/data-retrieval/area/getAreaData').then((res) => {

      if (res) {

        let d = res.data;
        if (d != "" && d != null && d?.length > 0) {

  
          let flatLevel = [];

          d?.forEach((item) => {
     

          var obj = {
              value: item.area_code,
              label: item.name,
              parent: item.parent_id,
              level:item.level
             
            }

            flatLevel.push(obj)
    
          });
          setFlatArea(flatLevel)
          // console.log("level1", flatLevel);
          const data = setLevelData(flatLevel);


          setParentAreaData(data);
        }
      }

    }).catch((err) => {

      console.log(err)
    });

    // eventBus.remove('completion');
  }, [progressEvent]);







  const updateUserData = () =>{
    
    let data = {
      "userId" : role?.id
     }
     adminService.httpPost('/data-import/users/getUserData', data).then(res=>{
if(res?.success==true){

  const data = res?.data?.[0];


  if( res?.data?.length){

  

    var obj = {
      "name": data?.name,
      "email": data?.email,
      "role": data?.role,
      "area": data?.area?.[0]?.area_code,
      "designation":  data?.designation,
      "id": data?._id,
      "last_login_time": data?.login_time,
      "profile_percentage":data?.profile_percentage,
      "status":statusCode,
      "profile_status": data?.profile_status      
  }
  localStorage.setItem('userInfo', JSON.stringify(obj))
  localStorage.setItem('perc', JSON.stringify(data?.profile_percentage))
  }

}




    })

  }

  const handleChange = (event) => {
    setFormState({ ...FormState, [event.target.name]: event.target.value });
    this.setState((state) => {
      return {
        open: !state.open,
      };
    });
  };



  const getAreaList = (e) =>{

    console.log('asas',e)


var val = e?.value;


    setFormState({...FormState, pareaCode:val, pareaName:e?.label});
    setAreaChecked([val])
    eventBus.dispatch("changeArea", { 'message': e?.value, 'name':e?.label });
}



const setLevelData = (flat) => {

  var roots = [];

  var all = {};

  flat.forEach(function (item) {

      all[item.value] = item;
  });


  Object.keys(all).forEach(function (value) {
      var item = all[value];
      if (item.parent === null || item.parent === "") {

          roots.push(item);
      } else if (item.parent in all) {
          var p = all[item.parent];
          if (!("children" in p)) {
           
              p.children = [];
              
          }
          p.children.push(item);
      }
  });


  return roots;
};



  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopClose = () => {
    setAnchorEl(null);
  };


  const openDropdownLogout = () => {

    if (dropClass) {
      changeClasses(false)
    }
    else {
      changeClasses(true)
    }
  };

  const navigate = useNavigate();

  const handleLogout = (e) => {
    dispatch(logout())
    // console.log('successfully logged out')
    navigate("/")
  }

  const items = ["Eswatini", "Eswatini 2", "Eswatini 3"];
  const [selectedItem, setSelectedItem] = useState("Eswatini");

  const years = ["2020", "2021", "2022", "2023"];
  const [selectedYears, setSelectedYears] = useState("2020");

  const [searchshow, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const searchShow = () => setShow(true);

  // ================================================================================
  const [state, setState] = useState({
    bottom: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const handleDrawerClose = () => {
    setState(false);
  };


  const check = (path) => {
    console.log(path)
    a_b = path
    // setRedirectto(path)
  };

  // ================================================================================

  // =======================================

  // const [progress, setProgress] = useState(10);

  // React.useEffect(() => {
  //   const timer = setInterval(() => {
  //     setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 1));
  //   }, 800);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);
  // =======================================




  // console.log("role.profile_percentage"+role.profile_percentage)

  switch (role?.role) {
    case 1: 
      dataMan = '/admin/Summary'
      break;
    case 2:
      dataMan = '/admin/data-entry'
      break;

    case 4:
      dataMan = '/admin/data-approve'
      break;

    default:
      dataMan = ''
  }

  console.log('dataMan',dataMan, role?.role)

  // console.log('PROFILE PERCENTAGE NEWWWWW',role.profile_percentage)

  // console.log('PROGRESS COUNTT:--- ',progressState.header.userName)



  useEffect(() => {

    // if(role?.profile_percentage == null){
    //   setPercentage(0)
    // }

    let perc = localStorage.getItem('perc');
   

    if(role?.role ==1){
      setRole("Admin")
    }else if(role?.role ==2){
      setRole("Data Entry")
    }else if(role?.role ==4){
      setRole ("Data Approve")
    }

    if (progressEvent != null && progressEvent != undefined && parseFloat(perc) > 0) {

      setPercentage(progressEvent?.toFixed())
      console.log('byprogess', progressEvent?.toFixed())
    }
    else if (perc != undefined && perc != null) {

      setPercentage(parseFloat(perc)?.toFixed())
      console.log('byperc', parseFloat(perc)?.toFixed())
    }
    else {
      if (role?.profile_percentage !== null) {


        setPercentage(parseFloat(role?.profile_percentage)?.toFixed())

      }
      else {

        setPercentage(0)

      }


    }


    // console.log("reload krke progressEvent:---", progressEvent)
    // if(progressEvent){
    //   setPercentage(progressEvent)
    // }
    // else if(!progressEvent || progressEvent==undefined){
    //   let perc = localStorage.getItem('perc');
    //   console.log('this is percen=====================', perc)
    //   if(perc && perc!=null && perc!=''){


    //       let crPerc = perc!=null &&  perc!=''? parseFloat(perc)?.toFixed(1) : 0 ;
    //       setPercentage(crPerc)
    //       console.log('diljit',crPerc, percentage)




    //   }
    //   else{
    //     // console.log('case else',role?.profile_percentage?.toFixed(1))
    //     // setPercentage(role?.profile_percentage?.toFixed(1))
    //   }

    //   // setPercentage(role?.profile_percentage?.toFixed(1))
    // }
    // else if(!localStorage.get-filter-datagetItem('perc')){
    //   setPercentage(role?.profile_percentage?.toFixed(1))
    // }
    // else if(role?.profile_percentage == 0){
    //   setPercentage(progressEvent)
    // }
    // else if(role?.profile_percentage == 0 && !progressEvent){
    //   setPercentage(0)
    // }
    // else if(role.profile_percentage == null){
    //   let perc = localStorage.getItem('perc');
    //   if(perc && perc!=null && perc!=''){
    //     setPercentage(perc!=null && perc!=''? parseFloat(perc)?.toFixed(1) : 0)
    //   }
    // }
    // else if(isNaN(progressEvent)){
    //   setPercentage(role?.profile_percentage?.toFixed(1))
    // }
  }, [progressEvent])

  // useEffect(() => {
  //   if (!role?.profileStatus) {
  //     setStatusColor('pending')
  //   }
  //   else if (role?.profileStatus == 1) {
  //     setStatusColor('approved')
  //   }
  //   else if (role?.profileStatus == 2) {
  //     setStatusColor('pending')
  //   }
  //   else if (role?.profileStatus == 3) {
  //     setStatusColor('disapproved')
  //   }
  // }, [statusColor])

  // useEffect(()=>{
  //   let un = localStorage.getItem('name')
  //   let userInfo = JSON.parse(localStorage.getItem('userInfo'))
  //   // console.log("USERINFO FREEEE : ----", userInfo.name)
  //   // if(userInfo.name){
  //   //   setUserName(userInfo.name)
  //   // }
  //   if(un){
  //     setUserName(un)
  //   }
  //   else if(!un || un == null || !progressState.header.userName){
  //     setUserName(role.name);
  //   }
  // }, [])

  // useEffect(()=>{
  //   let un = localStorage.getItem('name')

  //   if(role?.name || un!==null){
  //     setUserName(un)
  //   } else if(role?.name && !un){
  //     setUserName(role?.name)
  //   } else if(!un){
  //     setUserName(role?.name)
  //   } else if(!role.name && !un){
  //     setUserName('')
  //   } else if(role.name && un){
  //     setUserName(un)
  //   }else if(!role?.name && un == null){
  //     setUserName('')
  //   } else if(role.name == null || un == "null"){
  //     setUserName('')
  //   } else {
  //     setUserName(progressState.header.userName)
  //   }

  // }, [FormState,userName])

  // useEffect(()=>{
  //   let un = localStorage.getItem('name')
  //   if(role?.name=="" && un!='null'){
  //     setUserName(un)
  //   } else if(role?.name == 'null' && !un){
  //     setUserName('')
  //   }
  //   else if(role?.name == "" && un=='null' || un==''){
  //     setUserName("")
  //   }
  //   else if(role?.name==""){
  //     setUserName("")
  //   } else if(role?.name == null && un){
  //     setUserName(un)
  //   } else if(!un){
  //     setUserName(role?.name)
  //   } else if(!role?.name && !un){
  //     setUserName('')
  //   } else if(role?.name && un){
  //     setUserName(un)
  //   } else {
  //     setUserName(progressState.header.userName)
  //   }

  // }, [FormState, userName])


  const [isScrollValueMoreThanHeaderHeight, setIsScrollValueMoreThanHeaderHeight] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollValueMoreThanHeaderHeight(window.scrollY > 1);
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll);
  }, [])




  

  return (
    <>
      <div className={isScrollValueMoreThanHeaderHeight ? "header_sticky hs_Top" : "header_sticky"}>
        <header className={route != '/data-analytics' && route != '/dashboard/tracker' && route != '/dashboard/profile' && route != '/dashboard/activity' ? 'dashboard_logo' : 'dashboard_logo db_header'}>
          <div className="container-fluid">
            <div className="row justify-content-between align-items-center">
              <div className={route != '/data-analytics' && route != '/dashboard/tracker' && route != '/dashboard/profile' && route != '/dashboard/activity' ? 'col-3' : 'col-lg-4 col-3'}>
                <div className="admin_logo">
                  <Link to="/" ><img src="assets/images/logo_wmis.png" /></Link>
                </div>
              </div>

              {(role?.role === 5 && route == '/dashboard/tracker' || route == '/dashboard/profile' || route == '/dashboard/activity') &&
                <div className='col-lg-4 col-6 text-center mobile-view'>
                  <div className='d-inline-block tracker_head'>
                  {
                    route == '/dashboard/activity' ?
                    `${name ? name : '' }`
                    :
                    <div className='header_dropdown hd_tracker hdt_dropdown'>
                    {route == '/dashboard/activity' ? <h5>{role?.name}</h5>: <span>Area</span>}
                      {route != '/dashboard/activity' && <ul>
                        <li>
                    
                        <div className="mat-form-field-wrapper">
                          <Dropdown>
                              <Dropdown.Toggle variant="white" id="dropdown-basic">
                              {FormState.pareaName}
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="treeviewCheckbox">
                                  <CheckboxTree
                                      nodes={callData}
                                      checked={areaChecked}
                                      expanded={areaExpand}
                                      onClick={(e) => getAreaList(e)}
                                      onExpand={(expanded) => setAreaExpanded(expanded)}
                                  />
                              </Dropdown.Menu>
                          </Dropdown>
                          </div>

                        </li>
                      </ul>}
                    </div>
              }

                    <div className='organization'>{userName}</div>

                    <div className='header_dropdown hd_tracker'>
                      <ul>
                        <li>
                          <h6>Registration Status: <span className={statusColor} >{status}</span></h6>
                          {/* <Dropdown>
                            <Dropdown.Toggle variant="white" id="dropdown-basic">
                              {FormState.pareaName}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <TreeView
                                    aria-label="file system navigator"
                                  
                                    defaultCollapseIcon={<ExpandMoreIcon />}
                                    defaultExpandIcon={<ChevronRightIcon />}                    
                                    sx={{ flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                                >
                                    {renderTree(callData)}
                                </TreeView>
                            </Dropdown.Menu>
                          </Dropdown> */}
                        </li>
                        <li>
                         
                          <h6>Profile {route!='/dashboard/profile'? <span>{role?.profile_percentage ? role?.profile_percentage.toFixed() : percentage}% Completed</span>:<span>{progressEvent ? progressEvent.toFixed() : percentage}% Completed</span>}
                          </h6>
                          {/* <Dropdown>
                            <Dropdown.Toggle>{selectedYears}</Dropdown.Toggle>
                            <Dropdown.Menu>
                              {years.map((years) => (
                                <Dropdown.Item onClick={() => setSelectedYears(years)}>
                                  {years}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown> */}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              }
              {(role?.role !== 5 && route == '/dashboard/tracker' || route == '/data-analytics') &&
                <div className='col-md-4 text-center mobile-view'>
                  <div className='d-inline-block tracker_head dataAnalytics_head'>
                    <div className='header_dropdown hd_tracker'>
                      <span>Area</span>
                      <ul>
                        <li>
                          {/* <Dropdown>
                            <Dropdown.Toggle variant="white" id="dropdown-basic">
                              {FormState.pareaName}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <TreeView
                                aria-label="file system navigator"
                                defaultCollapseIcon={<ExpandMoreIcon />}
                                defaultExpandIcon={<ChevronRightIcon />}
                                sx={{ flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                              >
                                {renderTree(callData)}
                              </TreeView>
                            </Dropdown.Menu>
                          </Dropdown> */}
                         <div className="mat-form-field-wrapper">
                          <Dropdown>
                              <Dropdown.Toggle variant="white" id="dropdown-basic">
                              {FormState.pareaName}
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="treeviewCheckbox">
                                  <CheckboxTree
                                      nodes={callData}
                                      checked={areaChecked}
                                      expanded={areaExpand}
                                      onClick={(e) => getAreaList(e)}
                                      onExpand={(expanded) => setAreaExpanded(expanded)}
                                  />
                              </Dropdown.Menu>
                          </Dropdown>
                          </div>

                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              }

              {/* {(route == '/dashboard/tracker') &&
                <div className='col-md-4 text-center mobile-view'>
                  <div className='d-inline-block tracker_head dataAnalytics_head'>            
                    <div className='header_dropdown hd_tracker'>
                      <span>Area</span>
                      <ul>
                        <li>
                          <Dropdown>
                            <Dropdown.Toggle variant="white" id="dropdown-basic">
                              {FormState.pareaName}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <TreeView
                                    aria-label="file system navigator"                                
                                    defaultCollapseIcon={<ExpandMoreIcon />}
                                    defaultExpandIcon={<ChevronRightIcon />}                    
                                    sx={{ flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                                >
                                    {renderTree(callData)}
                                </TreeView>
                            </Dropdown.Menu>
                          </Dropdown>
                        </li>                
                      </ul>
                    </div>
                  </div>
                </div>
              } */}

              <div className={route != '/data-analytics' && route != '/dashboard/tracker' && route != '/dashboard/profile' && route != '/dashboard/activity' ? 'col-9' : 'col-lg-4 col-3 small_screen'}>
                <div className='headerright'>
                  {route != '/data-analytics' && route != '/dashboard/tracker' && route != '/dashboard/profile' && route != '/dashboard/activity' &&
                    <>
                      <Navbar expand="lg">
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                          <Nav className="me-auto">
                            <li><NavLink to='/home#' className='nav-link' ><img src='assets/lib/images/home/home_icon.svg' /> Home</NavLink></li>
                            <li><NavLink to='/information-kiosk#' className='nav-link' >Information Kiosk</NavLink></li>
                            {role && <li><NavLink to='/dashboard/tracker#' className='nav-link' onClick={check('/dashboard/tracker')} >Tracker</NavLink></li>}
                            <li><NavLink to='/data-analytics#' className='nav-link' >Data Analytics</NavLink></li>
                            {role?.role == '5' && statusCode==1 ? <li><NavLink to='/dashboard/activity#' className='nav-link' onClick={handleDrawerClose}>Activity</NavLink></li>: ''}
                            {role?.role == '5' ? <li><NavLink to='/dashboard/profile' className='nav-link' >Profile</NavLink></li> : ''}
                            <li><NavLink to='/contact#' className='nav-link' >Contact</NavLink></li>
                            {role && role?.role != '5' && <li><NavLink to={dataMan} className='nav-link' >Data Manager</NavLink></li>}
                          </Nav>
                        </Navbar.Collapse>
                      </Navbar>
                      {/* <div className='hsearch' onClick={searchShow}>
                        <img src='assets/lib/images/home/search.svg' />
                      </div> */}
                    </>
                  }



                  {(route == '/data-analytics' || route == '/dashboard/tracker' || route == '/dashboard/profile' || route == '/dashboard/activity') &&
                    <>
                      <Navbar expand="lg">
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                          <Nav className="me-auto">
                            {role && <NavLink to='/dashboard/tracker#' className='nav-link' >Tracker</NavLink>}
                            <NavLink to='/data-analytics#' className='nav-link' >Data Analytics</NavLink>
                            {role?.role =='5' && statusCode == 1? <NavLink to='/dashboard/activity#' className='nav-link' >Activity</NavLink>:''}
                            {role?.role =='5' ? <NavLink to='/dashboard/profile#' className='nav-link' >Profile</NavLink> : ''}
                          </Nav>
                        </Navbar.Collapse>
                      </Navbar>
                      {/* =============================================================== Search Popup ======================================================= */}
                      <div>
                        {['right'].map((anchor) => (
                          <React.Fragment key={anchor} className="humburgerMenu">
                            <div className='hsearch' onClick={toggleDrawer(anchor, true)}>
                              <img src='assets/lib/images/home/humburger.svg' />
                            </div>
                            <SwipeableDrawer className="hm_width" anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)} onOpen={toggleDrawer(anchor, true)}>
                              <Button variant="secondary" className='hmClose' onClick={handleDrawerClose}>
                                <img src='assets/lib/images/close.svg' />
                              </Button>
                              <Navbar expand="lg" className='hmMenu'>
                                {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                                <Navbar.Collapse id="basic-navbar-nav">
                                  <Nav className="me-auto">
                                    <li><NavLink to='/home#' className='nav-link' > Home</NavLink></li>
                                    <li><NavLink to='/information-kiosk#' className='nav-link' onClick={handleDrawerClose}>Information Kiosk</NavLink></li>
                                    {role && <li><NavLink to='/dashboard/tracker#' className='nav-link' onClick={handleDrawerClose}>Tracker</NavLink></li>}
                                    <li><NavLink to='/data-analytics#' className='nav-link' onClick={handleDrawerClose}>Data Analytics</NavLink></li>
                                    {role?.role == '5' && role?.status==1 ? <li><NavLink to='/dashboard/activity#' className='nav-link' onClick={handleDrawerClose}>Activity</NavLink></li>: ''}
                                    {role?.role == '5' ? <li><NavLink to='/dashboard/profile#' className='nav-link' onClick={handleDrawerClose}>Profile</NavLink></li> : ''}
                                    <li><NavLink to='/contact#' className='nav-link' onClick={handleDrawerClose}>Contact</NavLink></li>
                                    {role && role?.role != '5' && <li><NavLink to={dataMan} className='nav-link' onClick={handleDrawerClose}>Data Manager</NavLink></li>}
                                  </Nav>
                                </Navbar.Collapse>
                              </Navbar>
                            </SwipeableDrawer>
                          </React.Fragment>
                        ))}
                      </div>
                      {/* =============================================================== Search Popup =================================================*/}
                    </>
                  }


                  {localStorage.getItem('userInfo') ?
                    <div class="rt_dropdown">
                      <div className={role?.role == 5 ? 'admin_user au_circle' : 'admin_user au_circle a_user'} onClick={handleClick}>
                        <div className='adminUser_circle'  >
                          {role?.role == 5 ? <> <CircularProgressWithLabel
                            value={`${progressEvent ? progressEvent.toFixed() : percentage}`}
                          /> </> :
                          // (role?.role == 1 ||  role?.role== 2 ||  role?.role==4) ? <><p className=''>Admin</p></> : <></>
                           <><p className=''>{userRole}</p></> 
                          }
                        </div>
                        <img src='assets/lib/images/home/user_admin.svg' />
                      </div>


                      <Menu className='dropDown_actor'
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handlePopClose}
                        onClick={handlePopClose}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                            '&:before': {
                              content: '""',
                              display: 'block',
                              position: 'absolute',
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: 'background.paper',
                              transform: 'translateY(-50%) rotate(45deg)',
                              zIndex: 0,
                            },
                          },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                      >

                        {/* <MenuItem>
                      Name
                    </MenuItem> */}
                        {/* <MenuItem>
                      Profile: 50%
                    </MenuItem>
                    <MenuItem>
                      Status: Pending
                    </MenuItem> */}

                        <MenuItem onClick={handleLogout}>
                          Logout
                        </MenuItem>
                      </Menu>
                    </div>
                    :
                    <div className='admin_user'>
                      <img src='assets/lib/images/home/user_admin.svg' onClick="check('/admin/summary')" id="homeUserProfile" data-toggle='modal' data-target='#login' />
                    </div>
                  }

                </div>
              </div>
            </div>
          </div>
        </header>
      </div>

      <div className="modal fade login_popup" id="login" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header p-0">
              <h4 className="modal-title h4" id="exampleModalLabel">Login</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">
                  <img src="assets/lib/images/grey_cross.svg" alt="" />
                </span>
              </button>
            </div>
            <div className="modal-body p-0">
              <LoginForm name={a_b} />
            </div>

          </div>
        </div>
      </div>

      {/* =============================================================== Search Popup ========================================================= */}
      <Modal size="lg" className='search_popup' centered show={searchshow} onHide={handleClose}>
        <Modal.Body>
          <TextField type="search" id="search" placeholder='Search' variant="outlined" />
          <Button variant="secondary" className='searcBtn'>
            <img src='assets/lib/images/home/search.svg' />
          </Button>
        </Modal.Body>
        <Button variant="secondary" className='searcClose' onClick={handleClose}>
          <img src='assets/lib/images/close.svg' />
        </Button>
      </Modal>
      {/* =============================================================== Search Popup ========================================================= */}
      {/* =============================================================== Loader Start ========================================================= */}
      <div className='loaderImg' style={{ display: 'none' }}>
        <img src='assets/lib/images/home/loader.gif' />
      </div>


      {/* ================================================================ Loader End ========================================================== */}


    </>

  );
}

// const mapDispatchToProps = { login, logout };
// export default connect(
//   null,
//   mapDispatchToProps
// )(Header);
export default Header;
import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Polygon, Marker, Popup, useMapEvents } from 'react-leaflet';
import L, { LatLng } from 'leaflet';
// import GeoHash from '@jollie/geohash';
import { styled } from '@mui/material/styles';
import { pol, pol2, markers } from './mapview-data';
import * as $ from 'jquery';
import 'leaflet/dist/leaflet.css';
import './mapview.css'
// import {cordData} from './SWZ_L03_2022'

import "leaflet-boundary-canvas";

import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import FaciliyModal from '../../../Common/components/modal/facility.modal';
import FilterForm from '../tracker/filter';

import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import adminService from '../../../services/admin.service';
function MapView(props) {

  const jsonFile = "assets/map/json/level2.json";

  var exceptions = {
    'Eswatini': [-26.28144330014251, 31.235107445706586],
    'Eswatini': [-26.323900540511485, 31.556833542547704]
  }
  var southWest = L.latLng(-27.195274556153716, 30.96053346031669),
  northEast = L.latLng(-25.822349046196358, 32.133578079796116);
  var bounds = L.latLngBounds(southWest, northEast);


const [currentArea, setCurrentArea] = useState([])
const [masters, setMasterData] = useState([])
const [isFilter, setIsFilter] = useState(false)
  const [reset, setReset] = useState(false);

  const [areaData, setAreaData] = useState([]);

  const [filters, setFilters] = useState({
    isFilter: false,
    location: '',
    facility_name: '',
    waste_types: '',
    profileType: '',
    date_awarded_from: '',
    date_awarded_to: '',
    area_sq_mtr: '',
    permissionStatus: '',
    mapData: []


  });

    const [dumpCount, setDumpCount] = useState(0);
  const [show, setShow] = useState(false);
  const [dumpData, setDumpData] = useState();
  const handleClose = () => setShow(false);
  const [marker, setMarkers] = useState([]);
  const [mapObj, setMapObj] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [loader, setLoader] = useState(false)
  const [isCompass, setCompass] = useState(false)
  const [filterAreaData, setFilterAreaData] = useState([])
  const pinr = 'assets/map/red.svg';
  const ping = 'assets/map/green.svg';


  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const pinMB = L.icon({
    iconUrl: ping,
    iconSize: [10, 10],
    iconAnchor: [5, 10],
    popupAnchor: [12, -40],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null
  });

  const pinMB2 = L.icon({
    iconUrl: pinr,
    iconSize: [10, 10],
    iconAnchor: [5, 10],
    popupAnchor: [12, -40],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null
  });





  const filterData = (data, isFilter) => {





    const filData =     {
      "area" : data.location,
    "facility_name"  : data.facName,
    "permissionStatus"  : data.permType,
    "facility_type"  : data.facType,
    "waste_type"  : data.wasteType,
    "dateFrom"  : data.date_awarded_from,
    "dateTo"  : data.date_awarded_to,
    "area_sq_meter"  : data.area_sq_mtr,
    "capacity"  : data.capacity
 
 }



    console.log('match', data, filData)






    var dData = [];

    adminService.httpPost('/data-import/facility/filter', filData).then(res=>{

      if(res){

        map.eachLayer((layer) => {
          layer.remove();
        });
    
        
 
        setDumpCount(res.data?.length)
        updateMarkers(res.data);
    
     

        if(isFilter!==true){
     
     
          $('#closeFiler').trigger('click');
          setIsFilter(true)
        }else{
        
          setIsFilter(false)
        }

       
        
      }

    })

    // if (data.permType != '3') {
    //   dData = [];

    //   dData = filters.mapData.filter(function (item) {
    //     for (var key in needData) {
    //       if (item[key] === undefined && item[key] === null || item[key] != needData[key])
    //         return false;
    //     }
    //     return true;
    //   });

    // } else if(data.permType == '3' && 
    // data.location=='' && data.facName=='' &&
    // data.facType=='' &&
    //  data.areaSqf==undefined && data.dateFrom==undefined && data.dateTo==undefined) {

   
    //   filters.mapData.filter(function (item) {
    //     dData.push(item)
    //   })
    



    // }
    // else{
    //   filters.mapData.filter(function (item) {
    //     for (var key in needData) {
    //       if ( item[key] == needData[key] &&
    //          item['permissionStatus'] == 1 ){

    //           dData.push(item)
          
            
    //          }
    //       if ( item[key] == needData[key] && item['permissionStatus'] == 0){

    //         dData.push(item)
        
          
    //       }

    //     }
       
    //   });

     
    // }







  }






  const [map, setMap] = useState(null);
  useEffect(() => {

    console.log('testArea', props.areaName)
    setCurrentArea(props.areaName)

    setDumpCount(props.dumpDataMain?.length)

    $(document).ready(function () {

      let width = $(window).width();
  
      console.log('width', width)
      if(width < 1950 && width > 1701) {
        map.options.minZoom = 8.5;
        map.options.setZoom = 8.6;
        map.options.zoom = 8.6;
  
      }
      else if (width < 1700 && width > 1490) {
        map.options.minZoom = 8.4;
        map.options.setZoom = 8.5;
        map.options.zoom = 8.5;
      }
      else if (width < 1400) {
        map.options.minZoom = 8.3;
        map.options.setZoom = 8.4;
        map.options.zoom = 8.4;
      }
  
    })
  
    let recursData = [];


    console.log('filteredArea', props.areaList)
    const recurs = (filteredArea) =>{

      setIsFilter(false)

      if(filteredArea?.length > 0){
        props.areaList?.forEach(item=>{
    
          filteredArea.forEach(dt=>{
            if(item.parent_id==dt.area_code){
              recursData.push(item)
            }
          })
      
        })
      

      }
      else{
        
        props.areaList?.forEach(item=>{

            if( item.area_code==props.parentArea){
              recursData.push(item)
            }
  
      
        })
      }

      return filteredArea;

    }
    



   let recs =  recurs(recursData);
   recs?.forEach(item=>{
    recurs(recursData);
   })

    //   props.areaList?.forEach(item=>{
     

    //   if(item.parent_id==props.parentArea || item.area_code==props.parentArea){

    //     let subData = [];

    //     if(item.area_code==props.parentArea){
    //       item['level']=1;
    //       item['parent_id']='';
    //     }




    //     data2.push(item)

    //     recurs(data2);
    //   }
    // })


console.log('data2',recs)


    setFilterAreaData(recs)


    

console.log('arealist in mapview====>', props)

let oldLength = filters.mapData?.length;
    const markerData = props.dumpDataMain;

    setDumpData(props.dumpDataMain)
    




    if(oldLength!=markerData?.length){
      map?.eachLayer((layer) => {
        layer.remove();
      });
      updateMarkers(markerData)
    }

   


    //  regeneratemap

  

    const fetchGeoJSON = async () => {
      const response = await fetch(
        jsonFile
      );
      const geoJSON = await response.json();

      const mapObjc = L.geoJSON(geoJSON, {
        style: countriesStyle,
        onEachFeature: onEachFeatures,

      }).addTo(map);

      function countriesStyle(feature) {
        return {
            fillColor: "#000000",
            fillOpacity: 0.01,
            color: '#ffffff',
            opacity: 0.5,
            weight: 1.5,
        }
    }

      function onEachFeatures(feature, layer) {
        // DO THIS FOR EACH COUNTRY
        // events
        layer.on({
            mouseover: function () {
                this.setStyle({
                    'fillColor': '#000000',
                    'fillOpacity': 0.1,
                });
            },
            mouseout: function () {
                this.setStyle({
                    'fillColor': '#000000',
                    'fillOpacity':  0.01,
                });
            },
            click: function () {
              console.log(feature.properties)
                // TODO Link to page
                // alert('Clicked on ' + feature.properties.Name1_)
            }
        });
        
         var label = L.marker(exceptions[feature.properties.Name1_] || layer.getBounds().getCenter(), {
            icon: L.divIcon({
                
                html: `<p class="labelsDt">${feature.properties.Name1_}</p>`,
                iconSize: [0, 0]
            })
        }).addTo(map);

        label.bindTooltip(
          feature.properties.name,
          {
            permanent:true,
            direction:'center',
            className: 'countryLabel'
          }
        );
    }

      setMapObj(mapObjc)



     
      
    }


    fetchGeoJSON();
    console.log('markerData', markerData)

    if (markerData != '' && markerData != null && markerData) {


      for (var i = 0; i < markerData.length; i++) {
        const datato = markerData[i];
        var aMarker = new L.marker([markerData[i].latitude, markerData[i].longitude], { zIndexOffset: 1000, icon: markerData[i].permissionStatus == 1 ? pinMB : pinMB2 })

          .addTo(map).on('click', (e) => { sendModalData(datato) });;
      }




      // map.fitBounds(mapObj.getBounds());
      map.panTo({
        lat: -26.55139426368066,
        lng: 31.455374702908358
      })
    }

    setFilters({ ...filters, mapData: markerData })




  }, [ props.parentArea, props.dumpDataMain, props.areaList, props.masterData, props.areaName]);




  const updateMarkers = (markerData) => {

    console.log('ccc')

    const fetchGeoJSON = async () => {
      const response = await fetch(
        jsonFile
      );
      const geoJSON = await response.json();

      const mapObjc = L.geoJSON(geoJSON, {
        style: countriesStyle,
        onEachFeature: onEachFeatures,

      }).addTo(map);

      function countriesStyle(feature) {
        return {
          fillColor: "#000000",
          fillOpacity: 0.05,
          color: '#ffffff',
          opacity: 0.5,
          weight: 1.5,
        }
      }

      function onEachFeatures(feature, layer) {
        // DO THIS FOR EACH COUNTRY
        // events
        layer.on({
          mouseover: function () {
            this.setStyle({
              'fillColor': '#000000',
              'fillOpacity': 0.1,
            });
          },
          mouseout: function () {
            this.setStyle({
              'fillColor': '#000000',
              'fillOpacity': 0,
            });
          },
          click: function () {
            console.log(feature.properties)
            // TODO Link to page
            // alert('Clicked on ' + feature.properties.Name1_)
          }
        });

        var label = L.marker(exceptions[feature.properties.Name1_] || layer.getBounds().getCenter(), {
          icon: L.divIcon({

            html: '<p style="color:#fff;"></p>',
            iconSize: [0, 0]
          })
        }).addTo(map);
        label.bindTooltip(
          feature.properties.name,
          {
            permanent: true,
            direction: 'center',
            className: 'countryLabel'
          }
        );

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {foo: 'bar'}).addTo(map);
      }

      setMapObj({...mapObj, ...mapObjc})



    }


    fetchGeoJSON();

    if (markerData != '' && markerData != null && markerData) {


      for (var i = 0; i < markerData.length; i++) {
        const datato = markerData[i];
        var aMarker = new L.marker([markerData[i].latitude, markerData[i].longitude], { zIndexOffset: 1000, icon: markerData[i].permissionStatus == 1 ? pinMB : pinMB2 })

          .addTo(map).on('click', (e) => { sendModalData(datato) });;
      }




      // map.fitBounds(mapObj.getBounds());
      map.panTo({
        lat: -26.55139426368066,
        lng: 31.455374702908358
      })
    }

  }

  // map.on( 'mousewheel', function() {
  //     console.log("zoom", map.getZoom())

  //     let dt = map.getZoom() ;


  //     if(dt > 8.495 ){
  //       setReset(true)    
  //        console.log(reset)
  //     }else{
  //       setReset(false)    
  //        console.log(reset)
  //     }
  // });

  const mapZoomIn = () => {
    let dt = map.getZoom() + 0.5;
    map.setZoom(dt)

    console.log(dt)

  }

  const mapZoomOut = () => {
    let dt = map.getZoom() - 1;
    map.setZoom(dt)

  
  }

  const mapReset = () => {
    map.fitBounds(mapObj.getBounds());
    setReset(false)
  }







  const downloadMap = () => {

    setLoader(true)
    $('.legends, .filter, .content_arrow ').hide();
    $('#download_desc ').removeClass('hide')
    $('.bottom_box').css({'position': 'relative', 'padding-left': '0', 'bottom': '0'})
    $('.tracker_section .mapBox.tracker_Map .map_area').css({'height': '550px'})
    $('.tracker_left_box.detail_box').css({'height': 'auto'})
    $('.tracker_section .mapBox.tracker_Map').css({'height': 'auto'})
    $('.countryName').css('display', 'unset');
    var node = document.getElementById('mainDownload');

    htmlToImage.toPng(node)
      .then(function (dataUrl) {
        var a = document.createElement('a');
        a.href = dataUrl;
        a.download = "Eswatini-Facilities-map-view.jpg";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        $('#download_desc ').addClass('hide')

        
        $('.bottom_box').css({'position': 'absolute', 'padding-left': '40px', 'bottom': '15px'})
        $('.tracker_section .mapBox.tracker_Map .map_area').css({'height': 'calc(100% - 70px)'})
        $('.countryName').css('display', 'none');
        $('.tracker_left_box.detail_box').css({'height': '100%'})
    $('.tracker_section .mapBox.tracker_Map').css({'height': '100%'})
        
        $('.legends, .filter, .content_arrow').show();
        setLoader(false)
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  }

  //   leaflet_zoom_out() {
  //  map.setZoom(this.map_global.getZoom() - 1)
  //   }
  // resetMapZoom() {

  //   this.map_global.fitBounds(this.mapObject.getBounds());

  // }

  const sendModalData = (data) => {

    setModalData(data)

    $('#facilityModal').modal();

  }

  const labelToggle = () => {

    $('.leaflet-marker-icon p').toggleClass('show')
    if($('.labelsDt.show').length > 0){
      $('#imageLabel').attr('src','assets/lib/images/home/label-off.svg');
  
    } else{
      $('#imageLabel').attr('src','assets/lib/images/home/label_on.svg');
    }

  }


    
  const center = [-26.53692874822112, 31.473254949625577];
  const position = [-26.55139426368066, 31.455374702908358];





  const parisStyle = {
    fillColor: 'red',
    fillOpacity: 0.5,
    weight: 10,
    color: 'black',
    outline: 'black',
    opacity: 0
  };


  const geo2Style = {
    fillColor: 'green',
    fillOpacity: 0.5,
    weight: 0,
    color: 'transparent',
    opacity: 0
  };




  return (
    <>
      <div className='mapBox tracker_Map mapsTrackers' id="mainDownload" style={{ background: '#fff' }}>

        <div className="tracker_left_box detail_box">
          <div className="map_value">
            <h3 onClick={(e) => setShow(true)}><span className='countryName'>{currentArea} | </span> Number of Facilities: <span >{dumpCount}</span></h3>
          </div>
          <div className="map_area text-center position-relative" >
            <div id="mainMapData"  >
              <MapContainer
                  position={position}
                  center = {center}
                  zoom={8.4}
                  minZoom={7}
                  maxBounds={bounds}
                  maxZoom={20}
                  whenCreated={setMap}
              >
                  <TileLayer
                      attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
              </MapContainer>
            </div>

            {isCompass == true && <span class="comapss" ><img src="assets/map/compass.svg" /></span>}
            {props.enableFilter &&
            <div className={isFilter==true? 'filteractive filter': 'filter'} data-toggle="modal" data-target="#filtermodal">
              <img src="assets/lib/images/home/filter_icon.svg" alt="" />
            </div>
            }
            <div className="legends">
              <ul>
                <li><Link to='' onClick={mapZoomIn}><img src='assets/lib/images/home/zoom-in.svg' /></Link></li>
                <li><Link to='' onClick={mapZoomOut}><img src='assets/lib/images/home/zoom-out.svg' /></Link></li>
                <li className='wmtm_reset'><Link to='' onClick={mapReset}><img src='assets/lib/images/home/reset.svg' /></Link></li>
                <li className='hr_li position-relative'></li>
                <li ><Link to='' onClick={labelToggle}><img src='assets/lib/images/home/label_on.svg' className='img-label' id="imageLabel"/></Link> </li>
                <li><Link to='' onClick={downloadMap}><img src='assets/lib/images/home/download.svg' /></Link> </li>
                <li><Link to='' onClick={(e) => { isCompass == true ? setCompass(false) : setCompass(true) }}><img src='assets/lib/images/home/north_pole.svg' /></Link> </li>

              </ul>
            </div>
          </div>
     
          <div className="bottom_box d-flex align-items-start flex-column">
            <div className="permission_values d-flex align-items-center justify-content-between w-100">
              <div className="source_area d-flex align-items-center">
                <div className="content_arrow">
                  <p> <Tooltip title="Maps are for graphical purposes only. They do not represent a legal survey. The boundaries and the names shown and the designations used on these maps are for demonstration purposes. While every effort has been made to ensure that these data are accurate and reliable within the limits of the current state of the art, I-Tech Mission cannot assume liability for any damages caused by any errors or omissions in the data, nor as a result of the failure of the data to function on a particular system. Users of our maps and other analysis products are solely responsible for interpretations made from these products." placement="top"><Link to=''>Disclaimer <img src='assets/lib/images/home/hover_arrow.svg' /></Link></Tooltip> </p>               
                </div>
              </div>
              <div className="new_permission_type permission_type">
                <div className="main_permision">
                  <p>Permission Type</p>
                </div>
                <ul className='permission_type_ul'>
                  <li>Licensed</li>
                  <li>Non-Licensed</li>
                </ul>
              </div>
            </div>

          </div>

          <div className="download_desc hide" id="download_desc">
              <p >
                 <b>Disclaimer:</b> The boundaries and the names shown and the designations used on these maps do not imply any official endorsement or acceptance by any agency organization or government.
                </p>
          </div>

        </div>

      </div>


      <div className={loader == true ? 'loader text-center ' : 'loader text-center hide'}>
        <img src="assets/images/Comp-2.gif" style={{ 'marginTop': '12%', 'width': '30%' }} />
      </div>

      <FaciliyModal modalTrigger={show} modalData={modalData} />

      <FilterForm 
      
        parentArea={props.parentArea} 
        sendData={filterAreaData} 
        areaList={filterAreaData} 
        dumpData={dumpData} 
        filterData={filterData} 
        masterData={props.masterData} />
    </>
  );
}

export default MapView;
import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import * as constants from '../../../Config/app.constants';

function ActivityViewModal({profileData, flatArea}) {

    const ActivityDate = dayjs(profileData?.activity_date_time).format('DD/MM/YYYY' + ' ' + 'hh:mm:ss  A'),
    
    Activity_Name = profileData?.activity_name,
    Activity_Type = profileData?.activity_type,
    
    Facility_Name = profileData?.facility_name,
    Facility_Type = profileData?.facility_type,
    Collection_Vehicle = profileData?.Collection_Vehicle,
    Destination = profileData?.destination,
    Origin = profileData?.origin,
    Waste_Type = profileData?.type_of_waste,
    Actor_Type = profileData?.actor_type,
    Quantity = profileData?.quantity,
    Unit = profileData?.unit,
    Description = profileData?.description,
    Image = profileData?.document;
    var Location_Actor = "",
    Location_Facility = profileData?.facility_location;
    flatArea?.map(item=>{

      
        if(item?.area_code==profileData?.actor_location){
          
            Location_Actor =  item.name;
        }

        // if(item?.area_code==profileData?.facility_location){
          
        //     Location_Facility =  item.name;
        // }

      })

      const showLocation =(e) =>{
        console.log("e----",e)
        const dtarr = [];
            var i=0;
            flatArea?.map(item=>{
                e?.forEach(element => {
                    if(item.area_code==element){
                        console.log("item-----",item.name)
                        dtarr.push(item.name +", " ) 
                    }
                });
              })
            return <>{dtarr?.length > 0 && dtarr.slice(0, 2).map((d, i) => { console.log('dash', d); return (<><span className="badge">{d} </span></>) })}
            {dtarr?.length > 2 ? <><span>{dtarr?.length - 1}+</span></> : ''}</> ;
      }

    return (
        <>
            <div className="modal maps_popup fade actor_tracker_popup activityviewmodal" id="activityviewmodal" tabindex="-1" aria-labelledby="activityviewmodalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div class="modal-title h4">Activity View</div>
                            <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className='facility_details'>
                                <div class="fd_img">
                                {
                                            Image != null && Image != '' ?
                                            <img src={`${constants.actorDocPath}${Image}`} />
                                            :

                                            <img src='assets/lib/images/home/facility_details_img.jpg' />
                                }
                                    
                                    
                                    </div>
                                <div className='fd_text'>
                                    <ul>
                                        <li>
                                            <div className='fdt_img'>
                                                <img src='assets/lib/images/home/actor-type.svg' />
                                            </div>                          
                                            <div className='fdt_text'>
                                                <h5>Actor Type</h5>
                                                <h4>{Actor_Type}</h4>
                                            </div>
                                        </li> 
                                        <li>
                                            <div className='fdt_img'>
                                                <img src='assets/lib/images/home/facility-type.svg' />
                                            </div>                          
                                            <div className='fdt_text'>
                                                <h5>Facility Type</h5>
                                                <h4>{Facility_Type}</h4>
                                            </div>
                                        </li> 
                                        <li>
                                            <div className='fdt_img'>
                                                <img src='assets/lib/images/home/facility-name.svg' />
                                            </div>                          
                                            <div className='fdt_text'>
                                                <h5>Facility Name</h5>
                                                <h4>{Facility_Name}</h4>
                                            </div>
                                        </li>  

                                        <li>
                                            <div className='fdt_img'>
                                                <img src='assets/lib/images/home/delete.svg' />
                                            </div>                          
                                            <div className='fdt_text'>
                                                <h5>Type of waste</h5>
                                                <h4>{Waste_Type}</h4>
                                            </div>
                                        </li> 
                                        <li>
                                            <div className='fdt_img'>
                                                <img src='assets/lib/images/home/activity-type.svg' />
                                            </div>                          
                                            <div className='fdt_text'>
                                                <h5>Activity Type</h5>
                                                <h4>{Activity_Type}</h4>
                                            </div>
                                        </li>  
                                        <li>
                                            <div className='fdt_img'>
                                                <img src='assets/lib/images/home/capacity.svg' />
                                            </div>                          
                                            <div className='fdt_text'>
                                                <h5>Quantity {Unit}</h5>
                                                <h4>{Quantity}</h4>
                                            </div>
                                        </li> 
                                        <li>
                                            <div className='fdt_img'>
                                                <img src='assets/lib/images/home/activity-name.svg' />
                                            </div>                          
                                            <div className='fdt_text'>
                                                <h5>Activity Name</h5>
                                                <h4>{Activity_Name}</h4>
                                            </div>
                                        </li>
                                    </ul>                     
                                    
                                    <ul>
                                        <li>
                                            <div className='fdt_img'>
                                                <img src='assets/lib/images/home/collection-vehicle.svg' />
                                            </div>                          
                                            <div className='fdt_text'>
                                                <h5>Collection vehicle</h5>
                                                <h4>{Collection_Vehicle}</h4>
                                            </div>
                                        </li>  
                                        <li>
                                            <div className='fdt_img'>
                                                <img src='assets/lib/images/home/global.svg' />
                                            </div>                          
                                            <div className='fdt_text'>
                                                <h5>Destination</h5>
                                                <h4>{Destination}</h4>
                                            </div>
                                        </li>  
                                        <li>
                                            <div className='fdt_img'>
                                                <img src='assets/lib/images/home/origin.svg' />
                                            </div>                          
                                            <div className='fdt_text'>
                                                <h5>Origin</h5>
                                                <h4>{Origin}</h4>
                                            </div>
                                        </li>  
                                        <li>
                                            <div className='fdt_img'>
                                                <img src='assets/lib/images/home/date.svg' />
                                            </div>                          
                                            <div className='fdt_text'>
                                                <h5>Activity Date Time</h5>
                                                <h4>{ActivityDate}</h4>
                                            </div>
                                        </li>  
                                        <li>
                                            <div className='fdt_img'>
                                                <img src='assets/lib/images/home/mappointer.svg' />
                                            </div>                          
                                            <div className='fdt_text'>
                                                <h5>Facility Location</h5>
                                                <h4>{showLocation(Location_Facility)}</h4>
                                                
                                            </div>
                                        </li>  
                                        <li>
                                            <div className='fdt_img'>
                                                <img src='assets/lib/images/home/mappointer.svg' />
                                            </div>                          
                                            <div className='fdt_text'>
                                                <h5>Actor Location</h5>
                                                <h4>{Location_Actor}</h4>
                                            </div>
                                        </li> 
                                        <li>
                                            <div className='fdt_img'>
                                                <img src='assets/lib/images/home/description.svg' />
                                            </div>                          
                                            <div className='fdt_text'>
                                                <h5>Description</h5>
                                                <h4>{Description}</h4>
                                            </div>
                                        </li>                                      
                                    </ul>      
                                    {/* <ul className='w-33'>
                                        <li>
                                            <div className='fdt_img'>
                                                <img src='assets/lib/images/home/mappointer.svg' />
                                            </div>                          
                                            <div className='fdt_text'>
                                                <h5>Image</h5>
                                                <h4>{Image}</h4>
                                            </div>
                                        </li>                                  
                                    </ul>                   */}
                                </div>
                            </div>                        
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default ActivityViewModal;
import React from "react";

const lazyLoader = (importComp) => {
	return class extends React.Component {

        constructor() {
            super();
        
            this.state = {
                component: null        
            }
        
        }
        		
		//loading the component and setting it to state
		componentDidMount() {
			importComp().then((comp) => this.setState({ component: comp.default }));
		}

		//rendering the component
		render() {
			const C = this.state.component;
			return C ? <C {...this.props} /> : <div className="lazyloding"><img src="assets/images/Comp-2.gif" /></div>;
			// return <div className="lazyloding"><img src="assets/images/Comp-2.gif" /></div>;
		}
	};
};
export default lazyLoader;
import React, { useEffect } from "react";
import { useCountUp } from "use-count-up";

function Counter(props) {
    const {start, end, duration, formatfn, startAgain} = props;

    const { value, reset } = useCountUp({
        isCounting: true,
        start: start,
        end: end,
        duration: duration,
        formatter: formatfn,
        autoResetKey: end,
        easing: 'linear'
        // decimalPlaces: 0
    });

    useEffect(() => {
        reset();
    }, [startAgain]);

    return (
        <>
            {value}
        </>
    );
}

export default Counter;
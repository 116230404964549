import React, { Component, useState } from 'react';
import Header from '../../layout/header';
import Footer from '../../layout/footer';
import Slider from "react-slick";
import { HashLink as Link } from 'react-router-hash-link';
import GeoData from '../geo-data/geo-data.js';
import {resourceImagePath} from '../../../Config/app.constants';
import CountUp, { useCountUp } from 'react-countup';

var homeTemplate = function () {
  
    const settings = {
      dots: true,
      infinite: true,
      arrows:false,
      autoplay: true,
      autoplaySpeed: 7000,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: () => { slide(); }      
    };

    const slide = () => {
      this.starts.forEach(start => {
          start();
        });
    }


    return (
      <>
        <Header />
        <section className='bannerslide'>
          <Slider {...settings}>
            {
              this.state.keyData.map((item, index) => {
                return (
                  <>
                    <div>
                      <div className='bs_section'>
                        <div className='row'>
                          <div className='col-md-12 d-flex justify-content-end'>
                            <div className='bannertext'>
                              <h4>In ESWATINI,</h4>
                              <h3>{item.indicator} <span>| {item.timeperiod}</span></h3> 
                              <div className='bt_number'>
                                <h2>
                                  <CountUp end={item.value} duration={2} delay={0} formattingFn={num => num.toLocaleString()}>
                                    {({ countUpRef, start }) => {
                                      this.countUpRefs.push(countUpRef);
                                      this.starts.push(start);
                                      return (
                                        <div>
                                          <span ref={countUpRef} />
                                        </div>
                                      );
                                    }}
                                  </CountUp>
                                </h2> 
                                <span className='btonnes'>{ item.unit }</span>
                              </div> 
                              <span className="source">SOURCE: {item.source}</span>
                            </div>              
                            <div className='bannerSvg'>
                              {this.abc[index]}                              
                            </div>  
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            }
              
            
          </Slider>
        </section>
        <section className='information_system'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12 text-center'>
                <h5>About the information system</h5>
                <h2>A Circular Economy Approach to Waste Management</h2>
                <p>Waste Management Information System is an interactive and user-friendly digital multi-stakeholder platform to be used by the Eswatini Environment Authority (EEA), local authorities, private sector, and the public to enable collaborative problem-solving, manage waste data, provide real time information, raise awareness and promote knowledge sharing. It is a customized platform that aims to bridge this gap by bringing stakeholders together to improve the management and oversight on waste management and practice in Eswatini. The platform will provide timely data on waste management activities and waste quantities disaggregated by categories and sectors.</p>
              </div>
            </div>
          </div>
        </section>
        <section className='data_analytics'>
          <div className='custom_container'>
            <div className='row align-items-center'>
              <div className='col-md-5'>
                <img className='imgWidth' src='assets/lib/images/home/data_analytics.svg' />
              </div>
              <div className='col-md-7'>
                <div className='da_text'>
                  <h2>Data <strong>Analytics</strong></h2>
                  <p>The waste management tracker module allows to view, track and periodically report the disaggregated data on waste collected, recycled, exported, incinerated/disposed and reuse/repurposed.</p>
                  <Link to='/data-analytics' className='explore_btn'>Explore <img className='' src='assets/lib/images/home/arrow.svg' /></Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='wmt'>
          <div className='custom_container'>
            <div className='row align-items-center'>              
              <div className='col-md-6'>
                <div className='da_text'>
                  <h2>Waste Management <strong className='d-md-block'>Tracker</strong></h2>
                  <p>The geographical analysis module presents the geo-spatial waste map with customized view to identify waste sites and waste generators by categories and types.</p>
                  <Link to="/dashboard/tracker" className='explore_btn'>Explore <img className='' src='assets/lib/images/home/arrow.svg' /></Link>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='geoData'>
                  <GeoData dumpDataMain={this.state.fecData}/>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='data_analytics'>
          <div className='custom_container'>
            <div className='row align-items-center'>
              <div className='col-md-5'>
                <img className='imgWidth' src='assets/lib/images/home/information-kiosk.svg' />
              </div>
              <div className='col-md-7'>
                <div className='da_text'>
                  <h2>Information<br/><strong>Kiosk</strong></h2>
                  <p>A repository of information on events organized, publications, project reports, research papers and other related reference materials on waste management.</p>
                  <Link  to="/information-kiosk#" className='explore_btn'>Explore <img className='' src='assets/lib/images/home/arrow.svg' /></Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='information_system latestesources'>
          <div className='custom_container'>
            <div className='row'>
              <div className='col-md-12 text-center'>
                <h5>Information Kiosk</h5>
                <h2>Latest Resources</h2>
              </div>
            </div>
            <div className='row'>

              {this.state.resData.slice(0, 3).map((item, index)=>{
              
            

              return (
                <div className='col-md-4'>
                <div className='lr_box'>
                  <div className='lr_box_img'>
                    <img className='img-fluid' src={resourceImagePath+item.image} 
                        onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src="assets/images/placeholder.png";
                      }} />
                  </div>
                  <div className='lr_box_text'>
                    <div className='lrbt_text'>
                      <h5> <a className='view_btn' href={item.type==="URL"? item.url : resourceImagePath+item.document} target="_blank">{item.title}</a></h5>
                      <p>{item.description}</p>
                    </div>
                    <div className='w-100 d-flex justify-content-end'>
                      <a className='view_btn' href={item.type==="URL"? item.url : resourceImagePath+item.document} target="_blank">View <img className='' src='assets/lib/images/home/arrow.svg' /></a>
                    </div>
                  </div>
                </div>
                </div>
              );
              })}
          
        
            </div>
          </div>
        </section>



        
        <Footer />
      </>
    );


};


export default homeTemplate;

import React, { useState, useEffect } from 'react';

import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import { isArray } from 'jquery';
import dayjs from 'dayjs';
import * as constants from '../../../Config/app.constants';

import DataTable from 'react-data-table-component';
import ModalImage from "react-modal-image";
// ================================================== Tabs
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
// const [active, setActive] = React.useState(true)


TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
// ================================================== Tabs
// ======================================================= Data Table



// const columns = [
//   {
//     name: '#',
//     selector: (row, index) => index + 1,
//     sortable: false,
//     width: "50px"
// },
// // {
// //     name: 'Activity Name',
// //     selector: row => <div className='activity_box'>{row?.activity_name}</div> ,
// //     sortable: true,
// //     width: '150px',

// // },
// {
//   name: 'Activity Date',
//   selector: row => dayjs(row?.activity_date_time).format('DD/MM/YYYY' + ' ' + 'hh:mm:ss  A'),
  
//   sortable: true,
//   width: '200px'
// },
// {
//     name: 'Activity Type',
//     selector: row => <div className='activity_box'>{row?.activity_type}</div> ,
//     sortable: true,
//     width: '150px',

// },
// {
//   name: 'Facility Type',
//   selector: row => <span className='badge'>{row?.facility_type}</span>,
//   sortable: true,
//   width: '200px'
// },
// {
//     name: 'Facility Location',
//     selector : row => ProfileModal.showLocation(row?.facility_location),
 
//     sortable: true,
//     width: '200px'
// },
// {
//   name: 'Facility Name',
//   selector: row => row?.facility_name,
//   sortable: true,
//   width: '150px'
// },
// {
//     name: 'Waste Type',
//     selector: row =>  <span className='badge'>{row?.type_of_waste}</span>,
//     sortable: true,
//     width: "150px"
// },
// {
//   name: 'Image',
//   // selector: row => row?.unit {row.document},
// // selector: row => <div className='documentImg'><span><img src={`${constants.actorDocPath}${row.document}`} /></span></div>,
// selector: row => <div className='documentImg'><span>
//   {row.document != ''?
// <ModalImage
// small={`${constants.actorDocPath}${row.document}`}
// large={`${constants.actorDocPath}${row.document}`}
// hideDownload
// hideZoom
// />
// : ''
// }
// {/* <img src={`${constants.actorDocPath}${row.document}`} /> */}
// </span>
// </div>,
//   sortable: true,
//   width: '150px'
// },
// {
//   name: <div className='extra_content_thead d-flex flex-column align-item-center'>Quantity <span>(Tonne/s)</span></div>,
//   selector: row => row?.unit=='Tonne/s'? row?.quantity : (row?.quantity)/1000,
//   sortable: true,
//   width: '150px'
// },
// {
//   name: 'Description',
//   selector: row => <div className='actor_description_box'>{row?.description}</div> ,
//   sortable: true,
//   width: '350px'
// },

// ];

// ======================================================= Data Table
function ProfileModal(props) {

    const [show, setShow] = useState(false);
    const [reqFields, setReqFileds] = useState(new Set());

    

useEffect(()=>{

  let profileType = props.profileData.profile_type;
  let actorTypes = constants.actortype;
  let requiredFields = new Set();

  profileType?.forEach(type => {
    if(type in actorTypes) {
      for (const [key, value] of Object.entries(actorTypes[type])) {
        if(value.required) {
          requiredFields.add(key);
        }
      }
    }
  });
  
  setReqFileds(requiredFields);
},[props])

// ============================================================ Tabs
const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const showLocation =(e) =>{

    const dtarr = [];
        var i=0;
        props.flatArea?.map(item=>{
            e?.forEach(element => {
                if(item.area_code==element){
                    console.log("item-----",item.name)
                    dtarr.push(item.name +", " ) 
                }
            });
          })
        return <>{dtarr?.length > 0 && dtarr.slice(0, 2).map((d, i) => { console.log('dash', d); return (<><span className="badge">{d} </span></>) })}
        {dtarr?.length > 2 ? <><span>{dtarr?.length - 1}+</span></> : ''}</> ;
  }

console.log("activitydata",props.activityData)
// ============================================================ Tabs

const columns = [
  {
    name: '#',
    selector: (row, index) => index + 1,
    sortable: false,
    width: "50px"
},
// {
//     name: 'Activity Name',
//     selector: row => <div className='activity_box'>{row?.activity_name}</div> ,
//     sortable: true,
//     width: '150px',

// },
{
  name: 'Activity Date',
  selector: row => dayjs(row?.activity_date_time).format('DD/MM/YYYY' + ' ' + 'hh:mm:ss  A'),
  
  sortable: true,
  width: '200px'
},
{
    name: 'Activity Type',
    selector: row => <div className='activity_box'>{row?.activity_type}</div> ,
    sortable: true,
    width: '150px',

},
{
  name: 'Facility Type',
  selector: row => <span className='badge'>{row?.facility_type}</span>,
  sortable: true,
  width: '200px'
},
{
    name: 'Facility Location',
    selector : row => showLocation(row?.facility_location),
 
    sortable: true,
    width: '200px'
},
{
  name: 'Facility Name',
  selector: row => row?.facility_name,
  sortable: true,
  width: '150px'
},
{
    name: 'Waste Type',
    selector: row =>  <span className='badge'>{row?.type_of_waste}</span>,
    sortable: true,
    width: "150px"
},
{
  name: 'Image',
  // selector: row => row?.unit {row.document},
// selector: row => <div className='documentImg'><span><img src={`${constants.actorDocPath}${row.document}`} /></span></div>,
selector: row => <div className='documentImg'><span>
  {row.document != ''?
<ModalImage
small={`${constants.actorDocPath}${row.document}`}
large={`${constants.actorDocPath}${row.document}`}
hideDownload
hideZoom
/>
: ''
}
{/* <img src={`${constants.actorDocPath}${row.document}`} /> */}
</span>
</div>,
  sortable: true,
  width: '150px'
},
{
  name: <div className='extra_content_thead d-flex flex-column align-item-center'>Quantity{/* <span>(Tonne/s)</span>*/}</div>,
  selector: row => <div>{row?.quantity} {row?.unit}</div>,
  sortable: true,
  width: '150px'
},
{
  name: 'Description',
  selector: row => <div className='actor_description_box'>{row?.description}</div> ,
  sortable: true,
  width: '350px'
},

];


    return (
<>

      

          <div className="modal maps_popup fade actor_tracker_popup" id="actorprofile" tabindex="-1" aria-labelledby="actorprofileLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <div class="modal-title h4">{props.profileData?.name? props.profileData?.name: 'NA'}</div>
                  <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">

                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Profile Details" {...a11yProps(0)} />
                    <Tab label="Activity Details" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0} className="atp_column">
                  <div className='facility_details mb-3'>
                    <div className='fd_text pl-0 w-100'>
                      <ul className='fd_text_box w-33'>
                        <li className={!reqFields.has('Name')?'not-required':''}>
                          <div className='fdt_img'>
                            <img src='assets/lib/images/username.svg' />
                          </div>                          
                          <div className='fdt_text'>
                            <h5>Name</h5>
                            <h4>{props.profileData?.name? props.profileData?.name: 'NA'}</h4>
                          </div>
                        </li>
                        <li className={!reqFields.has('Phone Number')?'not-required':''}>
                          <div className='fdt_img'>
                            <img src='assets/lib/images/home/call.svg' />
                          </div>
                          <div className='fdt_text'>
                            <h5>Phone Number</h5>
                            <h4>{props.profileData?.phone_number!=null? props.profileData?.phone_number : 'NA'}</h4>
                          </div>
                        </li>
                        <li className={!reqFields.has('Email ID')?'not-required':''}>
                          <div className='fdt_img'>
                            <img src='assets/lib/images/home/email.svg' />
                          </div>                          
                          <div className='fdt_text'>
                            <h5>Email ID</h5>
                            <h4>{props.profileData?.usersData?.[0]?.email? props.profileData?.usersData?.[0]?.email: 'NA'}</h4>
                          </div>
                        </li>
                        <li className={!reqFields.has('Location')?'not-required':''}>
                          <div className='fdt_img'>
                            <img src='assets/lib/images/home/mappointer.svg' />
                          </div>
                          <div className='fdt_text'>
                            <h5>Location</h5>
                            <h4>{props.profileData?.areaName!=null? props.profileData?.areaName: 'NA'}</h4>
                            {console.log("test666766",props.profileData)}
                          </div>
                        </li>
                        <li className={!reqFields.has('Type of waste')?'not-required':''}>
                          <div className='fdt_img'>
                            <img src='assets/lib/images/home/delete.svg' />
                          </div>
                          <div className='fdt_text'>
                            <h5>Type of waste</h5>
                            <h4>{Array.isArray(props.profileData?.waste_type)? props.profileData?.waste_type?.length > 0 ? props.profileData?.waste_type?.map((item, index)=>{ return (<><span class="badge">{item}</span></>);}) : 'NA' : props.profileData?.waste_type}</h4>
                          </div>
                        </li>

                        
                        {/* {props.profileData?.license_awarded_from!=null && */}


                        {/* {(props.profileData?.licence_awarded_from !=null || props.profileData?.licence_awarded_to !=null) &&
                          <li>
                            <div className='fdt_img'>
                              <img src='assets/lib/images/home/date.svg' />
                            </div>
                            <div className='fdt_text fdt_date'>
                                {props.profileData?.licence_awarded_from !=null && 
                                <div>
                                  <h5>License Awarded From</h5>                                  
                                  <h4>{props.profileData?.licence_awarded_from !=null ? dayjs(props.profileData?.licence_awarded_from).format('DD/MM/YYYY') : 'NA'}</h4>
                                  {console.log("hehehehe--->", props.profileData?.licence_awarded_from)}
                                </div>
                              }
                              {props.profileData?.licence_awarded_to !=null && 
                                <div>
                                  <h5>License Awarded To</h5>
                                  <h4>{props.profileData?.licence_awarded_to!=null? dayjs(props.profileData?.licence_awarded_to).format('DD/MM/YYYY') : 'NA'}</h4>
                                </div>
                              }
                            </div>
                          </li>    
                        } */}

                      </ul>                       
                      <ul className='w-33'>
                        <li>
                          <div className='fdt_img'>
                            <img src='assets/lib/images/home/facility-type.svg' />
                          </div>
                          <div className='fdt_text'>
                            <h5>Profile Type</h5>
                            <h4>{props.profileData.profile_type!=null? props.profileData.profile_type?.map((item, index)=>{ return (<><span class="badge">{item }</span></>);}) : '--'}</h4>
                          </div>
                        </li>                         
              
                                        
                 
                        {/* <li className={!reqFields.has('Waste licence No.')?'not-required':''}>
                          <div className='fdt_img'>
                            <img src='assets/lib/images/home/license_number.svg' />
                          </div>                          
                          <div className='fdt_text'>
                            <h5>Waste licence No.</h5>
                            <h4>{props.profileData?.waste_licence_no!=null?props.profileData?.waste_licence_no : 'NA'}</h4>
                          </div>
                        </li>*/}                                               
                      </ul>
                      <ul className='w-33'>
                        <li className={!reqFields.has('Destination')?'not-required':''}>
                          <div className='fdt_img'>
                            <img src='assets/lib/images/home/global.svg' />
                          </div>                          
                          <div className='fdt_text'>
                            <h5>Destination</h5>
                            <h4>{props.profileData?.destination!=null?props.profileData?.destination : 'NA'}</h4>
                          </div>
                        </li> 
                        <li className={!reqFields.has('Origin')?'not-required':''}>
                          <div className='fdt_img'>
                            <img src='assets/lib/images/home/origin.svg' />
                          </div>                          
                          <div className='fdt_text'>
                            <h5>Origin</h5>
                            <h4>{props.profileData?.origin!=null?props.profileData?.origin : 'NA'}</h4>
                          </div>
                        </li>
                     
                        <li className={!reqFields.has('Generation rate (Tonnes/month)')?'not-required':''}>
                          <div className='fdt_img'>
                            <img src='assets/lib/images/home/generation-rate.svg' />
                          </div>                          
                          <div className='fdt_text'>
                            <h5>Generation Rate (Tonnes/Month)</h5>
                            <h4>{props.profileData?.generation_rate!=null?props.profileData?.generation_rate : 'NA'}</h4>
                          </div>
                        </li> 
                        {/* <li className={!reqFields.has('Collection Vehicle')?'not-required':''}>
                          <div className='fdt_img'>
                            <img className="w-27" src='assets/lib/images/home/collection-vehicle.svg' />
                          </div>                          
                          <div className='fdt_text'>
                            <h5>Collection Vehicle</h5>
                            <h4>{props.profileData?.Collection_Vehicle!=null? props.profileData?.Collection_Vehicle?.map((item, index)=>{return (<><span className='badge'>{item}</span></>) }) : 'NA'}</h4>
                          </div>
                        </li>   */}
                        <li className={!reqFields.has('Date of registration')?'not-required':''}>
                          <div className='fdt_img'>
                            <img src='assets/lib/images/home/date.svg' />
                          </div>                          
                          <div className='fdt_text'>
                            <h5>Date of registration</h5>
                            <h4>{props.profileData?.date_of_registration!=null? dayjs(props.profileData?.date_of_registration).format('DD/MM/YYYY' + ' ' + 'hh:mm:ss  A') : 'NA'}</h4>
                          </div>
                        </li>

                      </ul>
                      
                    </div>
                  </div>
                  {props.profileData?.profile_type?.map(item=>{

                    if(item!='Visitor/researcher')

                      return (<>
                       <div className='collectorspecific'>
                      <h4>{item} Information</h4>
                      <div className='facility_details mb-3'>
                        <div className='fd_text pl-0 w-100'>
                          <ul className='w-100 collectorList'>

                            {item=='Generator'&&
                              <li className=''>
                                <div className='fdt_img'>
                                  <img className="w-27" src='assets/lib/images/home/collection-vehicle.svg' />
                                </div>   
                                <div className='fdt_text'>
                                  <h5>Generation Rate</h5>
                                  <h4>{props.profileData?.generation_rate!=null && props.profileData?.generation_rate!=''? props.profileData?.generation_rate+'%' :  'NA'}</h4>
                                </div>
                                
                              </li>
                            } 

                            {item=='Collector' &&
                              <li className=''>
                                <div className='fdt_img'>
                                  <img className="w-27" src='assets/lib/images/home/collection-vehicle.svg' />
                                </div>
                                <>
                                  <div className='fdt_img'>
                                    <img className='w-20' src='assets/lib/images/home/facility-type2.svg' />
                                  </div>                          
                                  <div className='fdt_text'>
                                    <h5>Facility Type</h5>
                                    <h4>{props.profileData?.facility_type != null ? props.profileData?.facility_type?.map(item=>{ return (<><span className="badge">{item}</span></>)}) : 'NA'}</h4>
                                  </div>                      
                                  <div className='fdt_text'>
                                  <h5>Collection Vehicle</h5>
                                    <h4>{props.profileData?.Collection_Vehicle!=null? props.profileData?.Collection_Vehicle?.map((item, index)=>{return (<><span className='badge'>{item}</span></>) }) : 'NA'}</h4>
                                  </div>                            
                                  <div className='fdt_text'>
                                    <div className='fdt_img'>
                                      <img src='assets/lib/images/home/frequency.svg' />
                                    </div>                          
                                    <div className='fdt_text'>
                                      <h5>Frequency</h5>
                                      <h4>{props.profileData?.frequency!=null?props.profileData?.frequency : 'NA'}</h4>
                                    </div>
                                  </div>
                                  <div className='fdt_img'>
                                    <img src='assets/lib/images/home/capacity.svg' />
                                  </div>                          
                                  <div className='fdt_text'>
                                    <h5>Capacity</h5>
                                    <h4>{props.profileData?.capacity!=null?props.profileData?.capacity : 'NA'} 
                                    {props.profileData?.unit!=null?props.profileData?.unit : ''}
                                    </h4>
                                  </div>
                                </>
                              </li>  
                            } 
                            {/* </ul>      */}                          
                            {(item!=='Generator' && item!=='Visitor/researcher' )  && 
                              <>                  
                                {/* <ul className='w-33'> */}

                                {item!=="Remediator" &&

                                  <li>
                                  <div className='fdt_img'>
                                    <img className='w-20' src='assets/lib/images/home/facility-type2.svg' />
                                  </div>                          
                                  <div className='fdt_text'>
                                    <h5>Facility Type</h5>
                                    <h4>{props.profileData?.facility_type != null ? props.profileData?.facility_type?.map(item=>{ return (<><span className="badge">{item}</span></>)}) : 'NA'}</h4>
                                  </div>
                                  </li>
                                  
                                }
                                                                                  
                                <li className=''>
                                  <div className='fdt_img'>
                                    <img src='assets/lib/images/home/license_number.svg' />
                                  </div>                          
                                  <div className='fdt_text'>
                                    <h5>License No.</h5>
                                    <h4>
                                      {props.profileData?.licenceData?.map(dt=>{
                                        if(dt.actor_type==item ){
                                      

                                          return <>{dt?.licence_no!=null && dt?.licence_no!=''? dt?.licence_no: 'NA'} </>

                                        }
                                      })}
                                    </h4>
                                  </div>
                                </li>                         
                                {/* </ul>
                                <ul className='w-33'>*/}                          
                                <li>
                                  <div className='fdt_img'>
                                    <img src='assets/lib/images/home/date.svg' />
                                  </div>
                                  <div className='fdt_text fdt_date'>
                                    <div>
                                      <h5>License Awarded From</h5>
                                      {/* <h4>{props.profileData?.license_awarded_from!=null? props.profileData?.license_awarded_from: 'NA'}</h4> */}
                                      <h4>
                                        {props.profileData?.licenceData?.map(dt=>{
                                          if(dt.actor_type==item ){                               
                                            return <>{dt?.licence_awarded_from!=null && dt?.licence_awarded_from!=''? dt?.licence_awarded_from: 'NA'} </>
                                          }
                                        })}  
                                      </h4>                                 
                                    </div>                                 
                                    <div>
                                      <h5>License Awarded To</h5>
                                      <h4>
                                        {props.profileData?.licenceData?.map(dt=>{
                                          if(dt.actor_type==item ){                               
                                            return <>{dt?.licence_awarded_to!=null && dt?.licence_awarded_to!=''? dt?.licence_awarded_to: 'NA'} </>
                                          }
                                        })}
                                      </h4>
                                    </div>                                
                                  </div>
                                </li>                            
                              </>
                            }
                          </ul>
                                     
                          
                        </div>
                      </div>
                  </div>
                      </>)

                  })}
                 
           
                </TabPanel>
                <TabPanel  className="profileModal atp_column" value={value} index={1}>
                  <DataTable
                    columns={columns}
                    data={props.activityData}
                    persistTableHead
                    // selectableRows
                    // onSelectedRowsChange={handleChange}
                    className='actortableFix'
                  />
                </TabPanel>

                </div>
              </div>
            </div>
          </div>

</>



    );
}


export default ProfileModal;
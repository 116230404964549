import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Polygon, Marker, Popup, useMapEvents } from 'react-leaflet';
import L, { LatLng } from 'leaflet';
// import GeoHash from '@jollie/geohash';
import { styled } from '@mui/material/styles';
import { pol, pol2, markers } from './mapData';

import 'leaflet/dist/leaflet.css';
import './geo-data.css'
// import {cordData} from './SWZ_L03_2022'

import "leaflet-boundary-canvas";

import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import FaciliyModal from '../../../Common/components/modal/facility.modal';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import * as $ from 'jquery';
function GeoData(props){
  const jsonFile = "assets/map/json/level2.json";
  const jsonFil2 = "assets/map/json/level3.json";
  var exceptions = {
    'Eswatini': [-26.28144330014251, 31.235107445706586],
    'Eswatini': [-26.323900540511485, 31.556833542547704]
    }

    var southWest = L.latLng(-27.195274556153716, 30.96053346031669),
    northEast = L.latLng(-25.822349046196358, 32.133578079796116);
  var bounds = L.latLngBounds(southWest, northEast);

  
  $(document).ready(function(){

    let width = $(window).width();

    console.log('width',width)

    if(width < 1700 && width > 1490){
      map.options.minZoom = 8.3;
      map.options.setZoom = 8.4;
      map.options.zoom = 8.4;
    }
    else if(width < 1400 ){
      map.options.minZoom = 8.3;
      map.options.setZoom = 8.4;
      map.options.zoom = 8.4;
    }

  })


  const [show, setShow] = useState(false);
  const [reset, setReset] = useState(false);
  const [dumpData, setDumpData] = useState();
  const handleClose = () => setShow(false);
  const [marker, setMarkers] = useState([]);
  const [mapObj, setMapObj] = useState([]);
  const [modalData, setModalData] = useState([]);

    const pinr = 'assets/map/red.svg';
    const ping = 'assets/map/green.svg';

    const BootstrapTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
      },
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
      },
    }));
 
    const pinMB = L.icon({
      iconUrl: ping,
      iconSize: [8, 8],
      iconAnchor: [4,8],
      popupAnchor: [12, -40],
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null
    });
  
    const pinMB2 = L.icon({
      iconUrl: pinr,
      iconSize: [8, 8],
      iconAnchor: [4,8],
      popupAnchor: [12, -40],
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null
    });
  
      const [map, setMap] = useState(null);
      useEffect(() => {

        const dData =  props.dumpDataMain;

        console.log('dumpdata main', dData)
 

        const fetchGeoJSON = async () => {
          const response = await fetch(
            jsonFile
          );
    
          const geoJSON = await response.json();

         const mapObjc =    L.geoJSON(geoJSON, {
            style: countriesStyle,
            onEachFeature: onEachFeatures,
            
        }).addTo(map);



        function countriesStyle(feature) {
          return {
              fillColor: "#000000",
              fillOpacity: 0.07,
              color: '#ffffff',
              opacity: 0.5,
              weight: 1.5,
          }
      }

        function onEachFeatures(feature, layer) {
          // DO THIS FOR EACH COUNTRY
          // events
          layer.on({
              mouseover: function () {
                  this.setStyle({
                      'fillColor': '#000000',
                      'fillOpacity': 0.1,
                  });
              },
              mouseout: function () {
                  this.setStyle({
                      'fillColor': '#000000',
                      'fillOpacity': 0,
                  });
              },
              click: function () {
                console.log(feature.properties)
                  // TODO Link to page
                  // alert('Clicked on ' + feature.properties.Name1_)
              }
          });
          
           var label = L.marker(exceptions[feature.properties.Name1_] || layer.getBounds().getCenter(), {
              icon: L.divIcon({
                  
                  html: '<p class="show"></p>',
                  iconSize: [0, 0]
              })
          }).addTo(map);

          label.bindTooltip(
            feature.properties.name,
            {
              permanent:true,
              direction:'center',
              className: 'countryLabel'
            }
          );
      }

        setMapObj(...mapObj, mapObjc)

        for (var i = 0; i < dData.length; i++) {


          const datato = dData[i];
          
        var aMarker =  new L.marker([dData[i].latitude, dData[i].longitude], {  zIndexOffset:1000, icon: dData[i].permissionStatus==1?pinMB: pinMB2})
           .addTo(map).on('click', (e)=>{sendModalData(datato)});
         
 
        }

       

      map.fitBounds(mapObj.getBounds());
        map.panTo({
          lat:-26.55139426368066,
          lng:  31.455374702908358
        })
  
  
        }


    
        fetchGeoJSON();

  
        
        console.log('dumps',dumpData)
      },[props]);



      const sendModalData = (data) => {

        setModalData(data)
      
        $('#facilityModal').modal();
        
      }


      const mapZoomIn = () => {
        let dt = map.getZoom() + 0.5;
        map.setZoom(dt)
    
        console.log(dt)
    
      }
    
      const mapZoomOut = () => {
        let dt = map.getZoom() - 1;
        map.setZoom(dt)
    
      
      }
    
      const mapReset = () => {
        map.fitBounds(mapObj.getBounds());
        setReset(false)
      }
    
    
    //   leaflet_zoom_out() {
    //  map.setZoom(this.map_global.getZoom() - 1)
    //   }
      // resetMapZoom() {
    
      //   this.map_global.fitBounds(this.mapObject.getBounds());

      // }

    
      const center = [-26.53692874822112, 31.473254949625577];
      const position = [-26.55139426368066, 31.455374702908358];


   
    
      const parisStyle = {
        fillColor: 'red',
        fillOpacity: 0.5,
        weight: 10,
        color: 'black',
        outline:'black',
        opacity: 0
      };


      const geo2Style = {
        fillColor: 'green',
        fillOpacity: 0.5,
        weight: 0,
        color: 'transparent',
        opacity: 0
      };




      return (
        <>
        <div className='mapBox'> 

        <div className='wmt_chart'>
                  <div className='wmt_maps_box position-relative'>
                        <MapContainer
                        position={position}
                        center = {center}
                        zoom={8.4}
                        minZoom={7}
                        maxBounds={bounds}
                        maxZoom={20}
                        whenCreated={setMap}
                        >
                          <TileLayer
                          attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                          />
                        </MapContainer>
                    <div className='wmtm_facilities'>
                      <h5>Facilities</h5>
                      <h2 >{props.dumpDataMain?.length}</h2>
                    </div>
                    <div className='wmtm_zoom'>
                      <ul>
                        <li><Link to='' onClick={mapZoomIn}><img  src='assets/lib/images/home/zoom-in.svg' /></Link></li>
                        <li><Link to='' onClick={mapZoomOut}><img  src='assets/lib/images/home/zoom-out.svg' /></Link></li>
                        <li><hr/></li>
                        <li className='wmtm_reset'><Link to='' onClick={mapReset}><img  src='assets/lib/images/home/reset.svg' /></Link></li>
                      </ul>
                    </div>
                  </div>

                  <div className='maps_sl w-100 d-flex align-items-end justify-content-between pt-3'>
                    <ul className='maps_source'>
                    
                      <li>  <Tooltip title="Maps are for graphical purposes only. They do not represent a legal survey. The boundaries and the names shown and the designations used on these maps are for demonstration purposes. While every effort has been made to ensure that these data are accurate and reliable within the limits of the current state of the art, I-Tech Mission cannot assume liability for any damages caused by any errors or omissions in the data, nor as a result of the failure of the data to function on a particular system. Users of our maps and other analysis products are solely responsible for interpretations made from these products." placement="top"><Link to=''>Disclaimer <img src='assets/lib/images/home/hover_arrow.svg' /></Link></Tooltip></li>
                    </ul>
                    <div  className='permission_type'>
                      <p>Permission type</p>
                      <ul>                        
                        <li>Licensed</li>
                        <li>Non-Licensed</li>
                      </ul>
                    </div>
                  </div>
             
                </div>



                <FaciliyModal modalTrigger={show} modalData = {modalData}/>
        </div>
        </>
      );
}

export default GeoData;
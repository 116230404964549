import { Component, useRef, useState } from 'react'
import './login-form.css';
import ForgetModal from '../forget.modal';
import AddRegisterNowModal from '../modal/addRegisterNowmodal';
// ======================================== Password Hide/Show
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { Navigate } from 'react-router';
// ======================================== Password Show/hide
var loginTemplate = function() {

    if(this.state.loggedIn) {
        return (<Navigate to={this.state.path} />);
    } else {

    return (
            <>    
             <div className="form-div">
                    <img className="img-responsive ministry_img" src="assets/images/logo_wmis.png" 
                        style={{'maxWidth': '270px', 'margin': '0 auto', 'marginBottom': '20px'}} />
                    <form onSubmit={(e)=>{this.login(e)}} className="contact-form yellow-form" noValidate autoComplete="off">
                        <div className={`form-group
                                    ${this.errorClass(this.state.formErrors.email)}`}>
                            <label htmlFor="email">email</label>
                            <fieldset>
                            <input className="form-control" type="email" name="email" autoComplete="off" maxLength="50" onBlur={(e)=>this.checkValidation(e)} value={this.state.email} onChange={(e)=> this.handleUserInput(e)} />
                            </fieldset>
                            <label style={{'color': 'red'}} className="error-msg">{this.state.formErrors.email}</label>
                        </div>
                      
                        <div className={`form-group
                                ${this.errorClass(this.state.formErrors.password)}`}>
                            <label htmlFor="password">password</label>
                            <fieldset>
                            {/* <input type="password" className="form-control" name="password" autoComplete="off" maxLength="20" onChange={(e)=> this.handleUserInput(e)} /> */}
                            <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                <OutlinedInput  inputProps={{ maxLength: 20, autocomplete: 'new-password',  form: { autocomplete: 'off',}, }} name="password" autoComplete="off" onBlur={(e)=>this.checkValidation(e)}  onChange={(e)=> this.handleUserInput(e)} id="outlined-adornment-password" type={this.state.showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility" onClick={this.handleClickShowPassword} onMouseDown={this.handleMouseDownPassword} edge="end">
                                                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            </fieldset>
                            <label style={{'color': 'red'}} className="error-msg">{this.state.formErrors.password}</label>
                        </div> 
                        <div className={`form-group-captcha no-gutters row position-relative ${this.errorClass(this.state.formErrors.captcha)}`}>
                            <div className="col-md">
                                  <div id="captcha"></div>
                            </div>
                            <div className="col-md-auto custom-captcha" >
                                <button id="createCaptcha" onClick={() => this.createCaptcha('captcha')}
                                    type="button" className="btn  refresh-btn">
                                        <img src="assets/lib/images/refresh-icon.svg" className="img-fluid" alt="" />
                                </button>
                            </div>
                            <div className="col-md captcha_inputfleid">
                                <div className="col-md captcha_input pr-0">
                                    <fieldset>
                                    <input className="captcha-position"  placeholder="captcha" name="captcha"
                                        id="cpatchaForgotTextboxrellax" autoComplete="off"
                                        maxLength="6" value={this.state.error ? '' : this.state.captcha} onChange={(e)=> this.handleCaptcha(e)} />
                                    </fieldset>
                                    <div className="invalid-cap"></div>
                                </div>
                                <label style={{'color': 'red'}} className="error-msg">{this.state.formErrors.captcha}</label>
                            </div>
                        </div>
                        <div className="form-group colored">
                            <div className="text-center">
                                <button className="login-b btn btn-primary w-100" style={{'color': '#fff !important', 'backgroundColor': '#1c3156 !important',
                                    'borderColor': '#3b63ab !important', 'display': 'flex !important', 'justifyContent': 'center !important', 
                                    'borderRadius': '10px !important'}} disabled={!this.state.formValid || this.state.captcha !== this.state.captchaCode}
                                    type="submit"
                                    color="warn">
                                        {/* TBD [disabled]="!loginForm.valid || isMatched == false || logAttemp >= 5" */}
                                    <span className="login-text" >{this.state.isLogining? <img style={{width:'15%'}} src="assets/ld2.gif"/>: 'Login'}</span>
                                      
                                </button>
                                <ForgetModal/> 
                            </div>
                        </div>                      
                    </form>
                    
                    <div className="bottom_link d-flex align-items-center justify-content-center">
                    New user?  <AddRegisterNowModal isModal={this.state.isModal}/>            
                    </div>
                   
                         

                </div>

      </>
    )
}
}

export default loginTemplate;
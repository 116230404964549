// export const server = "http://192.168.1.84/wmis/deewar/public/";    //rishav
// export const server = "http://192.168.1.197/wmis-eswatini/deewar/public/";
//  export const dataEntryPath = "http://localhost/WMIS-ESwatini/client/public/assets/download/withData/";
// export const server = 'https://itmcloud.org/eswawmis_apigateway/';
export const server = 'https://itmcloud.org/eswawmis_prod_apigateway/';
///harshit
// //with rishav's IP:-
// export const server = 'https://192.168.1.94/wmis/deewar/public/';
// export const facilityDocPath = "https://192.168.1.94/wmis/facilityUploads/";
// export const logFilePath = "https://192.168.1.94/wmis/logs/";
// export const uploadPath = "https://192.168.1.94/wmis/importLog/";
// export const resourceImagePath = "https://192.168.1.94/wmis/resourceUploads/";

// export const resourceImagePath = "https://itmcloud.org/eswatini-wmis/resourceUploads/";
// export const facilityDocPath = "https://itmcloud.org/eswatini-wmis/facilityUploads/";
// export const logFilePath = "https://itmcloud.org/eswatini-wmis/logs/";
// export const uploadPath = "https://itmcloud.org/eswatini-wmis/importLog/";

// export const server = 'https://itmcloud.org/eswawmis_apigateway/';

// export const server = 'http://localhost/wmis/deewar/public/';
// export const resourceImagePath = "http://localhost/wmis/resourceUploads/";
// export const facilityDocPath = "http://localhost/wmis/facilityDocs/";
// // export const actorDocPath = "http://localhost/wmis/facilityDocs/";
// export const logFilePath = "http://localhost/wmis/download/withData/";
// export const uploadPath = "http://localhost/wmis/importLog";


// export const resourceImagePath = "https://itmcloud.org/eswatini-wmis/resourceUploads/";
// export const facilityDocPath = "https://itmcloud.org/eswatini-wmis/facilityUploads/";
// export const logFilePath = "https://itmcloud.org/eswatini-wmis/logs/";
// export const uploadPath = "https://itmcloud.org/eswatini-wmis/importLog/";
// export const actorDocPath = "https://itmcloud.org/eswatini-wmis/actorDocs/";
// export const exportfile = "https://itmcloud.org/eswatini-wmis/exportwithoutdata/";

export const resourceImagePath = "https://itmcloud.org/eswatini-prod/resourceUploads/";
export const facilityDocPath = "https://itmcloud.org/eswatini-prod/facilityUploads/";
export const logFilePath = "https://itmcloud.org/eswatini-prod/logs/";
export const uploadPath = "https://itmcloud.org/eswatini-prod/importLog/";
export const actorDocPath = "https://itmcloud.org/eswatini-prod/actorDocs/";
export const exportfile = "https://itmcloud.org/eswatini-prod/exportwithoutdata/";

export const actorByState = {
    'Inactive' : 0,
    'Approved' : 1,
    'Pending' : 2,
    'Rejected' : 3
};

export const latitude = {
    'min' : -27.345840,
    'max' : -25.625111
}

export const longitude = {
    'min' : 30.648920,
    'max' : 32.340815
}

export const userRoles = [
    0, //guest
    1, //Admin
    5, //Actor
    2, //data Entry
    4, //data Approver
];

export const masterList = [
    // 'Actors',
    // 'Facility Type',
    'Operating Temperature',
    'Permission Status',
    'Operating hours',
    'Available Services',
    'Frequency',
    // 'Remaining Airspace',
    'Collection Vehicle',
    'Activity Type',
    'Source Type'
];


export const masterListType = [
    'Actors',
    'Facility Type',
    'Operating Temperature',
    'Waste Type',
    'Permission Status',
    'Operating hours',
    'Available Services',
    'Frequency',
    'Remaining Airspace',
    'Collection Vehicle',
    'Activity Type',
    'Source Type'
];

export const facilityPermissions = [
   { 'type':'Treatment of waste' , 
   'data':{
        'Name': {
            'required': true,
            'multiple': false
        },
        'Location' : {
            'required': true,
            'multiple': false
        },
        'Capacity' : {
            'required': true,
            'multiple': false
        },
        'Waste Type' : {
            'required': true,
            'multiple': true
        },
        'Coordinates' : {
            'required': true,
            'multiple': false
        },
        'Picture' : {
            'required': true,
            'multiple': false
        },
        'Facility Type' : {
            'required': true,
            'multiple': false
        },
        'Permission Status' : {
            'required': true,
            'multiple': false
        },
        'License Number' : {
            'required': true,
            'multiple': false
        },
        'License Awarded From' : {
            'required': true,
            'multiple': false
        },
        'License Awarded To' : {
            'required': true,
            'multiple': false
        },
        'Operating hours' : {
            'required': true,
            'multiple': false
        },
        'Number of working days' : {
            'required': true,
            'multiple': false
        },
        'Available services' : {
            'required': true,
            'multiple': true
        },
        'Operating temperature' : {
            'required': false,
            'multiple': false
        }
    }},
    { 'type': 'Designated waste storage' ,
    'data':{
        'Name': {
            'required': true,
            'multiple': false
        },
        'Location' : {
            'required': true,
            'multiple': false
        },
        'Capacity' : {
            'required': true,
            'multiple': false
        },
        'Waste Type' : {
            'required': true,
            'multiple': true
        },
        'Coordinates' : {
            'required': true,
            'multiple': false
        },
        'Picture' : {
            'required': true,
            'multiple': false
        },
        'Facility Type' : {
            'required': true,
            'multiple': false
        },
        'Permission Status' : {
            'required': true,
            'multiple': false
        },
        'License Number' : {
            'required': true,
            'multiple': false
        },
        'License Awarded From' : {
            'required': true,
            'multiple': false
        },
        'License Awarded To' : {
            'required': true,
            'multiple': false
        },
        'Operating hours' : {
            'required': true,
            'multiple': false
        },
        'Number of working days' : {
            'required': true,
            'multiple': false
        },
        'Available services' : {
            'required': true,
            'multiple': true
        },
        'Operating temperature' : {
            'required': false,
            'multiple': false
        }
    }},
    {'type':'Incinerator' ,
    'data': {
        'Name': {
            'required': true,
            'multiple': false
        },
        'Location' : {
            'required': true,
            'multiple': false
        },
        'Capacity' : {
            'required': false,
            'multiple': false
        },
        'Waste Type' : {
            'required': true,
            'multiple': true
        },
        'Coordinates' : {
            'required': true,
            'multiple': false
        },
        'Picture' : {
            'required': true,
            'multiple': false
        },
        'Facility Type' : {
            'required': true,
            'multiple': false
        },
        'Permission Status' : {
            'required': true,
            'multiple': false
        },
        'License Number' : {
            'required': true,
            'multiple': false
        },
        'License Awarded From' : {
            'required': true,
            'multiple': false
        },
        'License Awarded To' : {
            'required': true,
            'multiple': false
        },
        'Operating hours' : {
            'required': true,
            'multiple': false
        },
        'Number of working days' : {
            'required': true,
            'multiple': false
        },
        'Available services' : {
            'required': true,
            'multiple': true
        },
        'Operating temperature' : {
            'required': true,
            'multiple': false
        }
    }},
    {'type':'Recycling plant/facility', 
    'data': {
        'Name': {
            'required': true,
            'multiple': false
        },
        'Location' : {
            'required': true,
            'multiple': false
        },
        'Capacity' : {
            'required': false,
            'multiple': false
        },
        'Waste Type' : {
            'required': true,
            'multiple': true
        },
        'Coordinates' : {
            'required': true,
            'multiple': false
        },
        'Picture' : {
            'required': true,
            'multiple': false
        },
        'Facility Type' : {
            'required': true,
            'multiple': false
        },
        'Permission Status' : {
            'required': true,
            'multiple': false
        },
        'License Number' : {
            'required': true,
            'multiple': false
        },
        'License Awarded From' : {
            'required': true,
            'multiple': false
        },
        'License Awarded To' : {
            'required': true,
            'multiple': false
        },
        'Operating hours' : {
            'required': true,
            'multiple': false
        },
        'Number of working days' : {
            'required': true,
            'multiple': false
        },
        'Available services' : {
            'required': true,
            'multiple': true
        },
        'Operating temperature' : {
            'required': false,
            'multiple': false
        }
    }},
    {'type':'Waste transfer stations/facilities' ,
    'data': {
        'Name': {
            'required': true,
            'multiple': false
        },
        'Location' : {
            'required': true,
            'multiple': false
        },
        'Capacity' : {
            'required': true,
            'multiple': false
        },
        'Waste Type' : {
            'required': true,
            'multiple': true
        },
        'Coordinates' : {
            'required': true,
            'multiple': false
        },
        'Picture' : {
            'required': true,
            'multiple': false
        },
        'Facility Type' : {
            'required': true,
            'multiple': false
        },
        'Permission Status' : {
            'required': true,
            'multiple': false
        },
        'License Number' : {
            'required': true,
            'multiple': false
        },
        'License Awarded From' : {
            'required': true,
            'multiple': false
        },
        'License Awarded To' : {
            'required': true,
            'multiple': false
        },
        'Operating hours' : {
            'required': true,
            'multiple': false
        },
        'Number of working days' : {
            'required': true,
            'multiple': false
        },
        'Available services' : {
            'required': true,
            'multiple': true
        },
        'Operating temperature' : {
            'required': false,
            'multiple': false
        }
    }  },

    {'type':'Composting facilities',
    'data' : {
        'Name': {
            'required': true,
            'multiple': false
        },
        'Location' : {
            'required': true,
            'multiple': false
        },
        'Capacity' : {
            'required': true,
            'multiple': false
        },
        'Waste Type' : {
            'required':false,
            'multiple': true
        },
        'Coordinates' : {
            'required': true,
            'multiple': false
        },
        'Picture' : {
            'required': true,
            'multiple': false
        },
        'Facility Type' : {
            'required': true,
            'multiple': false
        },
        'Permission Status' : {
            'required': true,
            'multiple': false
        },
        'License Number' : {
            'required': true,
            'multiple': false
        },
        'License Awarded From' : {
            'required': true,
            'multiple': false
        },
        'License Awarded To' : {
            'required': true,
            'multiple': false
        },
        'Operating hours' : {
            'required': true,
            'multiple': false
        },
        'Number of working days' : {
            'required': true,
            'multiple': false
        },
        'Available services' : {
            'required': true,
            'multiple': true
        },
        'Operating temperature' : {
            'required': false,
            'multiple': false
        }
    } },
    
   {'type': 'Remediation of contaminated land',
   'data' : {
        'Name': {
            'required': true,
            'multiple': false
        },
        'Location' : {
            'required': true,
            'multiple': false
        },
        'Capacity' : {
            'required': false,
            'multiple': false
        },
        'Waste Type' : {
            'required':false,
            'multiple': true
        },
        'Coordinates' : {
            'required': true,
            'multiple': false
        },
        'Picture' : {
            'required': true,
            'multiple': false
        },
        'Facility Type' : {
            'required': true,
            'multiple': false
        },
        'Permission Status' : {
            'required': false,
            'multiple': false
        },
        'License Number' : {
            'required': false,
            'multiple': false
        },
        'License Awarded From' : {
            'required': false,
            'multiple': false
        },
        'License Awarded To' : {
            'required': false,
            'multiple': false
        },
        'Operating hours' : {
            'required': false,
            'multiple': false
        },
        'Number of working days' : {
            'required': true,
            'multiple': false
        },
        'Available services' : {
            'required': true,
            'multiple': true
        },
        'Operating temperature' : {
            'required': false,
            'multiple': false
        }
    } },

    {'type':'Sanitary landfill',
    'data' : {
        'Name': {
            'required': true,
            'multiple': false
        },
        'Location' : {
            'required': true,
            'multiple': false
        },
        'Capacity' : {
            'required': true,
            'multiple': false
        },
        'Waste Type' : {
            'required':true,
            'multiple': true
        },
        'Coordinates' : {
            'required': true,
            'multiple': false
        },
        'Picture' : {
            'required': true,
            'multiple': false
        },
        'Facility Type' : {
            'required': true,
            'multiple': false
        },
        'Permission Status' : {
            'required': true,
            'multiple': false
        },
        'License Number' : {
            'required': true,
            'multiple': false
        },
        'License Awarded From' : {
            'required': true,
            'multiple': false
        },
        'License Awarded To' : {
            'required': true,
            'multiple': false
        },
        'Operating hours' : {
            'required': true,
            'multiple': false
        },
        'Number of working days' : {
            'required': true,
            'multiple': false
        },
        'Available services' : {
            'required': true,
            'multiple': true
        },
        'Operating temperature' : {
            'required': false,
            'multiple': false
        }
    } },
    {'type':'Controlled dumpsite',
    'data' : {
        'Name': {
            'required': true,
            'multiple': false
        },
        'Location' : {
            'required': true,
            'multiple': false
        },
        'Capacity' : {
            'required': true,
            'multiple': false
        },
        'Waste Type' : {
            'required':true,
            'multiple': true
        },
        'Coordinates' : {
            'required': true,
            'multiple': false
        },
        'Picture' : {
            'required': true,
            'multiple': false
        },
        'Facility Type' : {
            'required': true,
            'multiple': false
        },
        'Permission Status' : {
            'required': true,
            'multiple': false
        },
        'License Number' : {
            'required': true,
            'multiple': false
        },
        'License Awarded From' : {
            'required': true,
            'multiple': false
        },
        'License Awarded To' : {
            'required': true,
            'multiple': false
        },
        'Operating hours' : {
            'required': true,
            'multiple': false
        },
        'Number of working days' : {
            'required': true,
            'multiple': false
        },
        'Available services' : {
            'required': true,
            'multiple': true
        },
        'Operating temperature' : {
            'required': false,
            'multiple': false
        }
    } },
    {'type':'Open dumpsite',
    'data' : {
        'Name': {
            'required': true,
            'multiple': false
        },
        'Location' : {
            'required': true,
            'multiple': false
        },
        'Capacity' : {
            'required': true,
            'multiple': false
        },
        'Waste Type' : {
            'required':true,
            'multiple': true
        },
        'Coordinates' : {
            'required': true,
            'multiple': false
        },
        'Picture' : {
            'required': true,
            'multiple': false
        },
        'Facility Type' : {
            'required': true,
            'multiple': false
        },
        'Permission Status' : {
            'required': true,
            'multiple': false
        },
        'License Number' : {
            'required': true,
            'multiple': false
        },
        'License Awarded From' : {
            'required': true,
            'multiple': false
        },
        'License Awarded To' : {
            'required': true,
            'multiple': false
        },
        'Operating hours' : {
            'required': true,
            'multiple': false
        },
        'Number of working days' : {
            'required': true,
            'multiple': false
        },
        'Available services' : {
            'required': true,
            'multiple': true
        },
        'Operating temperature' : {
            'required': false,
            'multiple': false
        }
    } },

    {'type':'Wastewater treatment plant',
    'data' : {
        'Name': {
            'required': true,
            'multiple': false
        },
        'Location' : {
            'required': true,
            'multiple': false
        },
        'Capacity' : {
            'required': true,
            'multiple': false
        },
        'Waste Type' : {
            'required':false,
            'multiple': true
        },
        'Coordinates' : {
            'required': true,
            'multiple': false
        },
        'Picture' : {
            'required': true,
            'multiple': false
        },
        'Facility Type' : {
            'required': true,
            'multiple': false
        },
        'Permission Status' : {
            'required': true,
            'multiple': false
        },
        'License Number' : {
            'required': true,
            'multiple': false
        },
        'License Awarded From' : {
            'required': true,
            'multiple': false
        },
        'License Awarded To' : {
            'required': true,
            'multiple': false
        },
        'Operating hours' : {
            'required': true,
            'multiple': false
        },
        'Number of working days' : {
            'required': true,
            'multiple': false
        },
        'Available services' : {
            'required': true,
            'multiple': true
        },
        'Operating temperature' : {
            'required': false,
            'multiple': false
        }
    } },
    {'type':'Wastewater treatment pond',
    'data' : {
        'Name': {
            'required': true,
            'multiple': false
        },
        'Location' : {
            'required': true,
            'multiple': false
        },
        'Capacity' : {
            'required': true,
            'multiple': false
        },
        'Waste Type' : {
            'required':false,
            'multiple': true
        },
        'Coordinates' : {
            'required': true,
            'multiple': false
        },
        'Picture' : {
            'required': true,
            'multiple': false
        },
        'Facility Type' : {
            'required': true,
            'multiple': false
        },
        'Permission Status' : {
            'required': true,
            'multiple': false
        },
        'License Number' : {
            'required': true,
            'multiple': false
        },
        'License Awarded From' : {
            'required': true,
            'multiple': false
        },
        'License Awarded To' : {
            'required': true,
            'multiple': false
        },
        'Operating hours' : {
            'required': true,
            'multiple': false
        },
        'Number of working days' : {
            'required': true,
            'multiple': false
        },
        'Available services' : {
            'required': true,
            'multiple': true
        },
        'Operating temperature' : {
            'required': false,
            'multiple': false
        }
    } },
    {'type':'Buy-back centres' ,
    'data': {
        'Name': {
            'required': true,
            'multiple': false
        },
        'Location' : {
            'required': true,
            'multiple': false
        },
        'Capacity' : {
            'required': false,
            'multiple': false
        },
        'Waste Type' : {
            'required':false,
            'multiple': true
        },
        'Coordinates' : {
            'required': true,
            'multiple': false
        },
        'Picture' : {
            'required': true,
            'multiple': false
        },
        'Facility Type' : {
            'required': true,
            'multiple': false
        },
        'Permission Status' : {
            'required': true,
            'multiple': false
        },
        'License Number' : {
            'required': false,
            'multiple': false
        },
        'License Awarded From' : {
            'required': false,
            'multiple': false
        },
        'License Awarded To' : {
            'required': false,
            'multiple': false
        },
        'Operating hours' : {
            'required': true,
            'multiple': false
        },
        'Number of working days' : {
            'required': true,
            'multiple': false
        },
        'Available services' : {
            'required': true,
            'multiple': true
        },
        'Operating temperature' : {
            'required': false,
            'multiple': false
        }
    } },
    {'type':'Drop-off points' ,
    'data': {
        'Name': {
            'required': true,
            'multiple': false
        },
        'Location' : {
            'required': true,
            'multiple': false
        },
        'Capacity' : {
            'required': false,
            'multiple': false
        },
        'Waste Type' : {
            'required':false,
            'multiple': true
        },
        'Coordinates' : {
            'required': true,
            'multiple': false
        },
        'Picture' : {
            'required': true,
            'multiple': false
        },
        'Facility Type' : {
            'required': true,
            'multiple': false
        },
        'Permission Status' : {
            'required': true,
            'multiple': false
        },
        'License Number' : {
            'required': false,
            'multiple': false
        },
        'License Awarded From' : {
            'required': false,
            'multiple': false
        },
        'License Awarded To' : {
            'required': false,
            'multiple': false
        },
        'Operating hours' : {
            'required': true,
            'multiple': false
        },
        'Number of working days' : {
            'required': true,
            'multiple': false
        },
        'Available services' : {
            'required': true,
            'multiple': true
        },
        'Operating temperature' : {
            'required': false,
            'multiple': false
        }
    } },
    {'type':'Communal Waste Disposal site',
    'data': {
        'Name': {
            'required': true,
            'multiple': false
        },
        'Location' : {
            'required': true,
            'multiple': false
        },
        'Capacity' : {
            'required': false,
            'multiple': false
        },
        'Waste Type' : {
            'required': true,
            'multiple': true
        },
        'Coordinates' : {
            'required': true,
            'multiple': false
        },
        'Picture' : {
            'required': true,
            'multiple': false
        },
        'Facility Type' : {
            'required': true,
            'multiple': false
        },
        'Permission Status' : {
            'required': false,
            'multiple': false
        },
        'License Number' : {
            'required': false,
            'multiple': false
        },
        'License Awarded From' : {
            'required': false,
            'multiple': false
        },
        'License Awarded To' : {
            'required': false,
            'multiple': false
        },
        'Operating hours' : {
            'required': false,
            'multiple': false
        },
        'Number of working days' : {
            'required': false,
            'multiple': false
        },
        'Available services' : {
            'required': false,
            'multiple': false
        },
        'Operating temperature' : {
            'required': false,
            'multiple': false
        }
    } },
    {'type':'Storage',
    'data': {
        'Name': {
            'required': true,
            'multiple': false
        },
        'Location' : {
            'required': true,
            'multiple': false
        },
        'Capacity' : {
            'required': false,
            'multiple': false
        },
        'Waste Type' : {
            'required': true,
            'multiple': true
        },
        'Coordinates' : {
            'required': true,
            'multiple': false
        },
        'Picture' : {
            'required': true,
            'multiple': false
        },
        'Facility Type' : {
            'required': true,
            'multiple': false
        },
        'Permission Status' : {
            'required': true,
            'multiple': false
        },
        'License Number' : {
            'required': false,
            'multiple': false
        },
        'License Awarded From' : {
            'required': false,
            'multiple': false
        },
        'License Awarded To' : {
            'required': false,
            'multiple': false
        },
        'Operating hours' : {
            'required': true,
            'multiple': false
        },
        'Number of working days' : {
            'required': true,
            'multiple': false
        },
        'Available services' : {
            'required': true,
            'multiple': true
        },
        'Operating temperature' : {
            'required': false,
            'multiple': false
        }
    } },
]

export const actortype ={
    'Generator' : {
        'Name': {
            'required': true,
            'multiple': false
        },
        'Location': {
            'required': true,
            'multiple': false
        },
        'Phone Number' : {
            'required': true,
            'multiple': false
        },
        'Email ID' : {
            'required': true,
            'multiple': false
        },
        'Type of waste' : {
            'required': true,
            'multiple': true
        },
        'Generation rate (Tonnes/month)' : {
            'required': true,
            'multiple': false
        },
        'Collection Vehicle' : {
            'required': false,
            'multiple': false
        },
        'Capacity' : {
            'required': false,
            'multiple': false
        },
        'Frequency' : {
            'required': false,
            'multiple': false
        },
        'Facility Type' : {
            'required': false,
            'multiple': false
        },
        'Waste licence No.' : {
            'required': false,
            'multiple': false
        },
        'License Awarded From (Date)' : {
            'required': false,
            'multiple': false
        },
        'License Awarded To (Date)' : {
            'required': false,
            'multiple': false
        },
        'Destination' : {
            'required': false,
            'multiple': false
        },
        'Origin' : {
            'required': false,
            'multiple': false
        },
        'Date of registration' : {
            'required': true,
            'multiple': false
        
    },





    },

    'Collector' : {
        'Name': {
            'required': true,
            'multiple': false
        },
        'Location': {
            'required': true,
            'multiple': false
        },
        'Phone Number' : {
            'required': true,
            'multiple': false
        },
        'Email ID' : {
            'required': true,
            'multiple': false
        },
        'Type of waste' : {
            'required': true,
            'multiple': true
        },
        'Generation rate (Tonnes/month)' : {
            'required': false,
            'multiple': false
        },
        'Collection Vehicle' : {
            'required': true,
            'multiple': false
        },
        'Capacity' : {
            'required':true,
            'multiple': false
        },
        'Frequency' : {
            'required': true,
            'multiple': false
        },
        'Facility Type' : {
            'required': true,
            'multiple': false
        },
        'Waste licence No.' : {
            'required': true,
            'multiple': false
        },
        'License Awarded From (Date)' : {
            'required': true,
            'multiple': false
        },
        'License Awarded To (Date)' : {
            'required': true,
            'multiple': false
        },
        'Destination' : {
            'required': false,
            'multiple': false
        },
        'Origin' : {
            'required': false,
            'multiple': false
        },
        'Date of registration' : {
            'required': true,
            'multiple': false
        
    },





    },

    'Recycler/reclaimer' : {
        'Name': {
            'required': true,
            'multiple': false
        },
        'Location': {
            'required': true,
            'multiple': false
        },
        'Phone Number' : {
            'required': true,
            'multiple': false
        },
        'Email ID' : {
            'required': true,
            'multiple': false
        },
        'Type of waste' : {
            'required': true,
            'multiple': true
        },
        'Generation rate (Tonnes/month)' : {
            'required': false,
            'multiple': false
        },
        'Collection Vehicle' : {
            'required': false,
            'multiple': false
        },
        'Capacity' : {
            'required':false,
            'multiple': false
        },
        'Frequency' : {
            'required': false,
            'multiple': false
        },
        'Facility Type' : {
            'required': true,
            'multiple': false
        },
        'Waste licence No.' : {
            'required': true,
            'multiple': false
        },
        'License Awarded From (Date)' : {
            'required': true,
            'multiple': false
        },
        'License Awarded To (Date)' : {
            'required': true,
            'multiple': false
        },
        'Destination' : {
            'required': false,
            'multiple': false
        },
        'Origin' : {
            'required': false,
            'multiple': false
        },
        'Date of registration' : {
            'required': true,
            'multiple': false
        
    },





    },


    'Importer' : {
        'Name': {
            'required': true,
            'multiple': false
        },
        'Location': {
            'required': true,
            'multiple': false
        },
        'Phone Number' : {
            'required': true,
            'multiple': false
        },
        'Email ID' : {
            'required': true,
            'multiple': false
        },
        'Type of waste' : {
            'required': true,
            'multiple': true
        },
        'Generation rate (Tonnes/month)' : {
            'required': false,
            'multiple': false
        },
        'Collection Vehicle' : {
            'required': false,
            'multiple': false
        },
        'Capacity' : {
            'required':false,
            'multiple': false
        },
        'Frequency' : {
            'required': false,
            'multiple': false
        },
        'Facility Type' : {
            'required': true,
            'multiple': false
        },
        'Waste licence No.' : {
            'required': true,
            'multiple': false
        },
        'License Awarded From (Date)' : {
            'required': true,
            'multiple': false
        },
        'License Awarded To (Date)' : {
            'required': true,
            'multiple': false
        },
        'Destination' : {
            'required': false,
            'multiple': false
        },
        'Origin' : {
            'required': true,
            'multiple': false
        },
        'Date of registration' : {
            'required': true,
            'multiple': false
        
    },

    },
    

    'Exporter' : {
        'Name': {
            'required': true,
            'multiple': false
        },
        'Location': {
            'required': true,
            'multiple': false
        },
        'Phone Number' : {
            'required': true,
            'multiple': false
        },
        'Email ID' : {
            'required': true,
            'multiple': false
        },
        'Type of waste' : {
            'required': true,
            'multiple': true
        },
        'Generation rate (Tonnes/month)' : {
            'required': false,
            'multiple': false
        },
        'Collection Vehicle' : {
            'required': false,
            'multiple': false
        },
        'Capacity' : {
            'required':false,
            'multiple': false
        },
        'Frequency' : {
            'required': false,
            'multiple': false
        },
        'Facility Type' : {
            'required': true,
            'multiple': false
        },
        'Waste licence No.' : {
            'required': true,
            'multiple': false
        },
        'License Awarded From (Date)' : {
            'required': true,
            'multiple': false
        },
        'License Awarded To (Date)' : {
            'required': true,
            'multiple': false
        },
        'Destination' : {
            'required': true,
            'multiple': false
        },
        'Origin' : {
            'required': false,
            'multiple': false
        },
        'Date of registration' : {
            'required': true,
            'multiple': false
        
    },

    },

    'Disposer' : {
        'Name': {
            'required': true,
            'multiple': false
        },
        'Location': {
            'required': true,
            'multiple': false
        },
        'Phone Number' : {
            'required': true,
            'multiple': false
        },
        'Email ID' : {
            'required': true,
            'multiple': false
        },
        'Type of waste' : {
            'required': true,
            'multiple': true
        },
        'Generation rate (Tonnes/month)' : {
            'required': false,
            'multiple': false
        },
        'Collection Vehicle' : {
            'required': false,
            'multiple': false
        },
        'Capacity' : {
            'required':false,
            'multiple': false
        },
        'Frequency' : {
            'required': false,
            'multiple': false
        },
        'Facility Type' : {
            'required': true,
            'multiple': false
        },
        'Waste licence No.' : {
            'required': true,
            'multiple': false
        },
        'License Awarded From (Date)' : {
            'required': true,
            'multiple': false
        },
        'License Awarded To (Date)' : {
            'required': true,
            'multiple': false
        },
        'Destination' : {
            'required': false,
            'multiple': false
        },
        'Origin' : {
            'required': false,
            'multiple': false
        },
        'Date of registration' : {
            'required': true,
            'multiple': false
        
    },

    },



    'Visitor/researcher' : {
        'Name': {
            'required': true,
            'multiple': false
        },
        'Location': {
            'required': false,
            'multiple': false
        },
        'Phone Number' : {
            'required': true,
            'multiple': false
        },
        'Email ID' : {
            'required': false,
            'multiple': false
        },
        'Type of waste' : {
            'required': false,
            'multiple': true
        },
        'Generation rate (Tonnes/month)' : {
            'required': false,
            'multiple': false
        },
        'Collection Vehicle' : {
            'required': false,
            'multiple': false
        },
        'Capacity' : {
            'required':false,
            'multiple': false
        },
        'Frequency' : {
            'required': false,
            'multiple': false
        },
        'Facility Type' : {
            'required': false,
            'multiple': false
        },
        'Waste licence No.' : {
            'required': false,
            'multiple': false
        },
        'License Awarded From (Date)' : {
            'required': false,
            'multiple': false
        },
        'License Awarded To (Date)' : {
            'required': false,
            'multiple': false
        },
        'Destination' : {
            'required': false,
            'multiple': false
        },
        'Origin' : {
            'required': false,
            'multiple': false
        },
        'Date of registration' : {
            'required': true,
            'multiple': false
        
    },

    },


    'Remediator' : {
        'Name': {
            'required': true,
            'multiple': false
        },
        'Location': {
            'required': true,
            'multiple': false
        },
        'Phone Number' : {
            'required': true,
            'multiple': false
        },
        'Email ID' : {
            'required': true,
            'multiple': false
        },
        'Type of waste' : {
            'required': true,
            'multiple': true
        },
        'Generation rate (Tonnes/month)' : {
            'required': false,
            'multiple': false
        },
        'Collection Vehicle' : {
            'required': false,
            'multiple': false
        },
        'Capacity' : {
            'required':false,
            'multiple': false
        },
        'Frequency' : {
            'required': false,
            'multiple': false
        },
        'Facility Type' : {
            'required': false,
            'multiple': false
        },
        'Waste licence No.' : {
            'required': true,
            'multiple': false
        },
        'License Awarded From (Date)' : {
            'required': true,
            'multiple': false
        },
        'License Awarded To (Date)' : {
            'required': true,
            'multiple': false
        },
        'Destination' : {
            'required': false,
            'multiple': false
        },
        'Origin' : {
            'required': false,
            'multiple': false
        },
        'Date of registration' : {
            'required': true,
            'multiple': false
        
    },

    },


}

export const countryList = [
    "Angola",
    "Burundi",
    "Benin",
    "Burkina Faso",
    "Botswana",
    "Central African Republic",
    "Cote d’Ivoire",
    "Cameroon",
    "Congo, Dem. Rep.",
    "Congo, Rep.",
    "Comoros",
    "Cabo Verde",
    "Djibouti",
    "Algeria",
    "Egypt, Arab Rep.",
    "Eritrea",
    "Ethiopia",
    "Gabon",
    "Ghana",
    "Guinea",
    "Gambia, The",
    "Guinea-Bissau",
    "Equatorial Guinea",
    "Kenya",
    "Liberia",
    "Libya",
    "Lesotho",
    "Morocco",
    "Madagascar",
    "Mali",
    "Mozambique",
    "Mauritania",
    "Mauritius",
    "Malawi",
    "Namibia",
    "Niger",
    "Nigeria",
    "Rwanda",
    "Sudan",
    "Senegal",
    "Sierra Leone",
    "Somalia",
    "South Sudan",
    "Sao Tome and Principe",
    "Eswatini",
    "Seychelles",
    "Chad",
    "Togo",
    "Tunisia",
    "Tanzania",
    "Uganda",
    "South Africa",
    "Zambia",
    "Zimbabwe",
    "Afghanistan",
    "United Arab Emirates",
    "Armenia",
    "Azerbaijan",
    "Bangladesh",
    "Bahrain",
    "Brunei Darussalam",
    "Bhutan",
    "China",
    "Cyprus",
    "Georgia",
    "Hong Kong SAR, China",
    "Indonesia",
    "India",
    "Iran, Islamic Rep.",
    "Iraq",
    "Israel",
    "Jordan",
    "Japan",
    "Kazakhstan",
    "Kyrgyz Republic",
    "Cambodia",
    "Korea, Rep.",
    "Kosovo",
    "Kuwait",
    "Lao PDR",
    "Lebanon",
    "Sri Lanka",
    "Macao SAR, China",
    "Maldives",
    "Myanmar",
    "Mongolia",
    "Malaysia",
    "Nepal",
    "Oman",
    "Pakistan",
    "Philippines",
    "Korea, Dem. People's Rep.",
    "West Bank and Gaza",
    "Qatar",
    "Saudi Arabia",
    "Singapore",
    "Syrian Arab Republic",
    "Thailand",
    "Tajikistan",
    "Turkmenistan",
    "Timor-Leste",
    "Turkey",
    "Uzbekistan",
    "Vietnam",
    "Yemen, Rep.",
    "Curacao",
    "Channel Islands",
    "Albania",
    "Andorra",
    "Austria",
    "Belgium",
    "Bulgaria",
    "Bosnia and Herzegovina",
    "Belarus",
    "Switzerland",
    "Czech Republic",
    "Germany",
    "Denmark",
    "Spain",
    "Estonia",
    "Finland",
    "France",
    "Faroe Islands",
    "United Kingdom",
    "Gibraltar",
    "Greece",
    "Croatia",
    "Hungary",
    "Isle of Man",
    "Ireland",
    "Iceland",
    "Italy",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Latvia",
    "Monaco",
    "Moldova",
    "North Macedonia",
    "Malta",
    "Montenegro",
    "Netherlands",
    "Norway",
    "Poland",
    "Portugal",
    "Romania",
    "Russian Federation",
    "San Marino",
    "Serbia",
    "Slovak Republic",
    "Slovenia",
    "Sweden",
    "Ukraine",
    "Aruba",
    "Argentina",
    "Antigua and Barbuda",
    "Bahamas, The",
    "Belize",
    "Bolivia",
    "Brazil",
    "Barbados",
    "Chile",
    "Colombia",
    "Costa Rica",
    "Cuba",
    "Cayman Islands",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Grenada",
    "Guatemala",
    "Guyana",
    "Honduras",
    "Haiti",
    "Jamaica",
    "St. Kitts and Nevis",
    "St. Lucia",
    "Mexico",
    "Nicaragua",
    "Panama",
    "Peru",
    "Puerto Rico",
    "Paraguay",
    "El Salvador",
    "Suriname",
    "Turks and Caicos Islands",
    "Trinidad and Tobago",
    "Uruguay",
    "St. Vincent and the Grenadines",
    "Venezuela, RB",
    "British Virgin Islands",
    "Virgin Islands (U.S.)",
    "St. Martin (French part)",
    "Bermuda",
    "Canada",
    "Greenland",
    "United States",
    "American Samoa",
    "Australia",
    "Fiji",
    "Micronesia, Fed. Sts.",
    "Guam",
    "Kiribati",
    "Marshall Islands",
    "Northern Mariana Islands",
    "New Caledonia",
    "Nauru",
    "New Zealand",
    "Palau",
    "Papua New Guinea",
    "French Polynesia",
    "Solomon Islands",
    "Tonga",
    "Tuvalu",
    "Vanuatu",
    "Samoa",
    "Sint Maarten (Dutch part)"	
];
 
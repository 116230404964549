import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import * as constants from '../../../Config/app.constants';
import "react-toastify/dist/ReactToastify.css";
import Button from '@mui/material/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Stack from '@mui/material/Stack';



import moment from 'moment';
import CheckboxTree from 'react-checkbox-tree';
import Dropdown from 'react-bootstrap/Dropdown';
import { TextField } from "@material-ui/core";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from "dayjs";
import * as $ from 'jquery';
import adminService from "../../../services/admin.service";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';



import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function AddActivityModal(props) {


  const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
  ];


  const form = useRef();
  const [wasteList, setWasteList] = useState([])
  const [areaTree, setAreaTree] = useState([]);
  const [, forceUpdate] = useState();
  const imageRef = useRef();
  const areaCheckedvalue=[];
  const matchedAreaData = [];
  const matcheddata = [];
  const [WTExpandareaExpand, setAreaExpanded ] = useState()
  const [areaChecked ,setAreaChecked] = useState([])
  const [licenceNoState ,setLicenceNo] = useState()
  const [licenceAwardedFrom ,setLicenceAwardedfrom] = useState()
  const [licenceAwardedTo ,setLicenceAwardedTo] = useState()
  
  
  const [sourceTypedatalist, setsourceTypedatalist] = useState([]);
const [areaTreeList, setareaTreeList] = useState([])
const [areaCheckedList, setAreaCheckedList] = useState([])

  const [state, setState] = useState({
    "id": '',
    "activityId" : '',
    "actorLocation": null,
    "facilityTypeName" : [],
    
    "WTChecked" : [],
    "callData" : [],
    "areaListState" : [],
    "WTExpand" : [],
    "areaChecked" : [],
    "areaExpand" : [],
    "actorDropdownLocation": null,
    "actorType": '',
    "activityType": '',
    "selectedArea":[],
    
    "facilityLocation": null,
    "facilitylocname" : '',
    "facilityloccode" : '',
    "facilityName": '',
    "facilityType": '',
    "wasteType": '',
    "document": '',
    "wasteTree" : [],
    "documentpreview": '',
    "quantity": 0,
    "qty_unit": 'Kg',
    "collectionVehicle": '',
    "destination": '',
    "origin": '',
    "activityDateTime": dayjs(),
  
    "areaData": null,
    "description": ""
  });



  const multiSourceChange = (event) => {
    const {
      target: { value },
    } = event;
    setsourceTypedatalist(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const renderTestTree = (nodes) => {
        
    return (
        <>
        {nodes && nodes.map(node=> {
              
            return (
            <li>
                    <span class="caret"></span>
                    <div 
                    
                     className={!props.actorAreaData.includes(node.value)? 'text-gray':'' } > 
                        <input 
                        disabled={!props.actorAreaData.includes(node.value)  } 
                        checked={areaChecked?.includes(node.value)} 
                        value={areaChecked}
                        // value={state.areaChecked}
                        // checked={ FormState.areaCode==node.value } 
                        onChange={(e)=>{getAreaList(e, node)}} 
                         type="checkbox" name="arearadio" /> 
                        <span for="arearadio">{node.label}</span></div>
                    <ul class="nested">
                        {
                            renderTestTree(node.children)
                        }
                    </ul>
                </li>
            )
        })

        }
        </>
    );
}

  const handleSubmit = (e) =>{

    e.preventDefault();
    
    
    let dataToSend = [];
    console.log("What is isedit??-->", props.isEdit)
    if(props.isEdit==true){
        console.log("edit")
        console.log("statecollection",state.collectionVehicle)
        dataToSend = {
        
            
              "id" : state.activityId,
          "actor_type": state.actorType,
          "facility_type": state.facilityType,
          "facility_name": state.facilityName,
          "type_of_waste": state.wasteType,
          "activity_type": state.activityType,
          "quantity" : state.quantity,
          "qty_unit" : state.qty_unit,
          "Collection_Vehicle" : state.collectionVehicle,
          "destination": state.destination,
          "origin" : state.origin,
          "activity_date_time": state.activityDateTime,
          
          // "document" : state.document,
          "document": state.document==''? '':state.document,
          "source_of_waste" : sourceTypedatalist,
          "facility_location": areaChecked,
          "actor_location": state.actorLocation ? state.actorLocation.areaCode : '',
          "description": state.description
        }

        props.newDataEntry(dataToSend);
        e.target.reset();


    }
    else{
        console.log("rishav")
         dataToSend = {
          "actor_type": state.actorType,
          "facility_type": state.facilityType,
          "facility_name": state.facilityName,
          "type_of_waste": state.wasteType,
          "activity_type": state.activityType,
          "quantity" : state.quantity,
          "qty_unit" : state.qty_unit,
          "Collection_Vehicle" : state.collectionVehicle,
          "destination": state.destination,
          "origin" : state.origin,
          "activity_date_time": state.activityDateTime,
          "source_of_waste" : sourceTypedatalist,
          "status" : 2,
          "n_status" : 1,
          "document" : state.document==''? '':state.document,
          
          "facility_location": areaChecked,
          "actor_location": state.actorLocation ? state.actorLocation.areaCode : '',
          "description": state.description
        };
        
        props.newDataEntry(dataToSend);
        e.target.reset();
    }

    
            
            
    
    


}
 

  useEffect(() => {
  




    setTimeout(() => {
      $('.caret').on('click', function() {
        
  
        // console.log("---", this.parentElement.querySelector(".nested").classList.toggle("active"))
        this.parentElement.querySelector(".nested").classList.toggle("active");
        this.classList.toggle("caret-down");
    })
    }, 6000);
 

    if(props.WasteTypeOrg != '' && props.WasteTypeOrg != null){
      console.log("props.WasteTypeOrg",props.WasteTypeOrg)
      let flatLevel = [];

      props.WasteTypeOrg?.forEach((item) => {
        props.wtTreeData?.forEach(dt => {

              if (item.value == dt) {

                  var obj = {
                      value: item.value,
                      label: item.label,
                      parent: item.parent,
                      level: item.level,
                     
                  }

                  flatLevel.push(obj)

              }

          })
      });


      var flater2 = [];
      props.WasteTypeOrg?.forEach((item) => {
          flatLevel?.forEach(dt => {
              if (item.value == dt.parent) {
                  var obj = {
                      value: item.value,
                      label: item.label,
                      parent: item.parent,
                      level: item.level,



                  }

                  flater2.push(obj)

              }
          })
      })


      var flater3 = [];
      props.WasteTypeOrg?.forEach((item) => {
          flater2?.forEach(dt => {
              
              if (item.value == dt.parent) {
                  var obj = {
                      value: item.value,
                      label: item.label,
                      parent: item.parent,
                      level: item.level,


                  }

                  flater3.push(obj)

              }
          })
      })

      var merger = flatLevel.concat(flater3, flater2)
      var finalMerger = []
      merger?.forEach((item) => {
        props.wtTreeData?.forEach(dt => {
              var obj = {}
              if (item.value === dt) {
                  obj = {
                      value: item.value,
                      label: item.label,
                      parent: item.parent,
                      level: item.level,
                      disabled: true

                  }
              }
              else {
                  obj = {
                      value: item.value,
                      label: item.label,
                      parent: item.parent,
                      level: item.level,
                      disabled: true

                  }
              }
              finalMerger.push(obj)

          })

      })
      
      var data = [];
      
          data = setLevelData(finalMerger)
      
      

      setWasteList(data)

  }

    console.log("ptopsarea",props.areaData)
    


  if(props.areaData!='' && props.areaData!=null){
    
    
    setState({...state,areaListState : []})
    let flatLevel = []
    props.areaData?.forEach((item) => {
  
      
        var obj = {
            value: item.area_code,
            label: item.name,
            parent: item.parent_id,
            level:item.level
           
          }


   
    

        flatLevel.push(obj)


    })

    const data = setLevelData(flatLevel);

    
    console.log('flatLevel',data)

    
    setareaTreeList(data)

    
}

    if(props.isEdit==true){
        
      var data = props.editData[0];
     
      console.log("isedit")

      changeLicenceData(data['activity_type']);
     
      var newActorLoc = '';
      var newFacLoc = [];
      props.flatArea?.forEach(item=>{

      
        if(item?.area_code==data['actor_location']){
          var obj = {areaName: item.name, areaCode: item.area_code}
          newActorLoc =  obj;
        }
        
        if(data['facility_location'].includes(item?.area_code)){
          var obj = {label: item.name, value: item.area_code}
          newFacLoc.push(obj);
        }

      })
      console.log("newFacLoc",newFacLoc,data['facility_location'],props.flatArea)
      setAreaCheckedList(data['facility_location'])
      setAreaChecked(data['facility_location'])
      setsourceTypedatalist(data['source_of_waste'])
      // setState({...state,selectedArea : newFacLoc})
      // getAreaList(data['facility_location']);
      setState({
        selectedArea :   newFacLoc,  
        activityId:data['_id'],
        actorType:data['actor_type'],
        facilityType:data['facility_type'],
        facilityName:data.facility_name,
        wasteType:data.type_of_waste,
        activityType:data['activity_type'],
        quantity:data['quantity'],
        qty_unit:data['unit'],
        
        collectionVehicle:data['Collection_Vehicle'],
        destination:data['destination'],
        origin:data['origin'],
        activityDateTime:data['activity_date_time'],
        documentpreview:data['document'],
        
        facilityLocation:newFacLoc,
        actorLocation:newActorLoc,
        description:data['description']

      });
      

      
    }
    else{
        
        var newActorLoc = '';
        var newFacLoc = '';
        setLicenceNo('')
    setLicenceAwardedfrom('')
    setLicenceAwardedTo('')
        console.log("props.cvTreeData",props.cvTreeData)
        
        props.flatArea?.forEach(item=>{
  
        
          if(item?.area_code==props.actorProfileData?.location){
            var obj = {areaName: item.name, areaCode: item.area_code}
            newActorLoc =  obj;
          }
  
       
  
        })

        console.log("props.actorsTreeData",props.actorsTreeData)
        setAreaCheckedList([])
        setAreaChecked([])
      setState({
        selectedArea :   [],  
        activityId:"",
        actorType:"",
        facilityType:"",
        facilityName:"",
        wasteType:"",
        activityType: props.actorsTreeData?.length==1? props.actorsTreeData[0] : "",
        quantity:"",
        qty_unit : "Kg",
        collectionVehicle:"",
        destination : props.actorProfileData?.destination!=null? props.actorProfileData?.destination : "",
        origin : props.actorProfileData?.origin!=null? props.actorProfileData?.origin : "",
        activityDateTime:dayjs(),
        documentpreview:"",
        
        facilityLocation:"",
        actorLocation:newActorLoc,
        description:""

      });
      
        return false;
    }
    
},[props])



const setLevelData = (flat) => {
  var roots = [];

  var all = {};

  flat.forEach(function (item) {

      all[item.value] = item;
  });



  Object.keys(all).forEach(function (value) {
      var item = all[value];
      if (item.parent === null || item.parent === "") {

          roots.push(item);
      } else if (item.parent in all) {
          var p = all[item.parent];
          if (!("children" in p)) {

              p.children = [];

          }
          p.children.push(item);
      }
  });


  return roots;
};


  const getEditData =(data) =>{

  
    if(data!=null){

      console.log("data",data)

    }
}

  let path="";
  const getBase64 = async(file) => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      
      let reader = new FileReader();

      
      reader.readAsDataURL(file);

     
        
        
        
        
      
      reader.onload = () => {
        
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
   
      console.log(fileInfo);
    });
  };

  const getImage = async (event)=> {

    // setFile(event.target.files[0]);
    
    console.log("satte--",event.target.files[0])
    let bfile = "";
    let files = event.target.files[0];
    


    let file = files;
    
    
    
    let filename=files.name;

    
    
    if (files && file) {
      
      
 
     await getBase64(file)
      .then(result => {
        file["base64"] = result;
        let b = file.base64;
        console.log("File Is", file.base64);
        
        bfile=b.split(",").pop()

        
      })

    
      console.log("bfile"+bfile)
      
      
    
    path = (filename + ';' + bfile);
    console.log("path--"+path)
    setState({...state,document : path})
    

    }
  };

  const getAllData = (child) => {

    
    child?.forEach(node => {
  
      matcheddata.push(node?.value);
      getAllData(node?.children);

    })

};
  
const getAreaList = (e,node) =>{
  
  var filterData = [];
  console.log("node",node)
  let status = e?.target?.checked;
  if(node?.children?.length>0){

      matcheddata.push(node?.value)
      getAllData(node?.children);
  }else{
    matcheddata.push(node?.value)
  }
  
  


  if(areaCheckedList!=''){
    areaCheckedList?.forEach(element => {
      areaCheckedvalue?.push(element)
    });
    
  }
  console.log("areaCheckedListmatch",matcheddata)
  
  if(status){

    matcheddata?.forEach(elem => {
      areaCheckedvalue?.push(elem)
    });
    filterData = areaCheckedvalue;
    
  }else{

    matcheddata?.forEach(elem => {
      console.log("areaCheckedListelem",elem)
      // areaCheckedvalue?.remove(elem)
      // areaCheckedvalue.filter(abc => {
      //     abc!=elem
      // })
      filterData = areaCheckedvalue.filter(function(e) { return e !== elem })
    });
  }
  console.log("areaCheckedListareaCheckedvalue",filterData)

  props.areaData?.forEach((item) => {
    if(filterData?.includes(item?.area_code)){
      var obj = {
        value: item?.area_code,
        label: item?.name
      }
      matchedAreaData?.push(obj)
    }
    
  })



  setAreaCheckedList(filterData)
  console.log("areaCheckedList",filterData,areaCheckedList)
  
  setAreaChecked(filterData)
    setState({...state,selectedArea : matchedAreaData})
}

  const getWasteType = (e) => {
   

    console.log('asas', e)


    var val = e?.value;

    props.wtTreeData?.map(item => {
        if (val == item) {
            setState({ ...state, wasteType: val, WTChecked: [val]});
            // setAreaChecked([val])
        }


    })




}
  

const renderTree = (nodes, name) => (

    nodes.map((item) => {
        return (<>

            <TreeItem 
                className={item.areaSubData?.length > 0? 'has-child':'no-child'}
                key={item.area_code} nodeId={item._id} label={item.name}
                onClick={() => { setState({...state, [name]: {areaCode: item.area_code, areaName: item.name } }) }} >

                {Array.isArray(item.areaSubData) && item.areaSubData != null
                    ? renderTree(item.areaSubData, name)
                    : null}
            </TreeItem>

        </>);


    })

  )


  const handleChange = (e) => {
    setState({...state, [e.target.name]: e.target.value});
  }

  // var newFacName = [];
  const [facName, setFacName] = useState([]);
  

  const changeLicenceData = (e) => {
    setLicenceNo('')
    setLicenceAwardedfrom('')
    setLicenceAwardedTo('')
    props.licenceData?.forEach(element => {
      if(element?.actor_type===e){
            // console.log("date",moment(element?.licence_awarded_from).utc().format('MM/DD/YYYY'))
            
            setLicenceNo(element.licence_no)
            setLicenceAwardedfrom(moment(element?.licence_awarded_from).utc().format('MM/DD/YYYY'))
            setLicenceAwardedTo(moment(element?.licence_awarded_to).utc().format('MM/DD/YYYY'))
      }
    });
  
  }

  const changeFacilityName = (e, loc) => {
    let newFacName = [];
    console.log("dataeeeeeeeeee",e)
    console.log("locccccc",loc)
    console.log("data",props.filterTypeData)

    var newActorLoc = '';
    
    
    props.filterTypeData?.forEach(item=>{

    
      loc?.forEach(element => {

        if(item?.facility_type==e && item?.location==element.value){

          newFacName.push(item?.facility_name)
         
        }
        
      });
     

    })
    //  setState({...state, facilityTypeName : newFacName})
    console.log("filter-----",newFacName)
    setFacName(newFacName);
    // setState({...state, [e.target.name]: e.target.value});
  }




  const cancelModal = (event) => {
    event.preventDefault();
    props.closeModal(event.target);
  }
  return (
    <>
      <div className="area-form">
        <ValidatorForm className="main_react_form w-100" ref={form} onSubmit={(e)=> handleSubmit(e)} id="addActivityForm">
            <div className="gallery-collections mt-0">
  

        <div className="row align-items-center mb-30 ">
                <div className="col-md-3"> 
                  <label className="form_lable">Activity Date</label>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group mb-0 inputform_style datePickerActivity">
                    <div className="mat-form-field-wrapper">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        renderInput={(props) => <TextField {...props} />}
                        label=""
                        maxDate={moment().toDate()}
                        value={state.activityDateTime}
                        onChange={(newValue) => {
                          setState({...state, activityDateTime: newValue});
                        }}
                      />
                    </LocalizationProvider>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="row align-items-center mb-30">
                <div className="col-md-3">
                  <label className="form_lable">Actor Type</label>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group mb-0 inputform_style fp_dropdown">
                    <div className="mat-form-field-wrapper">
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          {state.actorType}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {
                            props.actorsTreeData?.map((ele) => {
                              return <Dropdown.Item onClick={(e)=>{setState({...state, actorType: ele})}}>{ele}</Dropdown.Item>
                            })
                          }
                        </Dropdown.Menu>
                      </Dropdown>
                      <div  className="inPuthidden">
                      <TextValidator
                                  className=" w-100"
                                  label=""
                                    type="hidden"
                                  value={state.actorType}
                                  
                                                                    
                                  validators={['required']}
                                  errorMessages={['Please select an actor type!']} 
                              />
                        </div>
                    </div>
                  </div>
                </div>
              </div> */}


              

              <div className="row align-items-center mb-30">
                <div className="col-md-3">
                  <label className="form_lable">Activity Type</label>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group mb-0 inputform_style fp_dropdown">
                    <div className="mat-form-field-wrapper">
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          {state.activityType}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                        {
                            props.actorsTreeData?.map((ele) => {
                              return <Dropdown.Item onClick={(e)=>{setState({...state, activityType: ele}); changeLicenceData(ele)  }}>{ele}</Dropdown.Item>
                            })
                          }
                        </Dropdown.Menu>
                      </Dropdown>
                      <div  className="inPuthidden">
                      <TextValidator
                                  className=" w-100"
                                  label=""
                                    type="hidden"
                                  value={state.activityType}
                                  
                                                                    
                                  validators={['required']}
                                  errorMessages={['Please select an activity type!']} 
                              />
                        </div>


                    </div>
                  </div>
                </div>
              </div>


              <div className="row align-items-center mb-30">
                <div className="col-md-3 popup_input">
                  <span className="mat-form-field-label-wrapper">
                    <label className="mat-form-field-label">License No.</label>
                  </span>
                </div>

                <div className="col-md-6">
                  <div className="form-group mb-0 inputform_style">
                    <div className="mat-form-field-wrapper">
                      <TextField className="w-100" name="license" variant="outlined"                      
                        value={licenceNoState} 
                        disabled
                        onChange={handleChange}
                       />
                 
                    </div>
                  </div>
                </div>
              </div>  


              <div className="row align-items-center mb-30">
                <div className="col-md-3">
                    <span className="mat-form-field-label-wrapper">
                        <label>License Awarded</label>
                    </span>
                </div>
                <div className="col-md-6">
                    <div className="form-group mb-0 inputform_style datePicker">
                        <div className="mat-form-field-wrapper">
                            <ul className="rs_formlist">
                                <li>
                                    <span className="date_formto">From</span>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Stack spacing={1}>
                                            <DesktopDatePicker
                                                inputFormat="MM/DD/YYYY"
                                                name="date_awarded_from"
                                                disabled
                                                value={licenceAwardedFrom}
                                                // onChange={(e) => { setState({ ...state, date_awarded_from: String(e.$d) }) }}
                                                renderInput={(params) => <TextField {...params}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        placeholder: "DD/MM/YYYY"
                                                    }}

                                                />}

                                            />
                                        </Stack>
                                    </LocalizationProvider>
                                </li>
                                <li>
                                    <span className="date_formto">To</span>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Stack spacing={1}>
                                            <DesktopDatePicker
                                                inputFormat="MM/DD/YYYY"
                                                // value={Date}
                                                disabled
                                                value={licenceAwardedTo}
                                                // maxDate={moment().toDate()}
                                                // onChange={handleDateValue}
                                                // onChange={(e) => { setState({ ...state, date_awarded_to: String(e.$d) }) }}
                                                // onChange={(e) => { setState({...state, date_awarded_to:e.value }) }} 
                                                renderInput={(params) => <TextField {...params}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        placeholder: "DD/MM/YYYY"

                                                    }}

                                                />}

                                            />
                                        </Stack>
                                    </LocalizationProvider>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
              </div>            

                      {state.activityType=="Generator" &&

              <div className="row align-items-center mb-30">
                <div className="col-md-3">
                  <label className="form_lable">Actor Location</label>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group mb-0 inputform_style ifs_dropdown">
                    <div className="mat-form-field-wrapper">
          
                      <Dropdown>
                          <Dropdown.Toggle variant="white" id="dropdown-basic">
                              <TextValidator
                                  className=" w-100"
                                  label=""
                                  name="actorLocation"
                                  // value={state.actorLocation!=null?state.actorLocation?.areaName: ''}
                                  value={state.actorLocation?state.actorLocation.areaName:''}
                                  // value={state.actorLocation?state.actorLocation.areaName:''}
                                                                    
                                  validators={['required']}
                                  errorMessages={['Please select an location of actor!']} 
                              />

                          </Dropdown.Toggle>

                          {/* <Dropdown.Menu>
                              <TreeView

                                  aria-label="file system navigator"
                                  defaultCollapseIcon={<ExpandMoreIcon />}
                                  defaultExpandIcon={<ChevronRightIcon />}

                                  sx={{ flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                              >
                                  {renderTree(props.areaTree, "actorLocation")}

                              </TreeView>
                          </Dropdown.Menu> */}
                      </Dropdown>
                   
                    </div>
                  </div>
                </div>
              </div>

                }

                {state.activityType!="Generator" && state.activityType!="Visitor/researcher" && 

                <>

  {/* <div className="row align-items-center mb-30">
                <div className="col-md-3">
                  <label className="form_lable">Facility Location</label>
                </div>
                <div className="col-lg-6 col-sm-12">
                <div className="form-group mb-0 inputform_style ifs_dropdown multiselectArea checkboxRemove">
                    <div className="mat-form-field-wrapper">
                      

                                                <Dropdown>
                                                   <Dropdown.Toggle variant="white" id="dropdown-basic">
                                                   {state.selectedArea?.length > 0 && state.selectedArea.slice(0, 2).map((d, i) => { console.log('dash', d); return (<><span className="badge">{d.name} </span></>) })}
                                                   {state.selectedArea?.length > 2 ? <><span>{state.selectedArea?.length - 1}+</span></> : ''}
                                                   </Dropdown.Toggle>
                                                   <Dropdown.Menu className="treeviewCheckbox">
                                                       <CheckboxTree
                                                           nodes={areaTreeList}
                                                           checked={areaChecked}
                                                           expanded={WTExpandareaExpand}
                                                           onCheck={(e) => getAreaList(e)}
                                                           onExpand={(expanded) => setAreaExpanded(expanded)}
                                                       />
                                                   </Dropdown.Menu>
                                               </Dropdown>
                 
                    </div>
                  </div>



                </div>
              </div> */}


              <div className="row align-items-center mb-30">
                                <div className="col-md-3">
                                    <span className="mat-form-field-label-wrapper">
                                        <label>Facility Location</label>
                                    </span>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-0 inputform_style ifs_dropdown hdt_dropdown deDropdown">

                                        <div className="mat-form-field-wrapper">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="white" id="dropdown-basic">
                                                {state.selectedArea?.length > 0 && state.selectedArea.slice(0, 2).map((d, i) => { console.log('dash', d); return (<><span className="badge">{d.label} </span></>) })}
                                                   {state.selectedArea?.length > 2 ? <><span>{state.selectedArea?.length - 2}+</span></> : ''}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="treeviewCheckbox">
                                                  {console.log("arealist",areaTreeList)}
                                                    <ul id="myUL1">
                                                        {renderTestTree(areaTreeList)}
                                                    </ul>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>

<div className="row align-items-center mb-30">
                <div className="col-md-3">
                  <label className="form_lable">Facility Type</label>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group mb-0 inputform_style fp_dropdown">
                    <div className="mat-form-field-wrapper">
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          {state.facilityType}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {
                            props.ftTreeData?.map((ele) => {
                              return <Dropdown.Item onClick={(e)=>{setState({...state, facilityType: ele}); changeFacilityName(ele, state.selectedArea)}} >{ele}</Dropdown.Item>
                            })
                          }
                        </Dropdown.Menu>
                      </Dropdown>
                      <div  className="inPuthidden">
                      <TextValidator
                                  className=" w-100"
                                  label=""
                                    type="hidden"
                                    value={state.facilityType}
                                  
                                                                    
                                  validators={['required']}
                                  errorMessages={['Please select an facility type!']} 
                              />
                        </div>
                    </div>
                  </div>
                </div>
              </div>



              

              

              <div className="row align-items-center mb-30">
                <div className="col-md-3">
                  <label className="form_lable">Facility Name</label>
                </div>
                <div className="col-lg-6 col-sm-12">
                <div className="form-group mb-0 inputform_style fp_dropdown">
                    <div className="mat-form-field-wrapper">
                    
                  
                        <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          {state.facilityName}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {
                            facName?.map((ele) => {
                              console.log("ele-------",ele)
                              return <Dropdown.Item onClick={(e)=>{setState({...state, facilityName: ele})}}>{ele}</Dropdown.Item>
                            })
                          }
                        </Dropdown.Menu>
                      </Dropdown>
                      <div  className="inPuthidden">
                      <TextValidator
                                  className=" w-100"
                                  label=""
                                    type="hidden"
                                    value={state.facilityName}
                                  
                                                                    
                                  validators={['required']}
                                  errorMessages={['Please select an facility name!']} 
                              />
                        </div>
                    </div>
                  </div>
                </div>
              </div>

              

              
              </>
              }

              {state.activityType=="Collector" && 
              <div className="row align-items-center mb-30">
                <div className="col-md-3">
                  <label className="form_lable">Collection Vehicle</label>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group mb-0 inputform_style fp_dropdown">
                    <div className="mat-form-field-wrapper">
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          {state.collectionVehicle}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {
                            props.cvTreeData?.map((ele) => {
                              if(ele!=0){
                                return <Dropdown.Item onClick={(e)=>{setState({...state, collectionVehicle: ele})}}>{ele}</Dropdown.Item>
                              }
                             
                            })
                          }
                        </Dropdown.Menu>
                      </Dropdown>
                      <div  className="inPuthidden">
                      <TextValidator
                                  className=" w-100"
                                  label=""
                                    type="hidden"
                                    value={state.collectionVehicle}
                                  
                                                                    
                                  validators={['required']}
                                  errorMessages={['Please select an collection vehicle!']} 
                              />
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              }

              { (state.activityType=="Collector"  || state.activityType=="Recycler/reclaimer" ) && 
              <div className="row align-items-center mb-30">
              <div className="col-md-3">
                <label className="form_lable">Source of Waste</label>
              </div>
              <div className="col-lg-6 col-sm-6">
              <div className="form-group mb-0 inputform_style profileType">
                                <div className="mat-form-field-wrapper pt_dropDown">
                                    
                {/* <div className="form-group mb-0 inputform_style ifs_dropdown hd_tracker hdt_dropdown"> */}
                        


                                  <Select
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            value={sourceTypedatalist}
                                            onChange={multiSourceChange}
                                          
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}
                                          >
                                            {props.sourceType.map((name) => (
                                              <MenuItem key={name.value} value={name.value}>
                                                <Checkbox checked={sourceTypedatalist.indexOf(name) > -1} />
                                                <ListItemText primary={name.value} />
                                              </MenuItem>
                                            ))}
                                          </Select>
                                          <div  className="inPuthidden">
                      <TextValidator
                                  className=" w-100"
                                  label=""
                                    type="hidden"
                                    value={sourceTypedatalist}
                                  
                                                                    
                                  validators={['required']}
                                  errorMessages={['Please select an source of waste!']} 
                              />
                        </div>




                                          </div>
                </div>
              </div>
            </div>
              }




                          {state.activityType=="Exporter" && 
              <div className="row align-items-center mb-30">
                <div className="col-md-3">
                  <label className="form_lable">Destination</label>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group mb-0 inputform_style fp_dropdown">
                    <div className="mat-form-field-wrapper">
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          {state.destination}
                          {console.log("state--------",state.destination)}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                        {
                            constants.countryList?.map((ele) => {
                              return <Dropdown.Item onClick={(e)=>{setState({...state, destination: ele})}}>{ele}</Dropdown.Item>
                            })
                        }
                        </Dropdown.Menu>
                      </Dropdown>
                      <div  className="inPuthidden">
                      <TextValidator
                                  className=" w-100"
                                  label=""
                                    type="hidden"
                                    value={state.destination}
                                  
                                                                    
                                  validators={['required']}
                                  errorMessages={['Please select an destination!']} 
                              />
                        </div>
                    </div>
                  </div>
                </div>
              </div>

                      }

                      {state.activityType=="Importer" &&

              <div className="row align-items-center mb-30">
                <div className="col-md-3">
                  <label className="form_lable">Origin</label>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group mb-0 inputform_style fp_dropdown">
                    <div className="mat-form-field-wrapper">
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          {state.origin}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                        {
                            constants.countryList?.map((ele) => {
                              return <Dropdown.Item onClick={(e)=>{setState({...state, origin: ele})}}>{ele}</Dropdown.Item>
                            })
                        }
                        </Dropdown.Menu>
                      </Dropdown>
                      <div  className="inPuthidden">
                      <TextValidator
                                  className=" w-100"
                                  label=""
                                    type="hidden"
                                    value={state.origin}
                                  
                                                                    
                                  validators={['required']}
                                  errorMessages={['Please select an origin!']} 
                              />
                        </div>
                    </div>
                  </div>
                </div>
              </div>

                      }

              <div className="row align-items-center mb-30">
                <div className="col-md-3">
                  <label className="form_lable">Waste Type</label>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group mb-0 inputform_style ifs_dropdown hd_tracker hdt_dropdown">
                          
                    <div className="mat-form-field-wrapper">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="white" id="dropdown-basic">
                                                {state.wasteType}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="treeviewCheckbox">
                                                    <CheckboxTree
                                                        nodes={wasteList}
                                                        checked={state.WTChecked}
                                                        expanded={state.WTExpand}
                                                        onClick={(e) => getWasteType(e)}
                                                        onExpand={(expanded) => setState({...state, WTExpand : expanded})}
                                                    />
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="inPuthidden">    
                                        <TextValidator
                                          className=" w-100"
                                          label=""
                                            type="hidden"
                                            value={state.wasteType}
                                          
                                                                            
                                          validators={['required']}
                                          errorMessages={['Please select an waste type!']} 
                                         />
                                        </div>





                  </div>
                </div>
              </div>
              


              <div className="row align-items-center mb-30">
                <div className="col-md-3">
                  <label className="form_lable">Quantity{/* <span className="quantityTonnes">(Tonne/s)</span> */}</label>
                </div>
                <div className="col-lg-3 col-sm-12">
                  <div className="form-group mb-0 inputform_style">
                    <div className="mat-form-field-wrapper">
                      <TextField className="w-100" type="number" name="quantity"  InputProps={{ inputProps: { min: 1 , step:0.1} }} value={state.quantity} onChange={handleChange} variant="outlined" />
                      <div  className="inPuthidden">
                      <TextValidator
                                  className=" w-100"
                                  label=""
                                    type="hidden"
                                  value={state.quantity}
                                  
                                                                    
                                  validators={['required']}
                                  errorMessages={['Please select quantity!']} 
                              />
                        </div>
                    </div>
                  </div>
                </div>

                {/* <div className="col-md-3">
                  <label className="form_lable">Unit</label>
                </div> */}
                <div className="col-lg-3 col-sm-12 align-self-start">
                  <div className="form-group mb-0 inputform_style fp_dropdown">
                    <div className="mat-form-field-wrapper">
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          {state.qty_unit}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={(e)=>{setState({...state, qty_unit: 'Tonne/s'})}}>Tonne/s</Dropdown.Item>
                          <Dropdown.Item onClick={(e)=>{setState({...state, qty_unit: 'Kg'})}}>Kg</Dropdown.Item>
                          <Dropdown.Item onClick={(e)=>{setState({...state, qty_unit: 'Cubic Metres'})}}>Cubic Metres</Dropdown.Item>
                          <Dropdown.Item onClick={(e)=>{setState({...state, qty_unit: 'Litres'})}}>Litres</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <div  className="inPuthidden">
                      <TextValidator
                                  className=" w-100"
                                  label=""
                                    type="hidden"
                                    value={state.qty_unit}
                                  
                                                                    
                                  validators={['required']}
                                  errorMessages={['Please select an unit!']} 
                              />
                        </div>
                    </div>
                  </div>
                </div>

              </div>

           
              <div className="row align-items-center mb-30">
                <div className="col-md-3 popup_input">
                  <span className="mat-form-field-label-wrapper">
                    <label className="mat-form-field-label">Description</label>
                  </span>
                </div>

                <div className="col-md-6">
                  <div className="form-group mb-0 inputform_style">
                    <div className="mat-form-field-wrapper">
                      <TextField className="w-100" multiline
                        rows={4} name="description" variant="outlined"
                      
                      value={state.description} 
                      onChange={handleChange}
                       />
                 
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center mb-30">
                <div className="col-md-3 popup_input">
                  <span className="mat-form-field-label-wrapper">
                    <label className="mat-form-field-label">Image</label>
                  </span>
                </div>
                
                <div className="col-md-6">
                  <div className="form-group mb-0 inputform_style">
                    <div className="mat-form-field-wrapper">
                      <input name="image" type="file" class="form-control " onChange={getImage} id="image" accept="image/*" />
                      {/* <input className="w-100 form-control" type="file" name="image"  id="image"  accept="image/*" /> */}
                      <p className="mt-2 mb-0" name="imgbak" id="imgbak" value={state.documentpreview}><b>{state.documentpreview}</b></p>
                    </div>
                  </div>
                </div>
              </div>



            </div>


            <div className="gallery-sidebar-button mt-0 text-center new_gallery-sidebar-button">
              <div className="d-flex align-items-center justify-content-center">
                <Button variant="contained" onClick={cancelModal} className="cancelbtn">Cancel</Button>
                <Button variant="contained" type="submit" className="addbtn">{props.isEdit? 'Update': 'Add' }</Button>
              </div>
            </div>
        </ValidatorForm>
      </div>





    </>

  );
}

export default AddActivityModal;
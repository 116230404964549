import React, { Component, useState } from 'react';
import Dropdown from "react-bootstrap/Dropdown";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { Link } from '@material-ui/core';
import ReactEcharts from 'echarts-for-react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, TwitterIcon, FacebookIcon, LinkedinIcon } from 'react-share';
import Footer from '../../layout/footer';
import Header from '../../layout/header';
import Counter from '../../../Common/components/counter';
import MetaDataModal from '../../../Common/components/modal/metadata.modal';

var dataanalyticsTemplate = function () {

  return (
    <>
      <Header />
      <section className='da_column'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12 p-0'>
              <ul className='dac_lists'>
                {
                  this.state.indicatorData.map((item) => {
                    return (
                      <>

                        <li>
                          {/* <div className='col'> */}
                          <div className='dac_box'>
                            <div className='dacb_top'>
                              <div className='dacp_box'>
                                <div className='d-flex dacb_head'>
                                  {/* <Tooltip title={item.metaData} placement="top"> */}
                                    {/* <span><img src='assets/lib/images/home/indicator.svg' className='cursor-pointer' /></span> */}
                                  {/* </Tooltip> */}
                                  <MetaDataModal
                                      sector={item.sector} 
                                      iuId={item.iu_id} 
                                      metaData={item.metaData} 
                                      indicator={item.name} 
                                      unit={item.unit}
                                      type={'keyfact'}
                                    />
                                  <p>{item.name} <span>({item.unit})</span></p>
                                </div>
                                <div className='d-flex dacb_year'>
                                  <ul className='mb-0 d-flex align-items-center'>
                                    <li>
                                      {item.period}
                                      {/* <Dropdown>
                                        <Dropdown.Toggle>{this.state.selectvalue}</Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          {this.items.map((item) => (
                                            <Dropdown.Item onClick={() => this.setSelectedItem(item)}>
                                              {item}
                                            </Dropdown.Item>
                                          ))}
                                        </Dropdown.Menu>
                                      </Dropdown> */}
                                    </li>
                                    <li>
                                      {item.subgroup}
                                      {/* <Dropdown>
                                        <Dropdown.Toggle>{this.state.timeperiodvalue}</Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          {this.timeperiod.map((timeperiod) => (
                                            <Dropdown.Item onClick={() => this.settimeperiod(timeperiod)}>
                                              {timeperiod}
                                            </Dropdown.Item>
                                          ))}
                                        </Dropdown.Menu>
                                      </Dropdown> */}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className='dacb_number'>
                                <Counter
                                  startAgain={this.state.isCounting} 
                                  start={0}
                                  end={item.value ? item.value : 0} 
                                  duration={4}
                                  formatfn={num => num.toLocaleString()} 
                                />
                              </div>
                            </div>
                            <div className='dacb_source'>
                              Source: {item.source}
                            </div>
                          </div>
                          {/* </div> */}
                        </li>

                      </>
                    );
                  })
                }
              </ul>
            </div>
          </div>
        </div>
      </section>
      {
        this.state.sectorIus.map((sector) => {
          return (
            <>
              <section className='environment_section'>
                <div className='container-fluid'>
                  <div className='row'>
                    <div className='col text-center'>
                      <div className='environment'>
                        {/* <span><img src='assets/lib/images/home/environment.svg' /></span> */}
                        <h2>
                          <span>Sector</span>
                          {sector.level_name}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className='environment_chart'>
                <div className='container-fluid'>
                  <div className='row'>
                    {
                      sector.iusList.map((iu) => {
                        return (
                          <div id={"div-" + sector.level_name + iu._id} className='col-md-6'>
                            <div className='ec_box'>
                              <div className='ec_head'>
                                <ul className='mb-0 flex-wrap'>
                                  <li style={{ 'display': 'none' }} className='ec_indicator hArea'>
                                    <p>{this.state.currentAreaName}</p>
                                  </li>
                                  <li className='d-flex ec_indicator pl-0'>
                                    <MetaDataModal
                                      sector={sector.level_name} 
                                      iuId={iu._id} 
                                      metaData={iu.metadata?.[0]} 
                                      indicator={iu.name} 
                                      unit={iu.unit}
                                      type={'chart'}
                                    />
                                    <p>{iu.name} <span>({iu.unit})</span></p>
                                  </li>
                                </ul>
                                <ul className='social_link mb-0 pr-0 position-relative'>
                                  {
                                    this.state.source[sector.level_name + iu._id] ? (
                                      <>
                                        <li class="labelBox" onClick={(e) => { this.labelChart(sector.level_name, iu._id); }}><Link to=''>
                                          <img id={'img' + sector.level_name + iu._id} src='assets/lib/images/home/label_on.svg' />
                                        </Link> </li>
                                        <li><Link to='' data-toggle="collapse" href={"#multiCollapseShare" + sector.level_name + iu._id}
                                          role="button" aria-expanded="false"
                                          aria-controls={"multiCollapseShare" + sector.level_name + iu._id}>
                                          <img src='assets/lib/images/home/share.svg' />
                                        </Link> </li>
                                        <li className='borderRight' onClick={(e) => { this.downloadChart(sector.level_name, iu._id, iu.name); }}><Link to=''><img src='assets/lib/images/home/download.svg' /></Link> </li>

                                        <li className='borderRight chartOption'>
                                          <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            <img src={this.state.chartType?.[sector.level_name + iu._id]?.path} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item onClick={() => this.onSelect(sector.level_name, iu._id, this.chartIcon['bar'], 'bar') }>
                                                <img src={this.chartIcon['bar']} />
                                              </Dropdown.Item>
                                              <Dropdown.Item  onClick={() => this.onSelect(sector.level_name, iu._id, this.chartIcon['line'], 'line') }>
                                                <img src={this.chartIcon['line']} />
                                              </Dropdown.Item>
                                              <Dropdown.Item  onClick={() => this.onSelect(sector.level_name, iu._id, this.chartIcon['pie'], 'pie') }>
                                                <img src={this.chartIcon['pie']} />
                                              </Dropdown.Item>
                                              {/* <Dropdown.Item >Table</Dropdown.Item>
                                              <Dropdown.Item >Treemap</Dropdown.Item> */}
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </li>
                                      </>) : (<></>)
                                  }

                                  <div className="collapse multi-collapse" id={"multiCollapseShare" + sector.level_name + iu._id}>
                                    <div className="card card-body">
                                      <ul>
                                        <li key={"fbShare" + sector.level_name + iu._id}>
                                          <FacebookShareButton
                                            className={"fb" + sector.level_name + iu._id}
                                            // Disable calling the dialog if we don't have a url yet.
                                            openShareDialogOnClick={this.state.url !== "none"}
                                            url={this.state.url}
                                            onClick={() => this.onClickHandler('fb', sector.level_name, iu._id)}
                                          >
                                            <FacebookIcon size={25} />
                                          </FacebookShareButton></li>
                                        <li key={"lnShare" + sector.level_name + iu._id}>
                                          <LinkedinShareButton
                                            className={"ln" + sector.level_name + iu._id}
                                            // Disable calling the dialog if we don't have a url yet.
                                            openShareDialogOnClick={this.state.url !== "none"}
                                            url={this.state.url}
                                            onClick={() => this.onClickHandler('ln', sector.level_name, iu._id)}
                                          >
                                            <LinkedinIcon size={25} />
                                          </LinkedinShareButton></li>
                                        <li key={"twShare" + sector.level_name + iu._id}>
                                          <TwitterShareButton
                                            className={"tw" + sector.level_name + iu._id}
                                            // Disable calling the dialog if we don't have a url yet.
                                            openShareDialogOnClick={this.state.url !== "none"}
                                            url={this.state.url}
                                            onClick={() => this.onClickHandler('tw', sector.level_name, iu._id)}
                                          >
                                            <TwitterIcon size={25} />
                                          </TwitterShareButton></li>
                                      </ul>
                                    </div>
                                  </div>
                                </ul>



                              </div>
                              <div className='da_dropdown'>
                                <ul>
                                  <li>
                                    {
                                      this.state.source[sector.level_name + iu._id] ? (
                                        <>
                                          <ReactMultiSelectCheckboxes
                                            options={this.state.timePeriods[sector.level_name + iu._id] ? this.state.timePeriods[sector.level_name + iu._id] : []}
                                            placeholderButtonLabel="Time Periods"
                                            getDropdownButtonLabel={this.getDropdownButtonLabel}
                                            value={this.state.selectedOptionsTP[sector.level_name + iu._id] ? this.state.selectedOptionsTP[sector.level_name + iu._id] : []}
                                            onChange={(v, e) => this.onChangeTP(v, e, sector.level_name, iu._id)}
                                          />
                                        </>) : (<></>)
                                    }
                                  </li>
                                  <li>
                                    {
                                      this.state.source[sector.level_name + iu._id] ? (
                                        <>
                                          <ReactMultiSelectCheckboxes

                                            options={this.state.subgroupsLabel[sector.level_name + iu._id] ? this.state.subgroupsLabel[sector.level_name + iu._id] : []}
                                            placeholderButtonLabel="Subgroup"
                                            getDropdownButtonLabel={this.getDropdownButtonLabel}
                                            value={this.state.selectedOptionsSG[sector.level_name + iu._id] ? this.state.selectedOptionsSG[sector.level_name + iu._id] : []}
                                            onChange={(v, e) => this.onChangeSG(v, e, sector.level_name, iu._id)}

                                          />
                                        </>) : (<></>)
                                    }
                                  </li>
                                </ul>
                              </div>
                              <div className='charts_section'>
                                <ReactEcharts
                                  ref={(e) => { this.chartRefs[sector.level_name + iu._id] = e; }}
                                  option={this.defOption}
                                  style={{ height: "100%", left: 0, top: 0, width: "100%" }}
                                  opts={{ renderer: "svg" }}
                                />
                              </div>
                              <div className='maps_sl w-100 d-flex align-items-end justify-content-between pb-3'>
                                {
                                  this.state.source[sector.level_name + iu._id] ? (
                                    <>
                                      <ul className='maps_source'>
                                        <li><Tooltip title={this.state.source[sector.level_name + iu._id]} placement="top"><Link to=''>Source <img src='assets/lib/images/home/hover_arrow.svg' /></Link></Tooltip></li>
                                      </ul>
                                      <p className='download_source' style={{ 'display': 'none', 'fontSize': '12px', 'color': '#666' }}>
                                        Source: {this.state.source[sector.level_name + iu._id]}
                                      </p>
                                    </>) : (<></>)
                                }
                              </div>
                            </div>
                            
                          </div>
                         
                
                        );

                      })
                    }

                  </div>
                </div>
              </section>

              
            </>
          );
        })
      }
      <div className={this.state.loader ? 'loader text-center ' : 'loader text-center hide'}>
        <img src="assets/images/Comp-2.gif" style={{ 'marginTop': '12%', 'width': '30%' }} />
      </div>

      <div className={this.state.modal ? 'overlay dblock' : 'overlay'} ></div>
      <div id="copy" className='col-md-6' style={{ 'display': 'none' }} >

      </div>
      <Footer />


    </>
  );


};


export default dataanalyticsTemplate;

import React, { useState } from 'react';
import Header from '../../layout/header';
import Footer from '../../layout/footer';

import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { resourceImagePath } from '../../../Config/app.constants';
import ReactReadMoreReadLess from "react-read-more-read-less";
import { PaginationItem } from '@mui/material';

var informationTemplate = function (props) {

    return (
      <>
        <Header />   
        <section className='pageBanner' style={{backgroundImage: "url(assets/lib/images/home/information_kiosk.jpg)"}}>
            <div className='custom_container h-100'>
                <div className='row align-items-end h-100'>
                    <div className='col-md-12'>
                        <h2>Information <br/><strong>Kiosk</strong></h2>
                    </div>
                </div>
            </div>   
        </section>    
        <section className='ik_section'>
            <div className='custom_container'>
                <div className='row'>
                    <div className='col-md-3'>
                        <div className='ik_categorylist'>
                            <h3>Category</h3>
                            <ul>
                                <li className={this.state.tab=='All'? 'active':''}><Link to='' onClick={(e)=>{this.tabDataChange('All')}}>All</Link></li>
                                <li className={this.state.tab=='URL'? 'active':''}><Link to='' onClick={(e)=>{this.tabDataChange('URL')}}>Links</Link></li>
                                <li className={this.state.tab=='Document'? 'active':''}><Link to='' onClick={(e)=>{this.tabDataChange('Document')}}>Documents</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-9'>
                        <div className='iks_search'>
                            <div className='searchbar'>
                                <div className='searchbar-input w-100'>
                                    <TextField id="search" onKeyUp={(e)=>this.filterData(e)} type="search" variant="outlined" placeholder="Search" />
                                </div>
                                <div className='search_sorting'>
                                    <ul>
                                        <li onClick={this.shortData}><img src={this.state.shortItem ? 'assets/lib/images/home/sorting_number_2.svg': 'assets/lib/images/home/sorting_number.svg' } /></li>
                                        <li onClick={this.shortAbc}><img src={this.state.shortAbc ? 'assets/lib/images/home/sorting_abc_2.svg': 'assets/lib/images/home/sorting_abc.svg'} /></li>
                                    </ul>
                                </div>
                            </div>
                        
                            <div className='iks_list'>

                            {this.state.resData.map((data, index)=>{

                                
               
                           return (
                            <>
                        
                            <div className='iks_listbox'>
                                <div className='iks_list_img'>
                                 {data.type!="URL"?  <div className="downloadIcon"><a href={data.type=="URL"? data.url : resourceImagePath+data.document} download >
                                        <img src="assets/lib/images/download2.svg"/></a></div>: ''}
                                    <img className='img-fluid' title={resourceImagePath+data.image} src={resourceImagePath+data.image} 
                                    onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src="assets/images/placeholder.png";
                                }}  />
                                </div>
                                <div className='iks_listbox_text'>
                                    <h4><a href={data.type=="URL"? data.url : resourceImagePath+data.document} target="_blank">{data.title}</a></h4>
                                    <div className='iks_hyperLink'><a href={data.type=="URL"? data.url : resourceImagePath+data.document} target="_blank">{data.type=="URL"? data.url : resourceImagePath+data.document} </a></div>
                                    <p>
                                    <ReactReadMoreReadLess
                                        charLimit={264}
                                        readMoreText={"Read more"}
                                        readLessText={"Read less"}
                                        readMoreClassName="read-more-less--more"
                                        readLessClassName="read-more-less--less"
                                    >
                                        {data.description}
                                    </ReactReadMoreReadLess>                                                    
                                    </p>
                                </div>
                            </div>
                            </>
                            );
                                                
                                   
                                
                                        }
                                 )}
                                                           
                            </div>
               
                            {this.state.pageLimit > 1  &&
                            <div className='iks_pagination'>
                            <Stack>
                            <Pagination count={this.state.pageLimit}   
                               page={this.state.pagerCount + 1}  
                              onChange={this.paginate} />
                            </Stack>
                        </div> 
                            
                            }
                                                    
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
      </>
    );


};


export default informationTemplate;

import { configureStore } from '@reduxjs/toolkit'
import loginReducer from './features/loginSlice'
import headerReducer from './features/usersName'


export const store = configureStore({
  reducer: {
    login: loginReducer,
    header: headerReducer
  },
})
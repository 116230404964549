import React, { Component } from 'react'
import './data-entry.css';
import * as jsx from './data-entry.module.jsx';
import * as constants from '../../../Config/app.constants';
import * as $ from 'jquery';
import { data } from 'jquery';
import AddAreaModal from '../../Modals/addArea.modal';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from 'sweetalert';
import AdminService from '../../../services/admin.service';
//import { Tooltip } from '@material-ui/core'
import Tooltip from '@mui/material/Tooltip';

class DataEntry extends Component {
    arr = ["dxgf"]

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            currStatus:2,
            filteredData: [],
            searchText: '',
            setScrollableModal:false,
            scrollableModal: false,
            // mainData: [],
            isEdit: false,
            editModeId: null,
            modal: false,
            loader: false,
            dataLimit: 10,
            pageNumber:10,
            dataStatus: 2,
            loginArea : [],
            indicatorData: [],
            rowIndData: [],
            subIndData: [],
            areaData: [],
            editEntry:[],
            bulkDelete :[],
            statusData:[
                {"label":"Pending", "value":2},
                {"label":"Approved", "value":1},
                {"label":"Disapproved", "value":3}
            ]

        }
        // this.globalState = stateUtilityInstance.default;



    }

    componentDidMount() {

       

        this.sendStatus(this.state.currStatus);
        this.getAllIndicators();
        this.getAllAreaData()
        // this.deleteEntry('639c0a1503a7e55931d5d4b3')
    
        // AdminService.checkToken();
    }


    render() {
        return jsx.default.bind(this)();
    }


    sendStatus = (e) => {

        this.setState({currStatus:e})
        this.getData(e);
    }


    // update data limit===================//



 






    // filter table and get data====================//

    onSearch = (val) => {
        this.setState({searchText: val});
        let d = this.state.data;
        let fd=null;
        if(val == '') {
            fd = d;
        } else {
            fd = d.filter(
                item => (item.iusData[0].name && item.iusData[0].name.toLowerCase().includes(val.toLowerCase())
                    || (item.areaData[0].name && item.areaData[0].name.toLowerCase().includes(val.toLowerCase())
                    )
                ));
                
        }
        this.setState({ filteredData: fd });
    }



    columns1 = [
        {
            name: 'Indicator | Unit | Subgroup',
            selector: (row, index) =>            
                <> 
                <Tooltip title={row.iusData[0].name+" | "+row.iusData[0].unit+" | "+row.iusData[0].subgroup.name} placement="top">
                    <p className='mb-0'>{row.iusData[0].name} 
                    <span className='text-gray px-1'>| {row.iusData[0].unit} </span>
                    <span className='text-gray'>| {row.iusData[0].subgroup.name}</span></p>
                </Tooltip>
                    </>,
            sortable: false,
            width: "400px"
        },
        {
            name: 'Area Id',
            selector: row => row.areaData[0].area_code,
            sortable: true,
            // width: "150px"
        },
        {
            name: 'Area',
            selector: row => row.areaData[0].name,
            sortable: true,
            // width: "120px"
        },
        {
            name: 'Time Period',
            selector: row => row.time_period.start_time_period,
            sortable: true,
            // width: "150px"
        },
        {
            name: 'Source',
            selector: row =>  <Tooltip title={row.source.publisher} placement="top">
            <p>{row.source.publisher}</p>
            </Tooltip>,
            sortable: true,
            // minWidth: "250px"
        },

    

        {
            name: 'Data Value',
            selector: row => row.new_value,
            sortable: true,
            // width: "150px"
        },
        {
            name: 'Status',
            selector: row => <> {row.nStatus == 2 && <span className='badge badge-warning'>Pending</span>}
             {row.nStatus == 1 && <span className='badge badge-success m-0'>Approved</span> }
             {row.nStatus == 3 && <><div className='dentry_tables'><span className='badge badge-danger'>Disapproved</span><span className='indicatorImg d-inline-flex'><img src='assets/lib/images/home/indicator.svg' onClick={(e)=>this.openViewModal(row.remark)} /></span></div></>} </>,
            // sortable: true,
            width: "150px"
        },

        // {
        //     name: 'CREATED AT',
        //     selector: row => row.areaData[0].createdAt,
        //     sortable: true,
        //     width: "200px"
        // },

        // {
        //     name: 'CREATED BY',
        //     selector: row => row.areaData[0].updatedAt,
        //     sortable: true,
        //     width: "200px"
        // },
     
    ];

    columns = [
        {
            name: 'Indicator | Unit | Subgroup',
            selector: (row, index) =>            
                <> 
                <Tooltip title={row.iusData[0].name+" | "+row.iusData[0].unit+" | "+row.iusData[0].subgroup.name} placement="top">
                    <p className='mb-0'>{row.iusData[0].name} 
                    <span className='text-gray px-1'>| {row.iusData[0].unit}</span> 
                    <span className='text-gray'>| {row.iusData[0].subgroup.name}</span></p>
                </Tooltip>
                    </>,
            sortable: false,
            width: "400px"
        },
        {
            name: 'Area Id',
            selector: row => row.areaData[0].area_code,
            sortable: true,
            // width: "150px"
        },
        {
            name: 'Area',
            selector: row => row.areaData[0].name,
            sortable: true,
            // width: "120px"
        },
        {
            name: 'Time Period',
            selector: row => row.time_period.start_time_period,
            sortable: true,
            // width: "150px"
        },
        {
            name: 'Source',
            selector: row =>  <Tooltip title={row.source.publisher} placement="top">
            <p>{row.source.publisher}</p>
            </Tooltip>,
            sortable: true,
            // minWidth: "250px"/
        },

    

        {
            name: 'Data Value',
            selector: row => row.new_value,
            sortable: true,
            // width: "150px"
        },
        {
            name: 'Status',
            selector: row => <> {row.nStatus == 2 && <span className='badge badge-warning'>Pending</span>}
             {row.nStatus == 1 && <span className='badge badge-success'>Approved</span> }
             {row.nStatus == 3 && <><div className='dentry_tables'><span className='badge badge-danger'>Disapproved</span><span className='indicatorImg d-inline-flex'><img src='assets/lib/images/home/indicator.svg' onClick={(e)=>this.openViewModal(row.remark)} /></span></div></>} </>,
            sortable: true,
            width: "150px"
        },

        // {
        //     name: 'CREATED AT',
        //     selector: row => row.areaData[0].createdAt,
        //     sortable: true,
        //     width: "200px"
        // },

        // {
        //     name: 'CREATED BY',
        //     selector: row => row.areaData[0].updatedAt,
        //     sortable: true,
        //     width: "200px"
        // },
     {
            name: 'Action',
            width: "200px",

            cell: row =>
         

                <>
                    {/* <span className="action_specific ng-star-inserted">

                    {row.remark? <Tooltip title={row.remark} placement="top" arrow><span className="remakIcon" style={{
                                'marginRight': '20px'}}><i className="fa fa-comment"></i></span></Tooltip> : ''  } 

                        <img onClick={(e)=>{this.editDataEntry(row._id)}} className="cursor-pointer modal-btn" data-target="#EditAreaModal" src="assets/lib/images/icon/edit_icon.svg"
                            style={{
                                'marginRight': '20px', 'touchAction': 'none', 'userSelect': 'none',
                                'WebkitTapHighlightColor': 'rgba(0, 0, 0, 0)'
                            }}
                            ng-reflect-message="Edit" aria-describedby="cdk-describedby-message-25"
                            cdk-describedby-host=""

                        />
            
                        <span class="deleteIcon text-success" onClick={(e)=>{this.deleteEntry(row._id)}}><i class="fa fa-trash"></i></span>
                    </span> */}

                    <div class="dropdown table-action">
                    <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                        Select
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                       
                        <a class="dropdown-item  text-bold" onClick={(e)=>{this.editDataEntry(row._id)}} data-target="#EditAreaModal" src="assets/lib/images/icon/edit_icon.svg"
                            style={{
                                'marginRight': '20px', 'touchAction': 'none', 'userSelect': 'none',
                                'WebkitTapHighlightColor': 'rgba(0, 0, 0, 0)'
                            }}
                            ng-reflect-message="Edit" aria-describedby="cdk-describedby-message-25"
                            cdk-describedby-host="">Edit</a>
                     
                        <a class="dropdown-item text-danger text-bold" onClick={(e)=>{this.deleteEntry(row._id)}} href="javascript:;">Delete</a>
                    </div>
                </div> 
                </>,
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        },
    ];

    columns2 = [
        {
            name: 'Indicator | Unit | Subgroup',
            selector: (row, index) =>            
                <> 
                <Tooltip title={row.iusData[0].name+" | "+row.iusData[0].unit+" | "+row.iusData[0].subgroup.name} placement="top">
                    <p className='mb-0'>{row.iusData[0].name} 
                    <span className='text-gray px-1'>| {row.iusData[0].unit}</span> 
                    <span className='text-gray'>| {row.iusData[0].subgroup.name}</span></p>
                </Tooltip>
                    </>,
            sortable: false,
            width: "400px"
        },
        {
            name: 'Area Id',
            selector: row => row.areaData[0].area_code,
            sortable: true,
            // width: "150px"
        },
        {
            name: 'Area',
            selector: row => row.areaData[0].name,
            sortable: true,
            // width: "120px"
        },
        {
            name: 'Time Period',
            selector: row => row.time_period.start_time_period,
            sortable: true,
            // width: "150px"
        },
        {
            name: 'Source',
            selector: row =>  <Tooltip title={row.source.publisher} placement="top">
            <p>{row.source.publisher}</p>
            </Tooltip>,
            sortable: true,
            // minWidth: "250px"
        },

    

        {
            name: 'Data Value',
            selector: row => row.new_value,
            sortable: true,
            // width: "150px"
        },
        {
            name: 'STATUS',
            selector: row => <> {row.nStatus == 2 && <span className='badge badge-warning'>Pending</span>}
             {row.nStatus == 1 && <span className='badge badge-success'>Approved</span>}
             {row.nStatus == 3 && <span className='badge badge-danger'>Disapproved</span>} </>,
            sortable: true,
            width: "100px"
        },
        {
            name: 'Remarks',
            selector: row => row.remark,
            sortable: true,
            width: "100px"
        },

        // {
        //     name: 'CREATED AT',
        //     selector: row => row.areaData[0].createdAt,
        //     sortable: true,
        //     width: "200px"
        // },

        // {
        //     name: 'CREATED BY',
        //     selector: row => row.areaData[0].updatedAt,
        //     sortable: true,
        //     width: "200px"
        // },
     {
            name: 'Action',
            width: "200px",

            cell: row =>
         

                <>
                    {/* <span className="action_specific ng-star-inserted">

                    {row.remark? <Tooltip title={row.remark} placement="top" arrow><span className="remakIcon" style={{
                                'marginRight': '20px'}}><i className="fa fa-comment"></i></span></Tooltip> : ''  } 

                        <img onClick={(e)=>{this.editDataEntry(row._id)}} className="cursor-pointer modal-btn" data-target="#EditAreaModal" src="assets/lib/images/icon/edit_icon.svg"
                            style={{
                                'marginRight': '20px', 'touchAction': 'none', 'userSelect': 'none',
                                'WebkitTapHighlightColor': 'rgba(0, 0, 0, 0)'
                            }}
                            ng-reflect-message="Edit" aria-describedby="cdk-describedby-message-25"
                            cdk-describedby-host=""

                        />
            
                        <span class="deleteIcon text-success" onClick={(e)=>{this.deleteEntry(row._id)}}><i class="fa fa-trash"></i></span>
                    </span> */}

                    <div class="dropdown table-action">
                    <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                        Select
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                       
                        <a class="dropdown-item  text-bold" onClick={(e)=>{this.editDataEntry(row._id)}} data-target="#EditAreaModal" src="assets/lib/images/icon/edit_icon.svg"
                            style={{
                                'marginRight': '20px', 'touchAction': 'none', 'userSelect': 'none',
                                'WebkitTapHighlightColor': 'rgba(0, 0, 0, 0)'
                            }}
                            ng-reflect-message="Edit" aria-describedby="cdk-describedby-message-25"
                            cdk-describedby-host="">Edit</a>
                     
                        <a class="dropdown-item text-danger text-bold" onClick={(e)=>{this.deleteEntry(row._id)}} href="javascript:;">Delete</a>
                    </div>
                </div> 
                </>,
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        },
    ];




    openViewModal = async (e) => {

        // if(this.state.scrollableModal==false){
        //     this.setState({scrollableModal:true})
        //     this.setState({remarkdata:e})
        // }
        // else{
            
        //     this.setState({scrollableModal:false})
        // }

        swal({
            title:"Reason of Disapproval",
            text: e,
            className: "rod_popup_test" ,
            buttons: true,
        })
      
    }

    editDataEntry = (id) => {


        const edData = [];
        this.state.filteredData.forEach((item)=>{

            if(item._id==id){
                edData.push(item)
               return;
            }

        });


        console.log("data--->" ,edData );
        this.setState({editEntry:edData})

        if(edData.length > 0){
            console.log('ss',edData[0].iu_id)
         
        }

        this.setState({ isEdit: true })
        this.openAddModal();

    }



    getData = async (status) => {

  
 
        this.setState({ loader: true, searchText: "" });
        const savedItem = JSON.parse(localStorage.getItem("userInfo"));
            console.log("saved--"+savedItem)
            let userid = savedItem.id;
        const dataToBeSend = {
            "size":10,
            "totalElements": 0,
            "totalPages": 0,
            "pageNumber": 0,
            "filterKeyWord": "",
            "userId": userid,
            "startOffset": 0,
            "endOffset": 0,
            "level": [
                status
            ]
        }

      
        AdminService.httpPost('/data-retrieval/data/get-data', dataToBeSend).then((res)=>{
   
                    if(res){
                        this.setState({loader:false})
                        let d = res.data
                      let t = res.totalElements;

                            if(t!=0){
                                this.setState({pageNumber:t})

                            
          
                                  const dataToBeSend1 = {
                                      "size":t,
                                      "totalElements": 0,
                                      "totalPages": 0,
                                      "pageNumber": 0,
                                      "filterKeyWord": "",
                                      "userId": userid,
                                      "startOffset": 0,
                                      "endOffset": 0,
                                      "level": [
                                          status
                                      ]
                                  }
          
                                  AdminService.httpPost('/data-retrieval/data/get-data', dataToBeSend1).then((res)=>{
             
                                      if(res){
                                          this.setState({loader:false})
                                          let d = res.data;
                                        //   d.forEach((ele  , index)=>{ele.index = index })
                                          this.setState({filteredData: d, data:d})
                                      }
                  
                          })
                            }else{
                                let d = res.data;
                                // d.forEach((ele  , index)=>{ele.index = index })
                                this.setState({filteredData: d, data:d})
                            }
                    }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });

    }

 

    // get all indicators===================//

    getAllIndicators = async () => {


        AdminService.httpGet('/data-retrieval/ius/get-ius-maping').then((res) => {
            if (res) {

                let d = res.data;
                console.log(d)

           
                this.setState({ indicatorData: d, rowIndData:d })


            }

        }).catch((err) => {

            console.log(err)
        });






    }


    // get sub indi cators ===================//

    // getSubIndicators = async (id) => {


    //                     const bindData = [];
    //                 this.state.rowIndData.forEach(item => {
                    
    //                     if(item._id==id){ 
        
    //                         bindData.push({ label: item.subgroup[0].name, value: item.subgroup[0]._id, name:item.unit })
     
    //                     }

                      

    //                 });

       
    //                 this.setState({ subIndData: bindData })
    //             }
           







    // get area data =====================//

    getAllAreaData = async () => {
   

            alert("hh")

        AdminService.httpGet('data-retrieval/area/getAreaData').then((res) => {
            
            if (res) {

                let d = res.data;
                
                

                let bindData = [];
                d.forEach(item => {

                    bindData.push({ label: item.name, value: item._id })
                    

                });
                
             
                


            }

        }).catch((err) => {

            console.log(err)
        });


    }


        // get area data==================================//


        getAllAreaData = async () => {
    
                 
        var locs = localStorage.getItem('userInfo');
        var mainDt = mainDt = JSON.parse(locs);
            await AdminService.httpGet('/data-retrieval/area/getAreaData').then((res) => {
    
                
                if (res) {
                    let d = res.data;
                    let allareacode=[];
                    

                    d.forEach(item => {

                        
                        allareacode.push(item.area_code)
    
                    });

                    if(mainDt.role==1){
                        this.setState({ areaData: d, loginArea: allareacode})
                    }else{
                        this.setState({ areaData: d, loginArea: mainDt.area})
                    }
       

                }

    
    
            }).catch((err) => {
                
                console.log('errrr',err)
            });
    
        }



        // save data===========================//

        saveData = async (e) => {

            if(e.id!=null){

                this.updateEntry(e)

            }
            else{
                this.addNewEntry(e)
            }

        }


        updateEntry = async(e) =>{

        
            this.setState({ loader: true })
            const dataToBe2= {

                "id": e.id,
                "area":e.area,
                "source": e.source,
                "time_period": e.time_period,
                "indicator": e.indicator,
                "iu_id": e.iu_id,
                "unit": e.unit,
                "ius_id": e.ius_id,
                "value": parseInt(e.value),
                "footnote": e.footnote?  e.footnote :null

          
               

            }
    
            console.log('dten', dataToBe2)
    
            AdminService.httpPut('/data-import/data/update-data/'+e.id, dataToBe2).then((res) => {
                if (res) {
                    this.setState({ loader: false })
                    let d = res.data;

                    console.log(res)

                    if(  res.status == 1){
                        toast.success("Data updated successfully!");
                        this.openAddModal();
                        this.getData(this.state.currStatus);
                        this.setState({ isEdit: false })
            
                        this.setState({ editEntry: [] })
                    }
                    else{
                        toast.error("Failed to update data");

                    }
               
                }
    
            }).catch((err) => {
                this.setState({ loader: false })
                console.log(err)
            });
    
    


        }

        // add new entry=============================///


        addNewEntry = async (e) =>{

            console.log('dten', e)
            this.setState({ loader: true })
            const dataToBeSend = {
                "id": null,
                "area": e.area,
                "source": e.source,
                "time_period": e.time_period,
                "indicator": e.indicator,
                "iu_id": e.iu_id,
                "unit": e.unit,
                "ius_id": e.ius_id,
                "value": parseInt(e.value),
                "footnote": e.footnote?  e.footnote :null
            }

 
 
    

            console.log('check',dataToBeSend)
    
            AdminService.httpPost('/data-import/data/add-data', dataToBeSend).then((res) => {
                if (res) {
                    this.setState({ loader: false })
                    let d = res.data;

                    console.log(res)

                    if(  res.status==1){
                        toast.success("Data added successfully!");
                        this.openAddModal();
                        this.getData(this.state.currStatus);
                    }
                    else{
                        this.openAddModal();
                        this.getData(this.state.currStatus);
                        this.setState({ loader: false })
        
                        toast.error('Failed to add data');
        
        
                    }
     
         
                 }
                 }).catch((err) => {
                     this.setState({ loader: false })
                     toast.error('Failed to add data');
         
                     console.log(err)
                 });
          }
    


        // delete entry===========//


  deleteEntry = (id) =>{
   
     console.log('this is delete check======',this.state.currStatus)
    swal({
        title:"Are you sure you want to delete?",
        text: "Once deleted, you will not be able to recover this data entry!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then((willDelete) => {
          if(willDelete) {
            const dataToBeSend = {
                "data": id
            }
        
            AdminService.httpPost('/data-retrieval/data/delete-data', dataToBeSend).then((res) => {

                if (res) {
                    this.setState({ loader: false })
                    let d = res;
        
                    console.log(res)
        
                    if(  res.status == 1){
                        toast.success("Data deleted successfully!");
                        this.getData(this.state.currStatus);
                    }
                    else{
                        toast.error("Failed to delete data");
        
                    }
               
                }
        
            }).catch((err) => {
                this.setState({ loader: false })
                console.log(err)
            });
          }                
        });     






  }


    // open add modal=====================//


    openAddModal = () => {

        if (this.state.modal == false) {

            this.setState({ modal: true })
           

        } else {
            this.setState({...this.state.editEntry, editEntry: []})
            this.setState({ modal: false })
            this.setState({ isEdit: false })
            this.setState({editModeId:null})
            
        }

    }

///multiple delete

handleChange = ({ selectedRows }) => {
  
    this.setState({bulkDelete:selectedRows})

  };
  
  

    handleChangeData = ({ toggleCleared }) => {
        this.setState({bulkDelete:toggleCleared})

  };
  




  

  //////////=========checkbox delete============/////////

  deleteBulkData = (e) =>{
    let ids = [];
    
    this.state.bulkDelete.forEach(item=>{
        ids.push(item._id)
        console.log("hhhhhhhh++++++++",ids)
    })
    
    if(ids?.length > 0){
                swal({
                    title:"Are you sure you want to delete?",
                    text: "Once deleted, you will not be able to recover this dataentry!",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    })
                    .then((willDelete) => {
                      if(willDelete) {
                        const data= {
                            "_id": ids.toString()
                        }
                        AdminService.httpPost('/data-import/data/delete_multiple_data', data).then((res) => {
                            if (res) {
                                this.setState({ loader: false })
                                // let d = res.data;
                                // console.log("sssssssssddddddddd====",res)
                                // this.setState({filteredData: d, data:d})
                                // this.getData(this.state.dataStatus);
                                // this.getData(2);
                    
                                if(res.success == 1){
                                    console.log("============sssssssssddddddddd====",res)
                                    toast.success("Data deleted successfully!");
                                    this.getData(2);
                                }
                                else{
                                    toast.error("Failed to delete data");
                    
                                }
                           
                            }
                    
                        }).catch((err) => {
                            this.setState({ loader: false })
                            console.log(err)
                        });
                      }                
                    });     
                }
        
                else{
                    swal({
                        title:"No Record Selected!",
                        text: "Please select atleast one record to delete.",
                          icon: "warning",
                          button: true,
                          
                        })
                
                        return false;
                
                
                }
            }




}







export default DataEntry;

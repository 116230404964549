import React, { useEffect, useRef, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import AdminService from '../../../services/admin.service';
import Stack from '@mui/material/Stack';
import Dropdown from 'react-bootstrap/Dropdown';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { useTreeItem } from '@mui/lab/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { isArray } from 'lodash';
import * as constants from '../../../Config/app.constants';

import CheckboxTree from 'react-checkbox-tree';
export default function Inittabs(props) {

    const [FormState, setFormState] = useState(
        {
            
            typeOfWaste:'',
            typeOfProfile: [],
            newWaste_types:[],
            actorType:[],
            actorList:[],
            wasteTypes: [],

    });
    const [mainTreeData, setMainTreeData] = useState([]);
    const [invalid, setInvalid] = useState('')
    const [invalid2, setInvalid2] = useState('')

    const [flatTree, setFlatTree] = useState([]);
    const [wasteTree, setWasteTeree] = useState([]);

    const [typeOfProfile, setTypeOfProfile] = useState([])


    const [checked, setChecked] = useState([]);
const [expanded, setExpanded] = useState([]);


    const handleMultiSelectChange = (event) => {
        console.log("sddssdsdsdsd", event.target.value);
        const {
            target: { value },
        } = event;

        setFormState({ ...FormState, actorType: value });
        setInvalid('');
    
    };




    useEffect(() => {


        let finalTypes = [];
        
         for (const [key, value] of Object.entries(constants.actortype)) {
        
        
            FormState.typeOfProfile?.forEach(item=>{
                console.log('finalTpes',item==key, item, key)
                if(item===key){
                 
                    finalTypes.push({value})
                }
            })
        
          }
        
          console.log('finalTpes',finalTypes)
        
        
        
                console.log("Mohit treeData", props.treeData);
                if (props.treeData != '' && props.treeData != null) {
        
                    let flatLevel = [];
        
        
                    props.treeData.forEach((item) => {
        
        
                           delete item.areaSubData;

                        var obj = {
                            value: item.value,
                            label: item.label,
                            parent: item.parent,
                            level:item.level
                            
                          }
        
                        flatLevel.push(item)
        
        
                    })
                    setFlatTree(flatLevel)
                    const data = setLevelData(flatLevel)
                    console.log('flats', data)
                    setMainTreeData(data);
                    
        
                }
        
        

        
        
        
                if (props.masterData != "" && props.masterData != null) {
        
                    let arrayData = [];
                    let arrayData2 = []
                    let arrayData3 = []
        
                    props.masterData['Actors']?.map(item => {
                        arrayData3.push(item.value)
        
                    })
        
        
                    props.masterData['Facility Type']?.map(item => {
                        arrayData.push(item.value)
        
                    })
        
                    props.masterData['Collection']?.map(item => {
                        arrayData2.push(item.value)
        
                    })
        
        
                    setFormState({ ...FormState, typeOfProfile: arrayData, colVehicle: arrayData2, actorList:arrayData3 })
        
                }
        
        
        
            }, [props.treeData, props.masterData]);
        


    const textChange = (event) => {
        setFormState({ ...FormState, typeOfWaste: event.target.value });


        setInvalid('');
        setInvalid2('')

    }



    const getWatsteData = (e) =>{
        const dataArr = e;
        var parentItem = '';
        var children = 0;
        let filnalData = [];
    
    //    flatTree?.forEach((item) => {
    
        
        
    //         e?.forEach(dt=>{
      
    //             if(item.value===dt){
    
    
    //                 parentItem = item.parent;
                   
                    
    
    //             }
    //         })
    //     })
    
      
        // dataArr.push(parentItem)
    
       if(e?.length > 0){
        setChecked(e)
        setFormState({...FormState, wasteTypes:e})
       }
       else{
        setChecked([])
        setFormState({...FormState, wasteTypes:[]})
       }
    
       
    
    }
    


    const profileType = [
        "Generator",
        "Collector",
        "Recycler",
        "Importer/Exporter",
        "Disposer",
        "Reclaimer"
    ]


    const goToProfile = () => {

       
        const id1 = localStorage.getItem('userInfo')
        const gData = JSON.parse(id1)
        const proData = [];
        if (isArray(FormState.actorType)) {
            FormState.actorType.forEach(item => {

                if (item != null) {

                    proData.push(item)
                }

            })
        }

        const data = {
            user_id: gData.id,
            profile_type:  FormState.actorType,
            waste_type: FormState.wasteTypes,
            date_of_registration:new Date()
        }

        console.log('datatest',data)

        if (FormState.actorType == null || FormState.actorType == '' && FormState.wasteTypes == '' || FormState.wasteTypes == null) {
            setInvalid('<p style="color:red">Please select profile types.</p>')
            setInvalid2('<p style="color:red">Please select a waste type.</p>')
            return;
        }
        else if (FormState.actorType == null || FormState.actorType == '' && FormState.wasteTypes != '') {
            setInvalid('<p style="color:red">Please select profile types.</p>')
            setInvalid2('')
            return;
        }
        else if (FormState.wasteTypes == null || FormState.wasteTypes == '' && FormState.actorType != '') {
            setInvalid2('<p style="color:red">Please select a waste type.</p>')
            setInvalid('');
            return;
        }
        else {



            props.step1Call(data, false)

            // console.log('adding',data);

            setInvalid('');
            setInvalid2('')
            props.startData(data)
        }


    }




    const setLevelData = (flat) => {
   

        var roots = []

        var all = {}

        flat.forEach(function (item) {
            all[item.label] = item
        })
        Object.keys(all).forEach(function (label) {
            var item = all[label]
            if (item.parent === null || item.parent === '') {
                roots.push(item)
            } else if (item.parent in all) {
                var p = all[item.parent]
                if (!('children' in p)) {
                    p.children = []
                }
                p.children.push(item)
            }
        })

        return roots
    }





    const renderTreeW = (nodes) => {

        return (
            <>
                {
                    nodes.map((item) => {

                        let classes = '';
                        let isMulti = '';
                        let isExist = null;
                        if (FormState.newWaste_types?.length > 0) {
                            isExist = FormState.newWaste_types?.filter(dt => {
                                return dt === item.label;
                            })
                        }

                        if (isExist?.[0] === item.label) {
                            isMulti = 'multi'
                        }

                        if (item.areaSubData?.length < 1) {
                            classes = 'no-child  ' + isMulti
                        }
                        else if (item.areaSubData?.length > 0) {
                            classes = 'has-child  ' + isMulti
                        }

                        return (

                            <TreeItem
                                className={classes}
                                key={item.label} nodeId={item.label} label={item.label}
                                onClick={() => { selectMultiW(item.label) }}
                            >

                                {item.areaSubData != null && Array.isArray(item.areaSubData) && item.areaSubData?.length > 0
                                    ? renderTreeW(item.areaSubData)
                                    : <></>}
                            </TreeItem>

                        );


                    })

                }
            </>
        )


    };

    const selectMultiW = (item) => {


        let arrayDT = FormState.wasteTypes;
        let index = -1
        //   arrayDT?.forEach((x,i)=>{
        //     if(x == item){index = i}
        //   })
        arrayDT?.map((x, i) => {
            if (x == item) { index = i }
            return x;
        })


        if (index != -1) {
            arrayDT?.splice(index, 1);
            setFormState({
                ...FormState,
                wasteTypes: arrayDT
            });

        }
        else {
            arrayDT.push(item);
            setFormState({
                ...FormState,
                wasteTypes: arrayDT
            });

        }

        setInvalid2('')
    }



 

    return (
        <>
            <div className='profileInit profileinit_page'>
                <div className='container h-100'>
                    <div className='profileinit_Section'>
                        <div className="row w-100">
                            <div className='col-md-12'>
                                <h4 className='profile-heading'>Welcome to Your Dashboard</h4>
                            </div>

                            <div className="col-lg-5">
                            <div className="form-group mb-0 inputform_style">
                                <div className="mat-form-field-wrapper pt_dropDown">
                                    <span>Profile Type</span>
                                    <Select
                                        id="profileType"
                                        name='profileType'
                                        multiple
                                        value={FormState.actorType}
                                        className='profilratee-select'
                                        onChange={(e) => { handleMultiSelectChange(e) }}
                                    >
                                        {FormState.actorList?.map((actorList) => (

                                            <MenuItem
                                                key={actorList}
                                                value={actorList}
                                                className="multiselectCheckbox"
                                            >
                                                {actorList}
                                            </MenuItem>

                                        )
                                        )}
                                    </Select>

                                    <div dangerouslySetInnerHTML={{ __html: invalid ? invalid : '' }}></div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-5">
                            <div className="form-group mb-0 inputform_style ifs_dropdown checkboxRemove">

                            <div className="mat-form-field-wrapper pt_dropDown">
                                               
                                               <span>Waste Type</span>
                                               <Dropdown  className="dropWaste">
                                                   <Dropdown.Toggle variant="white" id="dropdown-basic">
                                                   {FormState.wasteTypes?.length > 0 && FormState.wasteTypes.slice(0, 2).map((d, i) => { console.log('dash', d); return (<><span className="badge">{d} </span></>) })}
                                                   {FormState.wasteTypes?.length > 2 ? <><span>{FormState.wasteTypes?.length - 1}+</span></> : ''}
                                                   </Dropdown.Toggle>
                                                   <Dropdown.Menu className="treeviewCheckbox">
                                                       <CheckboxTree
                                                           nodes={mainTreeData}
                                                           checked={checked}
                                                           expanded={expanded}
                                                           onCheck={(e) => getWatsteData(e)}
                                                           onExpand={(expanded) => setExpanded(expanded)}
                                                       />
                                                   </Dropdown.Menu>
                                               </Dropdown>
                          

                                {/* <div className="mat-form-field-wrapper ">
                                    <span>Waste Type</span>
                                    <Dropdown className="dropWaste">
                                        <Dropdown.Toggle variant="white" id="dropdown-basic">
                                            {FormState.wasteTypes?.length > 0 && FormState.wasteTypes.slice(0, 3).map((d, i) => { console.log('dash', d); return (<><span className="badge">{d} </span></>) })}
                                            {FormState.wasteTypes?.length > 3 ? <><span>{FormState.wasteTypes?.length - 1}+</span></> : ''}
                                            {/* <TextField
                                                className=" w-100"
                                                label=""
                                                type='', hidden'
                                                onChange={textChange}
                                                name="newWaste_types"
                                                value={FormState.wasteTypes}
                                            /> */}

                                        {/* </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <TreeView
                                                aria-label="file system navigator"
                                                defaultCollapseIcon={<ExpandMoreIcon />}
                                                defaultExpandIcon={<ChevronRightIcon />}
                                                sx={{ flexGrow: 1, }}
                                            >
                                                {renderTreeW(mainTreeData)}
                                            </TreeView>
                                        </Dropdown.Menu>
                                    </Dropdown> */}
                                   {/* <div dangerouslySetInnerHTML={{ __html: invalid2 ? invalid2 : '' }}></div>
                                </div> */}
                            </div>
                        </div>
                        </div>
                            <div className='col-lg-2 d-flex flex-wrap'>
                                <button type='submit' className="goProfilBtn" onClick={goToProfile} > Go <img src="assets/lib/images/home/arrow_right.svg" /></button>
                            </div>
                        </div>

                        <div className='row justify-content-center'>
                            <div className='col-md-11'>
                                <img src="assets/lib/images/profile-dashboard.svg" className="img-fluid mt-100" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
import React, { Component } from 'react'
import './data-analytics.css';
import * as jsx from './data-analytics.module.jsx';
import * as constants from '../../../Config/app.constants';
import adminService from "../../../services/admin.service";
import eventBus from '../../../features/eventBus';
import "react-toastify/dist/ReactToastify.css";
import domtoimage from 'dom-to-image';
import { connect } from "react-redux";
import * as $ from 'jquery';

class DataanalyticsPage extends Component {

    constructor() {
        super()
        this.currentAreaCode = 'SWZ';
        // this.currentAreaName = 'Eswatini';
        this.colors = {
          0: '#0C6638',
          1: '#108348',
          2: '#0E9650',
          3: '#009F4E',
          4: '#10B260',
          5: '#12BD66',
          6: '#0AC163',
          7: '#30D07E',
          8: '#37E98E',
          9: '#65F6AC',
          10: '#9DFFCD',
          11: '#BEFFDE',
        };
  
        this.state = {
            value:0,
            loader:false,
            isCounting: 0,
            selectedItem:[],
            selectvalue:'2022',
            timeperiodvalue:'Total',
            defOptions: [],
            selectedOptions: [],
            indicatorData: [],
            sectorIus: [],
            sectorMap: {},
            areaList: [],
            ius_list: {},
            optionData: [],
            timePeriods: [],
            subgroups: [],
            subgroupsLabel: [],
            selectedOptionsTP: [],
            selectedOptionsSG: [],
            source: [],
            labelon: false,
            url: "none",
            sector: '',
            iuId: '',
            buttonType: '',
            chartType: [],
            currentAreaName: 'Eswatini',

        };

        this.chartRefs = [];
        this.index = [];
        this.chartLoaded = [];

        this.defOption = {
          title: {
            show: true,
            textStyle:{
              color:'#666',
              fontSize:14,
              fontFamily: "Mulish",
              fontWeight: "400"
            },
            text: "Loading...",
            left: 'center',
            top: 'center'
          },
          xAxis: {
              show: false
          },
          yAxis: {
              show: false
          },
          series: []
        };
        
        this.optionData = [];

        this.charts = ['bar', 'line', 'pie'];
        this.chartIcon = {
          'bar': 'assets/lib/images/charts/bar_chart.svg', 
          'line': 'assets/lib/images/charts/line.svg', 
          'pie': 'assets/lib/images/charts/pie.svg' 
        }
    }

    items = ["2022"];
    timeperiod = ["Total"];

    componentWillUnmount() {
      eventBus.remove("changeArea");
    }

    onSelect(sector, iuId, path, type) {
      let chartTypes = this.state.chartType;
      chartTypes[sector+iuId] = {'path':path, 'type': type};
      this.setState({chartType: chartTypes});
      this.getChartForSubgroup(sector, iuId, type);
    }

    componentDidMount() {
      this.getData();
      this.getkeyFact();

      eventBus.on("changeArea", (data)=> {        
        
        this.setState({loader: true, isCounting: this.state.isCounting+1, currentAreaName: data.name});
        this.currentAreaCode = data.message;
        $(window).scrollTop(0);
        this.initializeCharts();
        this.getkeyFact();
        this.setState({loader: false});
      });
      let that = this;

      $(window).on('scroll', ()=>{
        let height = $(window).height();
        let scrollY = $(window).scrollTop();
        let multiple = Math.floor(scrollY/height);
        if(multiple > 0) {
          let startInd = multiple*4 - 2;
           
          for(let i=startInd; i<=startInd+4; i++) {
            if(i in that.index && !that.chartLoaded[i]) {
              that.chartLoaded[i] = true;
              this.getChartForSubgroup(that.index[i].sector, that.index[i].iuId);
            }            
          }
        }
      });

      // let oldScrollY = window.scrollY;

      // $(window).on('scroll', ()=>{
      //   let height = $(window).height();
      //   let scrollY = $(window).scrollTop();
      //   let scrollYBottom = $(document).height() - height;
      //   let startInd = -5;
      //   if(scrollY == scrollYBottom){
      //       //bottom reached
      //       startInd = that.index.length - 5;
      //   } 

      //   if(oldScrollY < window.scrollY){
      //     // directionText.textContent = " Down";
      //     let multiple = Math.floor(scrollY/height);
      //     if(multiple > 0) {
      //       startInd = multiple*4 - 2;
      //     }
      //   } else {
      //       // directionText.textContent = " Up";
      //     let multiple = Math.floor((scrollYBottom - scrollY)/height);
      //     if(multiple > 0) {
      //       startInd = multiple*4 - 4;
      //     }
      //   }
      //   for(let i=startInd; i>=startInd+4; i++) {
      //     if(i in that.index && !that.chartLoaded[i]) {
      //       that.chartLoaded[i] = true;
      //       this.getChartForSubgroup(that.index[i].sector, that.index[i].iuId);
      //     }
      //   }
      //   oldScrollY = window.scrollY;
        
      // });

    }

    getkeyFact(){

      const dataToBeSend = [
          {
              "subgroup": "Total",
              "unit": "Tonne/s",
              "indicator": "Total annual waste"
          },
          {
              "subgroup": "Total",
              "unit": "Tonne/s",
              "indicator": "Actual annual collected waste"
          },
          {
              "subgroup": "Total",
              "unit": "Tonne/s",
              "indicator": "Annual waste disposed"
          },
          {
              "subgroup": "Total",
              "unit": "Tonne/s",
              "indicator": "Collected urban waste burnt"
          },
          {
              "subgroup": "Controlled dumpsite",
              "unit": "Tonne/s",
              "indicator": "Annual landfills and dumpsites"
          }
      ]
      let obj = { 'selectedIUS': dataToBeSend, 'areacode': this.currentAreaCode }

      adminService.httpUserPost('/data-retrieval/data/get-selected-keyfact', obj).then((res)=>{
          if(res){
            let resData = []
            
            res.data.forEach(el => {
              let sector = el.sector.replace(/\s+/g, '-').toLowerCase();
              let obj = {
                name: el.indicator,
                unit: el.unit,
                period: el.timeperiod,
                subgroup: el.subgroup,
                value: (el.value != "") ? Number(el.value) : "",
                source: el.source,
                metaData: el.metadata,
                sector: sector,
                iu_id: el.iu_id
              }
              resData.push(obj)
            })
              let d = res.data;
              console.log("Amit :", resData);
              this.setState({loader:false, indicatorData: resData, isCounting: (this.state.isCounting+1)})
              // console.log("res.data:: ", res.data)
              
              
              // this.setState({keyData: d})
         
          }

      }).catch((err) => {
          this.setState({ loader: false })
          console.log(err)
      });

  }

    getData() {
      this.setState({loader: true});
      const request = adminService.httpNoAuthGet("/data-retrieval/data/sector-wise-data").then((res)=> {
        this.setState({...this.state, 
                          sectorIus: res.sectorIus,
                          sectorMap: res.sectorMap,
                          areaList: res.areaList,
                          ius_list: res.ius_list
                        });
        this.getChart();
        this.initializeCharts();
        this.setState({loader: false});
      });
    }
    
    getChart() {
      
      let res = this.state.sectorIus;
      let timePeriods = [];
      let subgroups = [];
      let subgroupsLabel = [];
      let chartTypes = [];
      res?.forEach((sector)=>{
        sector.iusList?.forEach((iu)=> {
          let tp = iu.time_period_list.map(item=> {
                                                  return {'label': item.time_period, 'value' : item.time_period};
                                                });
          timePeriods[sector.level_name+iu._id] = [{ label: "All", value: "*" }, ...tp];
          subgroups[sector.level_name+iu._id] = iu.subgroup;
          chartTypes[sector.level_name+iu._id] = {path: this.chartIcon['bar'], type: 'bar'};
          let sg = iu.subgroup.map(item=> {
                                            return {'label': item.name, 'value' : item._id};
                                          });
          subgroupsLabel[sector.level_name+iu._id] = [{ label: "All", value: "*" }, ...sg];
          
        });
      });
      this.setState({
                      timePeriods: timePeriods,
                      subgroups: subgroups,
                      subgroupsLabel: subgroupsLabel,
                      chartType: chartTypes
                    });
      
    }

    initializeCharts() {
      let ind = [];
      let chrtLoaded = [];
      this.state.sectorIus.map(sector => {
        sector.iusList?.map((iu)=> {
          ind.push({sector: sector.level_name, iuId: iu._id});
          chrtLoaded.push(false);
          let stp = this.state.selectedOptionsTP;
          stp[sector.level_name + iu._id] = this.state.timePeriods[sector.level_name + iu._id];
          
          let ssg = this.state.selectedOptionsSG;
          ssg[sector.level_name + iu._id] = this.state.subgroupsLabel[sector.level_name + iu._id];
          this.setState({selectedOptionsTP: stp, selectedOptionsSG: ssg, isCounting: this.state.isCounting+1});
          
        });
        this.index = ind;
        this.chartLoaded = chrtLoaded;
        if(ind.length > 0){
          this.chartLoaded[0] = true;
          this.getChartForSubgroup(this.index[0].sector,this.index[0].iuId);
        }
        if(ind.length > 1){
          this.chartLoaded[1] = true;
          this.getChartForSubgroup(this.index[1].sector,this.index[1].iuId);
        }
      });

      // this.getkeyFact()
    }

    getRandomChart() {
      let i = Math.floor(Math.random() * this.charts.length);
      return this.charts[i];
    }

    getChartForSubgroup(sector, iuId, type='random') {
      let elem = this.chartRefs[sector+iuId].getEchartsInstance();
      let iusIds = [];
      let ssg = this.state.selectedOptionsSG[sector+iuId].filter(item => item.value !== "*");
      ssg?.map(ius => {
        iusIds.push(ius.value);
      });
      let stp = Array.isArray(this.state.selectedOptionsTP[sector+iuId])?this.state.selectedOptionsTP[sector+iuId].filter(item => item.value !== "*"):[];
      let tps = [];
      stp?.map(tp => {
        tps.push(tp.value);
      });
      if(iusIds.length == 0 || tps.length == 0) {
        this.optionData[sector+iuId] = {
          title: {
            show: true,
            textStyle:{
              color:'#666',
              fontSize:14,
              fontFamily: "Mulish",
              fontWeight: "400"
            },
            text: "No Data",
            left: 'center',
            top: 'center'
          },
          xAxis: {
              show: false
          },
          yAxis: {
              show: false
          },
          series: [],
          source: ""
        };
      
        elem.clear();
        elem.setOption(this.optionData[sector+iuId]);
        return;
      }

      if(type == 'random') {
        type = this.getRandomChart();
      }
      let chartTypes = this.state.chartType;
      chartTypes[sector+iuId] = {'path':this.chartIcon[type], 'type': type};
      this.setState({chartType: chartTypes});
      
      this.renderChart(sector, iuId, iusIds, tps, type);
    }
    

    renderChart(sector, iuId, iusIds, tps, type) {
      let elem = this.chartRefs[sector+iuId].getEchartsInstance();
      let dataToSend = {
        "time_period" : tps,
        "ius_id" : iusIds,
        "area" : [this.currentAreaCode]
      };
      
      const req = adminService.httpPost("/data-retrieval/data/get-chart-data", dataToSend).then(result => {
        let image = document.getElementById('img'+sector+iuId);
        if(image) {
          image.src='assets/lib/images/home/label_on.svg';
        }        
      
        if(result.data && result.data.length > 0) {
          //if time period is single and subgroup is single
          if(tps.length == 1 && iusIds.length == 1) {
            // $(`#div-${sector}${iuId}`).find(".da_dropdown").hide();
            this.optionData[sector+iuId] = {
              title: {
                show: true,
                textStyle:{
                  color: '#0C6638',
                  fontSize: 50,
                  fontFamily: "Mulish",
                  fontWeight: "400"
                },
                text: (+result.data?.[0]?.new_value)?.toLocaleString(),
                left: 'center',
                top: 'center'
              },
              xAxis: {
                  show: false
              },
              yAxis: {
                  show: false
              },
              series: [],
              source: ""
            };
            
          } else if(tps.length == 1 && iusIds.length > 1) {
            //if time period is single and subgroups are multiple then subgroups will come on x-axis
            let iusArr = [];
            let vData = [];
            let legendData = [];
            let colorPalette = ['#0c6638', '#208b34', '#42af56', '#77d488', '#a4e5b0', '#e0ffe6'];
            let tpName = "";
            result.data.forEach(row=> {
              iusArr.push(row.subgroupData?.subgroup?.name);
              vData.push(row.new_value);
              tpName = row.time_period?.start_time_period;
            });
            if(type == 'bar' || type == 'line') {
              this.optionData[sector+iuId] = {
                legend: {
                  show: false
                },
                tooltip: {
                  trigger: 'item',
                  axisPointer: {
                    type: 'shadow'
                  }
                },
                grid:{
                  containLabel: true,
                  left: '10%',
                  bottom: '3%',
                  top: '10%'
                 },
                xAxis: {
                    type: 'category',
                    data: iusArr
                },
                yAxis: {
                    type: 'value'
                },
                series: [{ 
                    name: tpName,
                    data: vData,
                    type: type,
                    label: { 
                      show: false, 
                      position: 'top',
                      formatter: function(params) {
                        return (+params.value).toLocaleString();
                      }
                    },
                    emphasis: {
                      focus: 'series'
                    },
                    lineStyle: {
                      color: '#A4E5B0',
                      width: 1
                    },
                    itemStyle: {
                        borderWidth: 1,
                        borderColor: '#A4E5B0',
                        color: '#A4E5B0'
                    }
                }]
              };
            } else if(type == 'pie') {
              this.optionData[sector+iuId] = {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    type: 'scroll',
                    orient: 'vertical',
                    right: 10,
                    top: 20,
                    bottom: 20,
                    data: iusArr,
                    formatter: function (name) {
                      return name.length > 17?name.substring(0, 17) + '...':name;
                    },
                    tooltip: {
                      show: true,
                      trigger: 'item'
                    }
                },                
                series: [
                    {
                      name: tpName,
                      type: 'pie',
                      // radius: '75%',
                      center: ['40%', '50%'],
                      data: vData,
                      color: colorPalette,
                      label: {
                        show: false
                      },                      
                      emphasis: {
                          itemStyle: {
                              shadowBlur: 10,
                              shadowOffsetX: 0,
                              shadowColor: 'rgba(0, 0, 0, 0.5)'
                          }
                        }
                      
                    }
                  ]
              };
            
            }
            
          } else {
            //if time period is multiple then it will come on x-axis
            if(type == 'line' || type == 'bar') {
              let tpArr = [];
              let vData = [];
              let sgNames = [];
              result.data.forEach(row=> {
                let tp = row.time_period?.start_time_period;
                if(!tpArr.includes(tp)) {
                  tpArr.push(tp);
                }              
                let sgName = row.subgroupData?.subgroup?.name;
                if(sgName in vData) {
                  vData[sgName].push(row.new_value);
                } else {
                  vData[sgName] = [row.new_value];
                  sgNames.push(sgName);
                }            
              });
              
              let seri = [];
  
              sgNames.forEach((sg, i) => {
                seri.push({ 
                  name: sg,
                  data: vData[sg],
                  type: type,
                  label: { 
                    show: false, 
                    position: 'top',
                    formatter: function(params) {
                      return (+params.value).toLocaleString();
                    }
                  },
                  emphasis: {
                    focus: 'series'
                  },
                  lineStyle: {
                    // color: "#25f1f5",
                    color: this.colors[i],
                    width: 1
                  },
                  itemStyle: {
                      borderWidth: 1,
                      // borderColor: "#0C6638",
                      borderColor: this.colors[i],
                      // color: "#0C6638"
                      color: this.colors[i]
                  }
                });
              });
  
              this.optionData[sector+iuId] = {
                tooltip: {
                  trigger: 'item',
                  axisPointer: {
                    type: 'shadow'
                  }
                },
                legend: {
                  data: sgNames,
                  // orient: 'vertical',
                  // top: 'center',
                  // icon: 'rect',
                  // right: 0,
                  bottom: 0,
                  // height: 'auto',
                  type: 'scroll',
                  show: true,
                  formatter: function (name) {
                    return name.length > 17?name.substring(0, 17) + '...':name;
                  },
                  tooltip: {
                    show: true,
                    trigger: 'item'
                  }
                },
                grid:{
                  containLabel: true,
                  left: '10%',
                  // height: 'auto',
                  // bottom: '3%',
                  top: '10%',
                  y2:30,
                 },
                xAxis: {
                    type: 'category',
                    data: tpArr
                },
                yAxis: {
                    type: 'value'
                },
                toolbox: {
                  show: false,
                  orient: 'vertical',
                  left: 'right',
                  top: 'center',
                  feature: {
                    mark: { show: true },
                    dataView: { show: true, readOnly: true },
                    magicType: { show: true, type: ['bar', 'line'] },
                    // restore: { show: true },
                    saveAsImage: { show: true, title: 'Download' }
                  }
                },
                series: seri
              };
            } else if(type == 'pie') {
              let legendData = [];
              let colorPalette = ['#0c6638', '#208b34', '#42af56', '#77d488', '#a4e5b0', '#e0ffe6'];
              let vData = [];
              let seri = [];
              result.data.forEach(row=> {
                let tp = row.time_period?.start_time_period;
                let sgName = row.subgroupData?.subgroup?.name;
                legendData.push(sgName+'-'+tp);
                seri.push({
                  name: sgName+'-'+tp,
                  value: row.new_value
                });
              });
              
              this.optionData[sector+iuId] = {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    type: 'scroll',
                    orient: 'vertical',
                    right: 10,
                    top: 20,
                    bottom: 20,
                    data: legendData,
                    formatter: function (name) {
                      return name.length > 17?name.substring(0, 17) + '...':name;
                    },
                    tooltip: {
                      show: true,
                      trigger: 'item'
                    }
                },
                series: [
                  {
                    name: "",
                    type: 'pie',
                    // radius: '55%',
                    center: ['40%', '50%'],
                    data: seri,
                    color: colorPalette,
                    label: {
                      show: false
                    },                      
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                  }
                ]
              };
              
            }
            
          }

          let src = this.state.source;
          src[sector+iuId] = result.data[0]?.source?.publisher;
          this.setState({source: src});
        } else {
          this.optionData[sector+iuId] = {
            title: {
              show: true,
              textStyle:{
                color:'#666',
                fontSize:14,
                fontFamily: "Mulish",
                fontWeight: "400"
              },
              text: "No Data",
              left: 'center',
              top: 'center'
            },
            xAxis: {
                show: false
            },
            yAxis: {
                show: false
            },
            series: [],
            source: ""
          };
        }
        elem.clear();
        elem.setOption(this.optionData[sector+iuId]);          
      });
    } 

    labelChart(sector, iuId) {

      let image = document.getElementById('img'+sector+iuId)
    
      if(image.getAttribute('src')==='assets/lib/images/home/label_on.svg'){
        image.src = 'assets/lib/images/home/label-off.svg';
      }
      else {
        image.src = 'assets/lib/images/home/label_on.svg';
      }
     

      let ref = this.chartRefs[sector+iuId];
      if(ref) {
        let ele = ref.getEchartsInstance();
        let option = this.optionData[sector+iuId];
        let newSeries = [];
        option?.series?.forEach(seri => {
          seri.label = {
            show: !seri.label.show, 
            position: 'top',
            formatter: function(params) {
              return (+params.value).toLocaleString();
            }
          };
          newSeries.push(seri);
        });
        option.series = newSeries;
        ele.clear();
        this.optionData[sector+iuId] = option;
        ele.setOption(option);          
      }
    }

    getUrlFromService = (sector, iuId) => {
      return new Promise((resolve, reject)=> {
        this.setState({loader: true});
        let node1 = document.getElementById("div-"+sector+iuId);
        let node2 = document.getElementById("copy");
        node2.innerHTML = node1.innerHTML;
        node2.style.display = 'block';
        $("#copy").find(".da_dropdown").remove();
        $("#copy").find(".maps_source").remove();
        $("#copy").find(".social_link").remove();
        $("#copy").find(".download_source").show();
        // $("#copy").find(".hArea").html(`<p>${this.state.currentAreaName}</p>`);
        // $("#copy").find(".hArea").show();
        $("#copy").find(".hArea")[0].style.display='block';
        domtoimage.toBlob(node2)
          .then((blob) => {
            let file = [];
            let bfile = "";
            let path = "";
            // this.saveFile(blob);
            getBase64(blob)
                .then(result => {
                  file["base64"] = result;
                  let b = file.base64;
                  
                  bfile=b.split(",").pop()
                
  
              path = (""+sector+iuId+".png" + ';' + bfile);
              
              node2.style.display = 'none';
              let dataToSend = {
                "image":path
              }
              adminService.httpPost("/data-import/resources/add-image", dataToSend).then((res)=> {
                this.setState({loader: false});
                if(res.success == true) {
                  resolve(constants.resourceImagePath+res.file);
                } else {
                  reject(null);
                }              
              });
              
            }); 
          });     
      });
      
    }

    onClickHandler = (btnType, sector, iuId) => {
      // Be sure to check for the "none" state, so we don't trigger an infinite loop.
      if (this.state.url === "none") {
        this.getUrlFromService(sector, iuId).then((newUrl) => {
          this.setState({url: newUrl, sector: sector, iuId: iuId, buttonType: btnType});
        }, (newUrl)=> {
          this.setState({url: 'none', sector: '', iuId: '', buttonType: ''});
        })        
      }
    };

    componentDidUpdate() {
      if(this.state.url != null && this.state.url != 'none') {
        $("."+this.state.buttonType+this.state.sector+this.state.iuId).trigger("click");
        this.setState({url: "none", sector: '', iuId: '', buttonType: ''});
      }
    }

    downloadChart = (sector, iuId, indName) => {
      // let ref = this.chartRefs[sector+iuId];
      // if(ref){
      //   let ele = ref.getEchartsInstance();
      //   let option = this.optionData[sector+iuId]
      //   option.legend.type = 'plain'
      //   option.legend.rigth = '0'
      //   ele.setOption(option)

      // }


      // this.optionData[sector+iuId] = {...this.optionData[sector+iuId],...{legend:{type:'plain',orient: 'vertical'}}}
      console.log(this.optionData,sector+iuId);
     
      this.setState({loader: true});
      let node1 = document.getElementById("div-"+sector+iuId);
      // node1.style.position = 'fixed'
      // node1.style.height = '100vh'
      // node1.style.height = '100vw'
      // node1.style.top = '0'
      let node2 = document.getElementById("copy");
      node2.innerHTML = node1.innerHTML;
      node2.style.display = 'block';
      $("#copy").find(".da_dropdown").remove();
      $("#copy").find(".maps_source").remove();
      $("#copy").find(".social_link").remove();
      $("#copy").find(".metadata").remove();
      $("#copy").find(".download_source").show();
      // $("#copy").find(".hArea").html(`${this.state.currentAreaName}</p>`).show();
      // $("#copy").find(".hArea").show();
      $("#copy").find(".hArea")[0].style.display='block';
      domtoimage.toBlob(node2)
        .then((blob) => {
          this.saveFile(blob, indName, this.state.currentAreaName);
          node2.style.display = 'none';
          this.setState({loader: false});
        });
        // if(ref){
        //   let ele = ref.getEchartsInstance();
        //   let option = this.optionData[sector+iuId]
        //   option.legend.type = 'scroll'
        //   option.legend.right = '0'
        //   ele.setOption(option)
        // }
        
    }

    saveFile = async (blob, indName, areaName) => {
      const a = document.createElement('a');
      const dt = Date.now();
      a.download = `${indName}_${areaName}_${dt}.png`;
      a.href = URL.createObjectURL(blob);
      a.click();
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);

      
    };

    // =======================================================
    render() {
        return jsx.default.bind(this)();
    }    
    
    setSelectedItem(item){
        this.setState({selectvalue: item})
    }
    
    settimeperiod(timeperiod){
        this.setState({timeperiodvalue: timeperiod})
    }
    // =======================================================

    getDropdownButtonLabel({ placeholderButtonLabel, value }) {
      
      if (value && Array.isArray(value) && value.some((o) => o.value === "*")) {
        return `${placeholderButtonLabel}: All selected`;
      } else if(value && Array.isArray(value)) {
        if(value?.length == 1) {
          return `${placeholderButtonLabel}: ${value[0].label}`;  
        }
        return `${placeholderButtonLabel}: ${value?.length} selected`;
      } else {
        return `${placeholderButtonLabel}: ${value}`;
      }
    }
  
    onChangeTP = (value, event, sector, iuid) => {
      
      if (event.action === "select-option" && event.option.value === "*") {
        let stp = this.state.selectedOptionsTP;
        stp[sector+iuid] = this.state.timePeriods[sector+iuid];
        this.setState({selectedOptionsTP: stp});
      } else if (
        event.action === "deselect-option" &&
        event.option.value === "*"
      ) {
        let stp = this.state.selectedOptionsTP;
        stp[sector+iuid] = [];
        this.setState({selectedOptionsTP: stp});
      } else if (event.action === "deselect-option") {
        let stp = this.state.selectedOptionsTP;
        stp[sector+iuid] = value.filter((o) => ((o.value !== event.option.value) && (o.value !== "*")));
        this.setState({selectedOptionsTP: stp});
      } else if (value.length === this.state.timePeriods[sector+iuid].length - 1) {
        let stp = this.state.selectedOptionsTP;
        stp[sector+iuid] = this.state.timePeriods[sector+iuid];
        this.setState({selectedOptionsTP: stp});
      } else {
        let stp = this.state.selectedOptionsTP;
        stp[sector+iuid] = value;
        this.setState({selectedOptionsTP: stp});
      }

      this.getChartForSubgroup(sector, iuid, this.state.chartType[sector+iuid]?.type);
  }
    
  onChangeSG = (value, event, sector, iuid) => {
    
    if (event.action === "select-option" && event.option.value === "*") {
      let stp = this.state.selectedOptionsSG;
      stp[sector+iuid] = this.state.subgroupsLabel[sector+iuid];
      this.setState({selectedOptionsSG: stp});
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      let stp = this.state.selectedOptionsSG;
      stp[sector+iuid] = [];
      this.setState({selectedOptionsSG: stp});
    } else if (event.action === "deselect-option") {
      let stp = this.state.selectedOptionsSG;
      stp[sector+iuid] = value.filter((o) => ((o.value !== event.option.value) && (o.value !== "*")));
      this.setState({selectedOptionsSG: stp});
    } else if (value.length === this.state.subgroupsLabel[sector+iuid].length - 1) {
      let stp = this.state.selectedOptionsSG;
      stp[sector+iuid] = this.state.subgroupsLabel[sector+iuid];
      this.setState({selectedOptionsSG: stp});
    } else {
      let stp = this.state.selectedOptionsSG;
      stp[sector+iuid] = value;
      this.setState({selectedOptionsSG: stp});
    }

    this.getChartForSubgroup(sector, iuid, this.state.chartType[sector+iuid]?.type);
  }

}


export default connect(
   
  )(DataanalyticsPage);

const getBase64 = async(file) => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      
      let reader = new FileReader();

      
      reader.readAsDataURL(file);

     
        
        
        
        
      
      reader.onload = () => {
        
        
        baseURL = reader.result;
        
        resolve(baseURL);
      };
   
      
    });
};

import React, { useEffect,useRef,useState } from "react";
import './modal.css'
import "react-toastify/dist/ReactToastify.css";
import SimpleReactValidator from 'simple-react-validator';
import makeAnimated from 'react-select/animated';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import CheckboxTree from 'react-checkbox-tree';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';

import Tooltip from '@mui/material/Tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
// ================================== Password
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
// ================================== Password

import {omit} from 'lodash'

function AddUserModal(props) {
  const form = useRef()
  const simpleValidator = useRef(new SimpleReactValidator({
    messages: {
      required: 'This field is required'
    }
  }));


 
  

const [, forceUpdate] = useState();


const [state, setState] = useState({
  id: '',
  name: '',
  email: '',
  password: '',
  user_role: '',
  areaList: [],
  organization: '',
  designation: '',
  areaTree:[]
});
const [areaChecked ,setAreaChecked] = useState([])

const [areaExpand, setAreaExpanded] = useState([]);
const [areaTree, setAreaTree] = useState([]);




const [roleValue ,setRoleValue] = useState(0); 

const [role ,setRole] = useState([
{
  label: "Data Entry", value: 2 },
  {
 label: "Data Approve", value: 4 ,
 }
]);


useEffect(() => {
      if(props.areaTree?.length > 0){

        let flatLevel = []
        props.areaTree?.forEach((item) => {



            var obj = {
                value: item.area_code,
                label: item.name,
                parent: item.parent_id,
                level:item.level
               
              }


       
        

            flatLevel.push(obj)


        })

        const data = setLevelData(flatLevel);

        
        // console.log('flatLevel',data)

        setAreaTree(data);

        // console.log('assaas', data)



            }
    
          }, [props.areaTree]);

useEffect(() => {
  // console.log( "sfdrrr", props.editMode, props.isEdit);
  const facilities1 =props.facilityData;
  // setfacilityType(props.editMode?.facility_type); 
if(props.isEdit==true) {

  let test = '';
        
  props.areaTree.map(item=>{
//  console.log(item.area_code,"qqqqqqqqqqqqq======>",props.editMode?.area[0]?.area_code)
      if(item.area_code==props.editMode?.area[0]?.area_code){
        test = item.name;
      //  console.log("hello test=======>",test)
       
      }
     
  })

  const areaDt = [];


  if(Array.isArray(props.editMode?.area?.[0]?.area_code)){
    props.editMode?.area?.[0]?.area_code?.forEach(item=>{

      props.areaTree?.forEach(a=>{
        if(a.area_code==item){
  
          areaDt.push({code:item, name:a.name})
  
        }
      })
  
    })
  }
  else{
    props.areaTree?.forEach(a=>{
      if(a.area_code==props.editMode?.area?.[0]?.area_code){
        areaDt.push({code:a.area_code, name:a.name})
      }
 
    })

  }


  console.log('areaDt',areaDt)

  var areaChecked = [];

  areaDt?.forEach(item=>{
    areaChecked.push(item.code)
  })
  // console.log( "sfdrrr", props.editMode);
  setState({
    "id": props.editMode?._id,
    "name": props.editMode?.name,
    "email":props.editMode?.email,
    "password": props.editMode?.password,
    "user_role": props.editMode?.role,
    "areaList": areaDt,
    "organization":props.editMode?.organization,
    "designation":props.editMode?.designation      
  });


  setAreaChecked(areaChecked)

  let rlData = props.editMode.role;

  setRoleValue(rlData);
  
  console.log('ssrole',{
    "id": props.editMode?._id,
    "name": props.editMode?.name,
    "email":props.editMode?.email,
    "password": props.editMode?.password,
    "user_role": props.editMode?.role,
    "areaList": areaDt,
    "organization":props.editMode?.organization,
    "designation":props.editMode?.designation      
  })

}else{
         
  setState({
    "id": '',
    "name": '',
    "email": '',
    "password": '',
    "user_role": '',
    "areaList": [],
    "organization": '',
    "designation": ''
});
}


}, [props]);

 
// area tree sctructure=========================//




const setLevelData = (flat) => {

  var roots = [];

  var all = {};

  flat.forEach(function (item) {

      all[item.value] = item;
  });


  Object.keys(all).forEach(function (value) {
      var item = all[value];
      if (item.parent === null || item.parent === "") {

          roots.push(item);
      } else if (item.parent in all) {
          var p = all[item.parent];
          if (!("children" in p)) {
           
              p.children = [];
              
          }
          p.children.push(item);
      }
  });


  return roots;
};


const getAreaList = (e) =>{
  setAreaChecked(e)
  console.log('asas',e)

  var dataArr = [];
  e?.forEach(item=>{

      props.areaTree?.forEach(dt=>{
          if(dt.area_code==item){
              dataArr.push({code:dt.area_code, name:dt.name})

          }
      })


  })



  setState({...state, areaList:dataArr});



}





  
const inputhandle = (e) => {
  let name, value,label
  setRole(e.target.value);
  // console.log("ee-" ,e.target.value);
  name = e.target.name;
  value = e.target.value;
  label=e.target.label;
  setState({ ...state, [name]: value });
  setState({ ...state, [name]: value });

  // if (!isValidEmail(e.target.value)) {
  //   setError('Email is invalid');
  // } else {
  //   setError(null);
  // }
  // setMessage(e.target.value);

  // e.persist();
  // validate(e,name,value);

  //Let's set these values in state
  // setValues({
  //     ...values,
  //     [name]:value,
  // })
 


};





   
const [emailError, setEmailError] = useState('')

// ============================================================================== Password Show/Hide Start
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
// ============================================================================== Password Show/Hide End
// ============================================================================== Validation Start

const makeid=(length)=> {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < length; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  return text;
}

  const handleChange = (event) => {       
    
    if(event.target.name=='userRole'){
      let selectedVal = event.target.value;
      setRoleValue(selectedVal);
    }else{
      setState({...state,  [event.target.name]: event.target.value});
    }
 
 
  }

  const handleSubmit = (e) => {
      e.preventDefault();
      let dataToSend = [];
      let passwordString = makeid(10) + state.password
      let password = btoa(passwordString);

      const arrOfAreas = [];

      state.areaList?.forEach(item=>{
        arrOfAreas.push(item.code)
        console.log("What is this item.code??--->", item.code)
      })
      console.log("KYA HUA TERA VAADA--->", arrOfAreas)

      if (props.isEdit == true) {

        console.log("password--"+password)
        if(state.password==undefined){
          dataToSend = {



            "id": state.id,
            "name": state.name,
            "email": state.email,
            
            "user_role": roleValue,
            "area": arrOfAreas,
            "organization": state.organization,
            "designation": state.designation,
            "status": 1

        }
        }else{
          dataToSend = {



            "id": state.id,
            "name": state.name,
            "email": state.email,
            "password": password,
            "user_role": roleValue,
            "area": arrOfAreas,
            "organization": state.organization,
            "designation": state.designation,
            "status": 1

        }
        }


    }
    else {


       
        dataToSend = {

            "id": null,
            "name": state.name,
            "email": state.email,
            "password": password,
            "user_role": roleValue,
            "area": arrOfAreas,
            "organization": state.organization,
            "designation": state.designation,
            "status": 1
          }
        }
        if(simpleValidator.current.allValid()) {

          console.log("santea========>",dataToSend)
        props.parentCallback(dataToSend);
      }else{
        simpleValidator.current.showMessages();
        forceUpdate(1);
      }
     
  
  }

  // form submit function end============//

  const cancelModal = (event) => {
    event.preventDefault();
    props.closeModal(event.target);
  }
  // ============================================================================== Validation End

////////////=======multiple delete function ===========////////////////


// console.log("ttttttttzzzzzzzzzzz===========>",props.facilityData?.User_Roles)
 
  return (
    <>

      <div className="user-form">
        <ValidatorForm className="main_react_form w-100" ref={form} onSubmit={handleSubmit}>
          <Stack spacing={2} sx={{ width: 300 }}  className="w-100">
            {/* <form onSubmit={(e)=>updateData(e)} noValidate="" style={{ 'position': 'relative' }} id="addUserForm" className="ng-untouched ng-pristine ng-invalid" > */}
              <div className="gallery-collections mt-0 metadata_form">
                <div className="row align-items-center mb-30">
                    <div className="col-md-3">
                        <span className="mat-form-field-label-wrapper">
                            <label>Name</label>
                        </span>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group mb-0 inputform_style autocomplete">
                            <div className="mat-form-field-wrapper">
                                <TextValidator name="name" autoComplete="off" className="w-100" onChange={(e) => { handleChange(e) }} value={ state.name }  validators={['required']} errorMessages={['This field is required']} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row align-items-center mb-30">
                    <div className="col-md-3">
                        <span className="mat-form-field-label-wrapper">
                            <label>Designation</label>
                        </span>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group mb-0 inputform_style autocomplete">
                            <div className="mat-form-field-wrapper">
                                <TextValidator name="designation" autoComplete="off" className="w-100" onChange={(e) => { handleChange(e) }} value={state.designation}  validators={['required']} errorMessages={['This field is required']} />
                            </div>
                        </div>
                    </div>
                </div>  

                <div className="row align-items-center mb-30">
                    <div className="col-md-3">
                        <span className="mat-form-field-label-wrapper">
                            <label>Organization</label>
                        </span>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group mb-0 inputform_style autocomplete">
                            <div className="mat-form-field-wrapper">
                                <TextValidator name="organization" autoComplete="off" className="w-100" onChange={(e) => { handleChange(e) }} value={ state.organization }  validators={['required']} errorMessages={['This field is required']} />
                            </div>
                        </div>
                    </div>
                </div>     

                <div className="row align-items-center mb-30">
                    <div className="col-md-3">
                        <span className="mat-form-field-label-wrapper">
                            <label>Email</label>
                        </span>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group mb-0 inputform_style autocomplete">
                            <div className="mat-form-field-wrapper">
                                <TextValidator type="email" name="email" autoComplete="off" className="w-100"
                                //  readOnly = {props.isEdit}
                                disabled ={props.isEdit}
                                onChange={(e) => { handleChange(e) }}  
                                 value={ state.email }  
                                 validators={['required']} errorMessages={['this field is required']}
                                
                                />
                                
                            </div>
                            {/* readOnly={state.editMode? true :false} */}
                        </div>
                    </div>
                </div>  
                <div className="row align-items-center mb-30">
                    <div className="col-md-3">
                        <span className="mat-form-field-label-wrapper">
                                                <label>Area</label>
                                                </span>
                                  
                                           </div>
                                          
                                           <div className="col-md-6">
                                
                                    <div className="form-group mb-0 inputform_style ifs_dropdown multiselectArea checkboxRemove">
                                          
                                    <div className="mat-form-field-wrapper">
                                               
                                              
                                               <Dropdown>
                                                   <Dropdown.Toggle variant="white" id="dropdown-basic">
                                                    
                                                   {state.areaList?.length > 0 && state.areaList.slice(0, 2).map((d, i) => { console.log('dash', d); return (<><span className="badge">{d.name} </span></>) })}
                                                   {state.areaList?.length > 2 ? <><span>{state.areaList?.length - 1}+</span></> : ''}
                                                   </Dropdown.Toggle>
                                                   <Dropdown.Menu className="treeviewCheckbox">
                                                    
                                                       <CheckboxTree
                                                           nodes={areaTree}
                                                           checked={areaChecked}
                                                           expanded={areaExpand}
                                                           onCheck={(e) => getAreaList(e)}
                                                           onExpand={(expanded) => setAreaExpanded(expanded)}
                                                          
                                                       />
                                                   </Dropdown.Menu>
                                               </Dropdown>
                                           </div>
                                           <div className="inPuthidden">
                                           <TextValidator type="hidden" style ={{border:"none"}}name="password" autoComplete="off" className=" w-100" onChange={(e) => { handleChange(e) }} value={ state.areaList }  validators={['required']} errorMessages={['This field is required']} /> 
                                           </div>
                                
                                        {/* <div className="mat-form-field-wrapper">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="white" id="dropdown-basic">
                                                { state.areaList?.length > 0 && state.areaList?.slice(0,3)?.map((d,i)=>{ return (<><span className="badge">{ d.name} </span></>)})}
                                                            { state.areaList?.length > 3? <><span>{state.areaList?.length-1}+</span></>: ''}
                                                    <TextValidator
                                                        className="d-none w-100"

                                                        type="hidden"
                                                        label=""
                                                        name="pareaName"
                                                        value={state.areaList?.[0]?.code}

                                                    /> 

                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <TreeView

                                                        aria-label="file system navigator"
                                                        defaultCollapseIcon={<ExpandMoreIcon />}
                                                        defaultExpandIcon={<ChevronRightIcon />}

                                                        sx={{ flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                                                    >
                                                        {renderTree(areaTree)}

                                                    </TreeView>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                         
                          

                <div className="row align-items-center mb-30">
                    <div className="col-md-3">
                        <span className="mat-form-field-label-wrapper">
                            <label>Password</label>
                        </span>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group mb-0 inputform_style autocomplete password">
                            <div className="mat-form-field-wrapper">
                              <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                              
                                <OutlinedInput name="password" inputProps={{ autocomplete: 'new-password',  form: { autocomplete: 'off',}, }} autoComplete="off" id="outlined-adornment-password" onChange={(e) => { handleChange(e) }} 
                                
                                type={showPassword ? 'text' : 'password'} value={ state.password }  validators={['required']} errorMessages={['This field is required']} endAdornment={
                                  
                                 <InputAdornment position="end">
                                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                                      {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                  </InputAdornment>
                                } />
                              </FormControl>
                              <div className="inPuthidden">
                                {props.isEdit!=true && 
                                <TextValidator type="hidden" style ={{border:"none"}}name="password" autoComplete="off" className="w-100" onChange={(e) => { handleChange(e) }} value={ state.password }  validators={['required']} errorMessages={['This field is required']} /> 
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 

                 <div className="row align-items-center mb-30">
                  <div className="col-md-3">
                      <span className="mat-form-field-label-wrapper">
                          <label>Role</label>
                      </span>
                  </div>
                  <div className="col-md-6">
                      <div className="form-group mb-0 inputform_style">
                          <div className="mat-form-field-wrapper">
                          <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="userRole"
                                            value={roleValue}
                                            placeholder=''
                                            onChange={handleChange}
                                           
                                          >
                                            {role.map((item) => (
                                              <MenuItem
                                              
                                                key={item.value}
                                                value={item.value}
                                                
                                              >
                                                {item.label}
                                              </MenuItem>

                                            ))}
                                       
                                          </Select>
                                          {/* <TextValidator type="hidden" style ={{border:"none"}}name="password" autoComplete="off" className="w-100" onChange={(e) => { handleChange(e) }} value={ state.role }  validators={['required']} errorMessages={['This field is required']} />  */}
                            {/* <Select onChange={(e) => { inputhandle(e) }} name="role" id="user_role" >
                              <MenuItem value={2}>Data Entry</MenuItem>
                              <MenuItem value={4}>Data Approver</MenuItem>
                            </Select> */}
                          </div>
                      </div>
                  </div>
                </div>  
                </div>   


            

            <div className="gallery-sidebar-button text-center new_gallery-sidebar-button mt-0">
              <div className="d-flex align-items-center justify-content-center">
                  <Button variant="contained" onClick={cancelModal} className="cancelbtn">Cancel</Button>
                  <Button className="addbtn" color="error" variant="contained" type="submit">
                    {props.isEdit? 'Update': 'Add' }
                  </Button>
              </div>
            </div>
          </Stack>
        </ValidatorForm>
      </div>





    </>

  );
}

export default AddUserModal;